import React, { Component } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Avatar, Form, Table, Space, Button, Row, Input, Drawer, } from 'antd';
import { FormInstance } from 'antd/lib/form';

import './App.css';
import 'antd/dist/antd.css';

interface col {
    title: string,
    dataIndex?: string,
    key: string,
    fixed?: string,
    width?: number,
    render?: any,
}

interface IContact {
    email: string,
    firstname: string,
    lastname: string,
    phone: string,
}

interface IState {
    contacts: IContact[],
    visible: boolean,
    selectedEmail: string,
}

interface IProps extends WrappedComponentProps {
    onChange: (value: string) => void,
    contacts: string,
    options: string,
    form?: any,
    disabled?: boolean
}

class ContactList extends Component<IProps, IState>
{
    formRef = React.createRef<FormInstance>();

    constructor(props: IProps) {
        super(props);

        var lines = this.props.contacts.split("\n");
        var contacts = lines.map(l => {
            var fields = l.split(";");
            return {
                email: fields[0],
                firstname: fields[1],
                lastname: fields[2],
                phone: fields[3],
            } as IContact
        });

        this.state = {
            contacts: contacts,
            visible: false,
            selectedEmail: "",
        };

        this.saveText = this.saveText.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onOpen = this.onOpen.bind(this);
        this.onFinish = this.onFinish.bind(this);
        this.onFinishFail = this.onFinishFail.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onAdd = this.onAdd.bind(this);
    }

    onSave() {
        var res = "";
        const lines = this.state.contacts.map(c => {
            return c.email + ";"
                + c.firstname + ";"
                + c.lastname + ";"
                + c.phone;
        });
        res = lines.join("\n");
        this.props.onChange(res);
        this.setState({ visible: false, })
    }

    onSelect(i: any) {
        this.setState({ selectedEmail: i.email })
        this.formRef.current!.setFieldsValue({
            email: i.email,
            firstname: i.firstname,
            lastname: i.lastname,
            phone: i.phone,
        });

    }

    onDelete(i: any) {
        var a = this.state.contacts;
        var del = 0;
        a.forEach((v, index) => {
            if (v.email === i.email) del = index;
        });
        a.splice(del, 1);
        this.setState({ contacts: [...a] });
    }

    onClose() {
        this.setState({ visible: false, })
    }

    onOpen() {
        this.setState({ visible: true, })
    }

    componentDidMount() {
    }

    onAdd() {
        this.setState({ selectedEmail: "", });
        this.formRef.current!.resetFields();
    }

    saveText(e: any) {
       
    }

    onFinish(values: any) {
        var email = "";
        var firstname = "";
        var lastname = "";
        var phone = "";
        console.log('Success:', values);

        if (values.email !== null) email = values.email;
        if (values.firstname !== null) firstname = values.firstname;
        if (values.lastname !== null) lastname = values.lastname;
        if (values.phone !== null) phone = values.phone;

        var contacts = this.state.contacts;
        var found = false;
        contacts.forEach(i => {
            if (i.email === this.state.selectedEmail) {
                found = true;
                i.email = email;
                i.firstname = firstname;
                i.lastname = lastname;
                i.phone = phone;
            }
        })

        if (!found) {
            contacts = [...contacts, {
                email: email,
                firstname: firstname,
                lastname: lastname,
                phone: phone,
            }]
        }

        this.setState({
            selectedEmail: "",
            contacts: contacts
        });

        this.formRef.current!.resetFields();

    }

    onFinishFail() {

    }

    render() {

        var avatars = this.state.contacts.map(c => {
            var i = c.email.substr(0, 1);
            if ( c.firstname && c.firstname !== "") i = c.firstname.substr(0, 1);
            return <Avatar key={i} >{i}</Avatar>
        })


        var columns: col[] = [
            {
                title: 'Email Address',
                dataIndex: 'email',
                key: 'email',
            },
        ];

        var fields = [
            <Form.Item
                label="Email Address"
                name="email"
                key="email"
                rules={[{ required: true, message: 'Please input your username!' }]}
            >
                <Input disabled={this.props.disabled} placeholder="Email" />
            </Form.Item>
        ];

        var opts = this.props.options.split(";");
        if (opts.some(o => o === "0")) {
            columns.push({
                title: 'First Name',
                dataIndex: 'firstname',
                key: 'fname',
            });

            fields.push(
                <Form.Item
                    label="First Name"
                    name="firstname"
                    key="firstname"
                    rules={[{}]}
                >
                    <Input disabled={this.props.disabled} placeholder="First Name" />
                </Form.Item>
            );
        }
        if (opts.some(o => o === "1")) {
            columns.push({
                title: 'Last Name',
                dataIndex: 'lastname',
                key: 'lname',
            });
            fields.push(
                <Form.Item
                    label="Last or Family Name"
                    name="lastname"
                    key="lastname"
                    rules={[{}]}
                >
                    <Input disabled={this.props.disabled} placeholder="Last Name" />
                </Form.Item>
            );
        }
        if (opts.some(o => o === "2")) {
            columns.push({
                title: 'Phone',
                dataIndex: 'phone',
                key: 'phone',
            });
            fields.push(
                <Form.Item
                    label="Phone Number"
                    name="phone"
                    key="phone"
                    rules={[{}]}
                >
                    <Input disabled={this.props.disabled} placeholder="(999) 9999 999 999" />
                </Form.Item>
            );
        }

        columns.push({
            title: 'Action',
            key: 'operation',
            fixed: 'right',
            width: 100,
            render: (d, i) => <a onClick={() => this.onDelete(d)}>Delete</a>,
        });

        var buttons;

        if (this.state.selectedEmail === "") {
            buttons = <Form.Item key="add">
                <Button disabled={this.props.disabled} type="primary" htmlType="submit">
                    Add
                </Button>
            </Form.Item>
        }
        else {
            buttons = <>
                <Form.Item key="buttons">
                    <Space>
                        <Button disabled={this.props.disabled} type="primary" htmlType="submit">
                            Save
                        </Button>
                        <Button disabled={this.props.disabled} onClick={this.onAdd} type="primary" htmlType="submit">
                            Add
                        </Button>
                    </Space>
                </Form.Item>
            </>

        }

        return (
            <div  >
                <Row onClick={this.onOpen} >
                    <Avatar.Group maxCount={3} >
                        {avatars}
                    </Avatar.Group>
                </Row>
                <Drawer
                    title="Contact List"
                    width={720}
                    className="Drawer"
                    onClose={this.onClose}
                    visible={this.state.visible}
                    footer={
                        <div style={{ textAlign: 'right', }}>
                            <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                                Cancel
                            </Button>
                            <Button disabled={this.props.disabled} onClick={this.onSave} type="primary">
                                Save
                            </Button>
                        </div>
                    }
                >
                    <Form

                        name="basic"
                        initialValues={{ remember: true }}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFail}
                        layout="vertical"
                        ref={this.formRef}
                    >
                        {fields}
                        {buttons}
                    </Form>
                    <Table onRow={(r) => ({
                        onClick: () => this.onSelect(r)
                    })}
                        dataSource={this.state.contacts}
                        columns={columns as any}
                    />;

                </Drawer>
            </div>
        );
    }

}
//<PaperClipOutlined />
export default injectIntl(ContactList);
