  export const { API, APIKEY } = require('./IVestaEndpoint');

  export const MAPSKEY = "AIzaSyCvIfcCWgOEnXCn_jqJY0fV_TnzforO7L4";


  export enum QuestionType
  {
      YesNo,
      Text,
      Number,
      Currency,
      Date,
      OneOf,
      ManyOf,
      Rate,
      ReadDoc,
      Sign,
      ContactList,
      ItemList,
      Image,
      Video,
      Document,
      Address,
      Unit
  }


  export interface IAddress {
    address1: string
    address2: string
    city: string
    state: string
    postCode: string
    country: string
  }

  export interface IValidation {
    validate?: boolean
    validated: boolean
    validationFailMessage: string
    validationMin: number
    validationMax: number
    validationExtra: string
  }

  export interface ITheme {
    colors: IThemeColorVar[]
  }

  export interface IThemeColorVar {
    varName: string
    value: string
  }

  export interface INav {
    certificate: INavCertificate
  }

  export interface INavCertificate {
     buildingId: string | null | undefined,
     certificateId: string | null | undefined
  }

  export interface IMessage {
    id: string,
    responseToID: string,
    clientID: string,

    contactIDFrom: string,
    contactFrom: IContact,

    contactIDTo: string,
    contactTo: IContact,

    userIDTo: string,
    userTo: IUser,
    userIDFrom: string,
    userFrom: IUser,
    certificateID: string,
    certificate: ICertificate,
    surveyID: string,
    questionID: string,
    subject: string,
    message: string,
    fromMessage: string,
    read: boolean,

    // url: string,
    // logitude: number,
    // latitude: number,
    // takenat: Date,
    // blobname: string,

    dateSent: Date,
    dateRead: Date,
    messageHistory: [{
      message :string,
      dateSent:Date,
      isReceived :boolean
    }]
  }

  export interface ITranslation  {
    language: string,
    description: string,
    guidance: string,
    operatorguidance: string,
    evidenceprompt: string,
    evidencename: string,
    evidenceguidance: string,
    options: string,
  }

  export interface IQuestion  {
    id?: string,
    type: number,
    description: string,
    guidance: string,
    operatorGuidance: string,
    helpURL: string,
    options: string,
    mandatory: boolean,
    mandatoryGracePeriod: number
    evidencerequired: number,
    evidencetype: number,
    evidenceprompt: string,
    evidenceguidance: string,
    evidenceHelpURL: string,
    evidencename: string,
    requiredafter: number,
    gpsTagged: boolean,
    gpsRingfenced: boolean,
    answer?: IAnswer,
    translations: ITranslation[],
    conditional: boolean,
    conditionalSurveyID: string,
    conditionalQuestionID: string,
    externalRef: string,
    conditionalDisabled: boolean
    premium: boolean

    validate?: boolean
    validationFailMessage: string
    validationMin: number
    validationMax: number
    validationExtra: string

  }


  export interface IAnswer
  {
    id: string
    questionID: string
    answer: string
    na?: boolean;
    answerLocal: string
    expires: Date 
    evidencesupplied: boolean 
    evidence: IEvidence
  }

  export interface IEvidence
  {
    id: string
    mimetype: string
    url: string
    longitude: number
    latitude: number
    takenat: Date 
    acceptedBy: string 
    blobname: string
    originalname: string
    acceptedOn: Date
  }
  


  export interface ISecurity {
    APIKey: string,
    Token: string
  }

  export interface IClient {
    id: string,
    parentID: string,
    contactID: string,
    contactInfo?: IContact,
    name: string,
    contact: string,
    phone: string,
    email: string,
  }

  export interface ISurveySection {
    id?: string,
    name: string,
    description: string,
    approvalsRequiredPercent: number,
    questions: IQuestion[],
    qaPass: boolean
    answersLoaded: boolean
    validationError: string

  }

  export interface IBuilding {
    id?: string,
    contactID: string,
    contactInfo?: IContact,
    clientID: string,
    manager: string,
    telephone: string,
    buildingName: string,
    address1: string,
    address2: string,
    city: string,
    country: string,
    postcode: string,
    latitude: number,
    longitude: number,
    unitCount: number,
  }

  export interface IContact{
    id?: string,
    contactType: number,
    name: string,
    email: string,
    avatar: string,
    pin? :IPin,
    telephones?: any
    notifyPinDetails?: boolean
  }

  export interface IPin {
    pinHash: string,
    issuedOn?:  Date,
    expiresOn?: Date,
    verified?: boolean
  }

  export interface IPhone {
    type: number,
    countryCode: string,
    areaCode: string,
    number: string,
    ext: string
  }

  export interface ICertificate {
    id : string
    buildingID : string
    building : IBuilding
    name : string
    hasExpectedCompletedDate: boolean
    expectedCompletedDate: string
    expiresOn : string
    surveys : ICertificateSurvey[]
    active: boolean
    issued: boolean
    issuedBy: string
    issuedUser: IUser
    issuedOn: Date
    premium: boolean
  }

  export interface ISurveyItem {
    id: string,
    name: string,
    description: string,
  }

  export interface ITemplateItem {
    id: string,
    name: string,
    description: string,
  }

  export interface ICertificateTemplate {
    id: string,
    name: string,
    description: string,
    surveys: ITemplateSurvey[],
  }


  export interface ITemplateSurvey {
    unitpercent: number,
    surveyID: string,
    surveyName: string,
    surveyDescription: string,
  }

  export interface ISurvey {
    id?: string,
    name: string,
    description: string,
    validFor: number,
    completionMethod: number,
    expectedCompletionPeriod: number,
    sections: ISurveySection[],
    
  }

  export interface ICertificateSurvey
  {
    id: string,
    key: string,
    certificateID: string,
    certificateName: string,
    surveyID: string,
    survey: ISurvey,
    unit: string,
    notes: string,
    hasExpectedCompletedDate: boolean
    expectedCompletedDate: string
    completedOn: Date,
    approvedOn: Date,
    isCompleted: boolean,
    isApproved: boolean,
    answers: IAnswer[],
    lastUpdated?: Date
    startDate?: Date
    assignedDate?: Date
  }

  export interface IUser {
    id?: string 
    clientID?: string,
    email?: string,
    role?: string,
    active?: boolean,
  }

  export const getEmailErrorMessage = (value: string): string => {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)) return "";
    return `Please enter a valid email address.`;
  };

  
  