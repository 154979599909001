import React, { Component } from 'react';
import axios from 'axios';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import HelloSignService from './components/HelloSignService'
import ReactPlayer from 'react-player'
import { API, APIKEY, IAnswer, IBuilding, ICertificate, ICertificateSurvey, IQuestion, IValidation, QuestionType, IAddress } from './IVesta';
import { ValidateAddress, ValidateText, ValidateInteger, ValidateCurrency } from './lib/Validation'
import { Mandatory, DateDiffDays } from './lib/venus';
import { Space, notification, Row, Col, Radio, Checkbox, Rate, Input, InputNumber, DatePicker, Select, Upload, Popover } from 'antd';
import { CheckOutlined, CloseOutlined, FileOutlined, FilePdfOutlined, FileWordOutlined, DownloadOutlined } from '@ant-design/icons';
import { FileImageOutlined, VideoCameraOutlined, InfoCircleOutlined, UploadOutlined } from '@ant-design/icons';
import ContactList from './ContactList'
import UserDefinedList from './UserDefinedList'
import { VButton } from './components/VButtons'
import { CertPlus, SkipForward } from './components/VenusIcons'
import DocumentViewer, { EContainerType, EMode } from './components/DocumentViewer'

import './App.css';
import 'antd/dist/antd.css';

const { Dragger } = Upload;

const { TextArea } = Input;
const { Option } = Select;
const moment = require('moment');

const PrimaryType: any = "primary";
const DashedType: any = "dashed";
const VButtonDashedCss: string = "VButtonDashed";
const VButtonNACss: string = "VButtonNA";
const VButtonDisabledCss: string = "VButtonDisabled";
const VButtonSelectedDisabledCss = "VButtonSelectedDisabled";
const VButtonCompletedCss = "VButtonCompleted";
const VButtonCompletedDisabledCss = "VButtonCompletedDisabled";

interface IState {
    building: IBuilding,
    certificate: ICertificate,
    certificatesurvey: ICertificateSurvey,
    textval: string,
    dateval: any,
    numval: number,
    currency: string,
    handleUpload: boolean,
    uploadMessage: string,
    disabled?: boolean,
    address: IAddress,
    validation: IValidation,
    showDocument: boolean,
    documentUri: string | null
    documentTitle: string | null | undefined,
    loading: boolean

}

interface IProps extends WrappedComponentProps {
    user: string,
    question: IQuestion,
    certificatesurveyid: string,
    onAnswered: any,
    hasEvidence?: boolean,
    displayEvidence?: any,
    onSiteSurvey?: boolean
    completed?: boolean
    startDate?: Date
}

interface IButtonCss {
    Css: string;
    Type: string;
}

function checkOverflow(e: any) {
    var el = e.currentTarget;
    var curOverflow = el.style.overflow;

    if (!curOverflow || curOverflow === "visible")
        el.style.overflow = "hidden";

    var isOverflowing = el.clientWidth < el.scrollWidth
        || el.clientHeight < el.scrollHeight;

    el.style.overflow = curOverflow;

    return isOverflowing;
}

class Question2 extends Component<IProps, IState>
{
    constructor(props: IProps) {
        super(props);

        var textval = "";
        var currency = "GBP";
        var numval = 0;
        var dateval = new moment();
        var address: IAddress = { address1: "", address2: "", city: "", state: "", postCode: "", country: "" };

        if (props.question.answer != null) {
            switch (props.question.type) {
                case 1: case 5: case 6: case 10: case 11: case 16: textval = props.question.answer.answer; break;
                case 2: case 7: numval = Number(props.question.answer.answer); break;
                case 3:
                    var opts = props.question.answer.answer.split(";");
                    currency = opts[0];
                    numval = Number(opts[1]);
                    break;
                case 4: dateval = new moment(props.question.answer.answer); break;
                case 15:
                    var flds = props.question.answer.answer.split("\n");
                    if (flds.length === 6) {
                        address.address1 = flds[0];
                        address.address2 = flds[1];
                        address.city = flds[2];
                        address.state = flds[3];
                        address.postCode = flds[4];
                        address.country = flds[5];
                    }
                    break;

            }
        }

        this.state = {
            certificate: {} as ICertificate,
            building: { address1: "" } as IBuilding,
            certificatesurvey: {} as ICertificateSurvey,
            textval: textval,
            dateval: dateval,
            numval: numval,
            currency: currency,
            handleUpload: true,
            uploadMessage: "",
            showDocument: false,
            documentUri: null,
            documentTitle: null,
            address: address,
            loading: false,


            validation: {
                validate: this.props.question.validate,
                validationFailMessage: this.props.question.validationFailMessage,
                validationMin: this.props.question.validationMin,
                validationMax: this.props.question.validationMax,
                validationExtra: this.props.question.validationExtra,
                validated: true,
            },

            disabled: this.props.completed
                ? true
                : false


        };

        this.saveContacts = this.saveContacts.bind(this);
        this.setText = this.setText.bind(this);
        this.saveText = this.saveText.bind(this);
        this.setNum = this.setNum.bind(this);
        this.setOption = this.setOption.bind(this);
        this.saveOption = this.saveOption.bind(this);
        this.saveCheck = this.saveCheck.bind(this);
        this.saveRate = this.saveRate.bind(this);
        this.setCurrencyVal = this.setCurrencyVal.bind(this);
        this.setCurrency = this.setCurrency.bind(this);
        this.saveCurrency = this.saveCurrency.bind(this);
        this.saveDate = this.saveDate.bind(this);
        this.saveRead = this.saveRead.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.saveExternal = this.saveExternal.bind(this);
        this.onTextAreaInput = this.onTextAreaInput.bind(this);
        this.saveEvidenceNA = this.saveEvidenceNA.bind(this);
        this.helloSignDocLink = this.helloSignDocLink.bind(this);
        this.setAddress = this.setAddress.bind(this);
        this.saveAddress = this.saveAddress.bind(this);
        this.showDocument = this.showDocument.bind(this);
        this.hideDocument = this.hideDocument.bind(this);
    }

    componentDidMount() {
    }


    helloSignDocLink(signatureId: string | null | undefined) {
        if (!signatureId) return;

        var $this = this;
        var errorMessage = this.props.intl.formatMessage({ id: "err.oops" });

        const post = {
            security: {
                APIKey: APIKEY,
                user: this.props.user,
            },

            signatureID: signatureId
        }

        axios.post(API + "/hellosign/GetSignLink", post)
            .then(response => {

                if (response.data.status.errorCode === 0) {
                    var url = response.data.url;
                    window.open(url);
                }
                else {
                    this.showNotification("Error", errorMessage);
                }

            })
            .catch(function (error) {
                console.log(error);
                $this.showNotification("Error", errorMessage);
            });

    }

    showNotification(title: string | null | undefined, message: string | null | undefined, placement: any = 'topRight') {
        notification.open({
            message: title ?? "Error",
            description: message ?? "",
            placement: placement

        });
    }

    save(ans: any, na?: boolean) {
        if (this.state.disabled) return;

        const post = {
            security: {
                APIKey: APIKEY,
                user: this.props.user,
            },
            certificateSurveyID: this.props.certificatesurveyid,
            questionID: this.props.question.id,
            answer: ans,
            nA: na
        }
        axios.post(API + "/survey/answer", post)
            .then(response => {
                this.props.onAnswered();
                this.forceUpdate();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    saveEvidenceNA() {
        if (this.state.disabled) return;

        const data = new FormData();
        data.append("certificateSurveyID", this.props.certificatesurveyid);
        data.append("questionID", this.props?.question?.id ?? "");
        data.append("answer", "NO EVIDENCE");
        data.append("nA", "True")
        data.append("APIKEY", APIKEY);
        data.append("user", this.props.user);

        axios.post(API + "/survey/venusaddevidence", data, { headers: { "Content-Type": "multipart/form-data" } })
            .then(response => {
                this.props.onAnswered();
                this.forceUpdate();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    saveExternal() {
        if (this.state.disabled) return;

        this.props.onAnswered();
        this.forceUpdate();
    }

    saveContacts(value: string) {
        if (this.state.disabled) return;

        this.setState({ textval: value });
        this.save(value);
    }

    setText(e: any) {
        if (this.state.disabled) return;

        const { value } = e.target;
        this.setState({ textval: value });
    }

    saveText(value: string, na?: boolean) {
        if (this.state.disabled) return;

        if (!na) {
            var validation = this.state.validation
            validation.validated = ValidateText(value, this.props.question.mandatory, this.state.validation);

            if (!validation.validationFailMessage || validation.validationFailMessage === "") {
                validation.validationFailMessage = this.props.intl.formatMessage({ id: "validation.required" });
            }

            this.setState({ validation: validation });

            if (!validation.validated) {
                return;
            }
        }

        this.save(value, na);

    }

    setAddress(fld: string, e: any) {
        if (this.state.disabled) return;

        var address = this.state.address;

        const { value } = e.target;
        switch (fld) {
            case "address1":
                address.address1 = value;
                break;

            case "address2":
                address.address2 = value
                break;

            case "city":
                address.city = value;
                break;

            case "state":
                address.state = value;
                break;

            case "postcode":
                address.postCode = value
                break;

            case "country":
                address.country = value;
                break;
        }

        this.setState({ address: address });

    }

    saveAddress(na: boolean) {
        if (this.state.disabled) return;

        if (!na) {
            var validation: IValidation = ValidateAddress(this.state.address);

            this.setState({ validation: validation })

            if (!validation.validated) {
                return;
            }
        }

        const value =
            this.state.address.address1 + "\n" +
            this.state.address.address2 + "\n" +
            this.state.address.city + "\n" +
            this.state.address.state + "\n" +
            this.state.address.postCode + "\n" +
            this.state.address.country;

        this.save(value, na);
    }

    saveRead(e: any) {
        if (this.state.disabled) return;

        this.save("True");
    }

    saveCheck(e: any) {
        if (this.state.disabled) return;

        this.setState({ textval: e.join(";") });
        this.save(e.join(";"));
    }

    setOption(e: any) {
        if (this.state.disabled) return;

        const { value } = e.target;
        this.setState({ textval: value });
        // this.save(value.toString());
    }

    saveOption(value: string, na: boolean) {
        if (this.state.disabled) return;

        if (!na) {
            var validation = this.state.validation
            validation.validated = ValidateText(value, this.props.question.mandatory, this.state.validation);

            if (!validation.validationFailMessage || validation.validationFailMessage === "") {
                validation.validationFailMessage = this.props.intl.formatMessage({ id: "validation.required" });
            }

            this.setState({ validation: validation });

            if (!validation.validated) {
                return;
            }
        }

        this.save(value, na);


    }

    saveRate(e: number) {
        if (this.state.disabled) return;

        this.setState({ numval: e });
        this.save(e.toString());
    }

    setNum(val: any | string | null | undefined) {
        if (this.state.disabled) return;
        if (typeof (val) === "number") this.setState({ numval: val });
    }

    saveNum(val: number, na?: boolean) {
        if (this.state.disabled) return;

        if (!na) {
            var validation = this.state.validation
            validation.validated = ValidateInteger(val, this.props.question.mandatory, this.state.validation);

            if (!validation.validationFailMessage || validation.validationFailMessage === "") {
                validation.validationFailMessage = this.props.intl.formatMessage({ id: "validation.required" });
            }

            this.setState({ validation: validation });

            if (!validation.validated) {
                return;
            }
        }

        this.save(val.toString(), na);

    }

    setCurrencyVal(e: any) {
        if (this.state.disabled) return;

        const value = e.target.value;
        if (isNaN(parseFloat(value))) this.setState({ numval: 0 });
        else this.setState({ numval: parseFloat(value) });
    }

    setCurrency(val: string) {
        if (this.state.disabled) return;

        this.setState({ currency: val });
    }

    saveCurrency(curr: string, value: number, na: boolean) {
        if (this.state.disabled) return;

        if (!na) {
            var validation = this.state.validation
            validation.validated = ValidateCurrency(curr, value, this.props.question.mandatory, this.state.validation);

            if (!validation.validationFailMessage || validation.validationFailMessage === "") {
                validation.validationFailMessage = this.props.intl.formatMessage({ id: "validation.required" });
            }

            this.setState({ validation: validation });

            if (!validation.validated) {
                return;
            }
        }


        var answer = this.state.currency + ";" + this.state.numval.toString()
        this.save(answer, na);

    }

    saveDate(val: any) {
        if (this.state.disabled) return;

        this.setState({ dateval: val })
        //this.save(val.format());
        //this.setState({currencyval: Number(val.target.nodeValue || 0)});
    }

    yes() {
        if (this.state.disabled) return;

        this.save("True");
    }

    renderuploaditem() {
        return <></>
    }

    handleChange(info) {

        if (!this.state.handleUpload) return;

        var validation = this.state.validation;
        validation.validated = true;
        validation.validationFailMessage = "";
        this.setState({ validation: validation });

        if (info.file != null) {
            if (info.file.status === "done") {
                if (info.file.response?.status?.errorCode === 0) {
                    validation.validated = true;
                    validation.validationFailMessage = "";
                    this.setState({ validation: validation, uploadMessage: "", loading: false });

                    this.props.onAnswered();

                    return;
                }
                else {

                    validation.validated = false;
                    validation.validationFailMessage = info.file.response?.status?.message ?? "";

                    this.setState({ validation: validation, uploadMessage: "", loading: false });
                }
            }

            if (info.file.status === "error") {
                validation.validated = false;
                validation.validationFailMessage = this.props.intl.formatMessage({ id: "upload.error" })
                this.setState({ validation: validation, uploadMessage: "", loading: false });
            }

        }
    }



    onTextAreaInput(e: any) {
        var el = e.currentTarget;
        var isOverflowing = checkOverflow(e);

        if (isOverflowing) {
            ++el.rows
            el.data = "ov";
        }
        else if (el.data && el.rows > 1) {
            --el.rows;
            if (checkOverflow(e)) {
                ++el.rows;
            }
        }
    }



    assistPopOver(guidance?: string, helpUrl?: string) {
        if (!guidance && !helpUrl) return <></>

        const content =
            <>
                <div className={"AssistGuidance"}>
                    {guidance}
                </div>

                {helpUrl &&
                    <div className="AssistHelpUrl">
                        You can view the help document <a href="#" onClick={() => this.openWin('https://' + this.props.question.helpURL)}>here</a>
                    </div>
                }

            </>

        return (
            <Popover placement="left" content={content} trigger="click"   >
                <div className="AssistIcon">
                    {(guidance || helpUrl) &&
                        <InfoCircleOutlined />
                    }


                </div>
            </Popover>
        );
    }

    openWin(url: string) {
        var browser = window.self;
        browser.open(url);
    }


    GetButtonCss(answer: IAnswer | null | undefined, btn: number, answered: boolean = false, disable: boolean = false): IButtonCss {

        var disabled = this.state.disabled || disable;

        var ret: IButtonCss = {
            Css: answered
                ? disabled ? VButtonCompletedDisabledCss : VButtonCompletedCss
                : VButtonNACss,
            Type: PrimaryType
        }

        var bDisabled = {
            Css: VButtonDisabledCss,
            Type: PrimaryType
        }

        if (disabled && !answer) {
            return bDisabled;
        }

        switch (btn) {
            case 3: ret = { Css: VButtonNACss, Type: PrimaryType }; break;
        }

        if (!answer) {

            return ret;
        }

        if (btn === 3) {
            if (answer?.na === true) {
                ret = { Css: disabled ? VButtonSelectedDisabledCss : VButtonCompletedCss, Type: PrimaryType }
            } else {
                ret = { Css: disabled ? VButtonDisabledCss : VButtonDashedCss, Type: DashedType }
            }
        }

        const na = answer?.na ? true : false;

        if (btn === 2 && (answer?.answer === "True" || na)) {
            return { Css: disabled ? VButtonDisabledCss : VButtonDashedCss, Type: DashedType }
        }

        if (btn === 1 && (answer?.answer === "False" || na)) {
            return { Css: disabled ? VButtonDisabledCss : VButtonDashedCss, Type: DashedType }
        }

        return ret;
    }


    getCheckRadioCss(answered: boolean, column: number, disabled: boolean) {
        if (disabled) return "";

        return answered
            ? "check-radio-complete"
            : "check-radio"
    }

    showDocument(documentUri: string, title?: string | null | undefined) {
        this.setState({ showDocument: true, documentUri: documentUri, documentTitle: title });
    }

    hideDocument() {
        this.setState({ showDocument: false, documentUri: null });
    }

    render() {

        if (this.props.question == null) return <Space></Space>;

        var action2: any = null;
        var action3: any = null;

        var questionfields = <div></div>;

        var button1Css: IButtonCss;
        var button2Css: IButtonCss;
        var button3Css: IButtonCss;
        var answered = (this.props.question.answer) ? true : false
        var updateButtonText = answered ? "Update" : "Submit"
        var disabled = this.state.disabled;
        var controlBorder = disabled ? "BorderDisabled" : "BorderEnabled";
        var buttonDisabled: boolean | undefined = false;
        var checkCss: string = "";
        var ansimage = <></>;

        switch (this.props.question.type) {
            case QuestionType.YesNo: //  Y/N
                button1Css = this.GetButtonCss(this.props.question.answer, 1, answered);
                button2Css = this.GetButtonCss(this.props.question.answer, 2, answered);
                button3Css = this.GetButtonCss(this.props.question.answer, 3, answered);

                questionfields =
                    <>
                        <div className="question-button">
                            {!this.props.question.mandatory &&
                                <VButton className={button3Css.Css} disabled={disabled} type={button3Css.Type} icon={<SkipForward />} text="N/A" onClick={() => this.save("False", true)} />
                            }
                        </div>
                        <div className="question-button">
                            <VButton className={button1Css.Css} disabled={disabled} type={button1Css.Type} icon={<CheckOutlined />} onClick={() => this.save("True", false)} text="Yes" />
                        </div>
                        <div className="question-button">
                            <VButton className={button2Css.Css} disabled={disabled} type={button2Css.Type} icon={<CloseOutlined />} text="No" onClick={() => this.save("False", false)} />
                        </div>
                    </>
                break;

            case QuestionType.Text: // Text,
            case QuestionType.Unit: // Unit,
                button1Css = this.GetButtonCss(this.props.question.answer, 1, answered);
                button3Css = this.GetButtonCss(this.props.question.answer, 3, answered);

                buttonDisabled = disabled;
                if (!disabled) {
                    buttonDisabled = !ValidateText(this.state.textval, this.props.question.mandatory, this.state.validation)
                    if (buttonDisabled) {
                        button1Css.Css = VButtonDisabledCss;

                    }
                }

                questionfields =
                    <Row className="Row" >
                        <Col style={{ width: "100%" }}>
                            <TextArea className={"TextArea " + controlBorder}
                                placeholder={this.props.question.options}
                                onChange={this.setText}
                                value={this.state.textval}
                                rows={1}
                                onInput={this.onTextAreaInput}
                                disabled={disabled}
                            />
                        </Col>
                    </Row>

                action3 =
                    <div className="question-button-body">
                        <div className="question-buttons">
                            <div className="question-button">
                                {!this.props.question.mandatory &&
                                    <VButton className={button3Css.Css} type={button3Css.Type} disabled={disabled} icon={<SkipForward />} text="N/A" onClick={() => this.saveText(this.state.textval, true)} />
                                }
                            </div>
                            <div className="question-button">&nbsp;</div>
                            <div className="question-button">
                                <VButton type={button1Css.Type} className={button1Css.Css} disabled={buttonDisabled} icon={<CheckOutlined />} text={updateButtonText} onClick={() => this.saveText(this.state.textval, false)} />
                            </div>
                        </div>
                    </div>

                break;

            case QuestionType.Number: // Number,
                button1Css = this.GetButtonCss(this.props.question.answer, 1, answered);
                button3Css = this.GetButtonCss(this.props.question.answer, 3, answered);

                buttonDisabled = disabled;
                if (!disabled) {
                    buttonDisabled = !ValidateInteger(this.state.numval + "", this.props.question.mandatory, this.state.validation)
                    if (buttonDisabled) {
                        button1Css.Css = VButtonDisabledCss;

                    }
                }

                questionfields =
                    <Row className="Row">
                        <InputNumber className={"Input Number " + controlBorder} disabled={disabled} placeholder="Please enter your answer" onChange={this.setNum} value={this.state.numval} />
                    </Row>

                action3 =
                    <div className="question-button-body">
                        <div className="question-buttons">
                            <div className="question-button">
                                {!this.props.question.mandatory &&

                                    <VButton className={button3Css.Css} type={button3Css.Type} disabled={disabled} icon={<SkipForward />} text="N/A" onClick={() => this.saveNum(this.state.numval, true)} />
                                }
                            </div>
                            <div className="question-button">&nbsp;</div>
                            <div className="question-button">
                                <VButton className={button1Css.Css} type={button1Css.Type} disabled={buttonDisabled} icon={<CheckOutlined />} text={updateButtonText} onClick={() => this.saveNum(this.state.numval, false)} />
                            </div>
                        </div>
                    </div>

                break;

            case QuestionType.Currency: // Currency,
                button1Css = this.GetButtonCss(this.props.question.answer, 1, answered);
                button3Css = this.GetButtonCss(this.props.question.answer, 3, answered);

                buttonDisabled = disabled;
                if (!disabled) {
                    buttonDisabled = !ValidateCurrency(this.state.currency, this.state.numval + "", this.props.question.mandatory, this.state.validation)
                    if (buttonDisabled) {
                        button1Css.Css = VButtonDisabledCss;

                    }
                }

                const selectBefore = (
                    <Select className={"select-before " + controlBorder} disabled={disabled} value={this.state.currency} bordered={false} onChange={this.setCurrency}>
                        <Option value="GBP">(GBP) £</Option>
                        <Option value="USD">(USD) $</Option>
                        <Option value="EUR">(EUR) Ꞓ</Option>
                        <Option value="YEN">(YEN) ¥</Option>
                    </Select>
                );

                questionfields =
                    <Row className="Row">
                        <Col>
                            {selectBefore}&nbsp;&nbsp;
                        </Col>
                        <Col>
                            <Input disabled={disabled} className={"Input Curr " + controlBorder} placeholder="0.00" onChange={this.setCurrencyVal} value={this.state.numval} />
                        </Col>
                    </Row>

                action3 =
                    <div className="ant-select-focus question-button-body">
                        <div className="question-buttons">
                            <div className="question-button">
                                {!this.props.question.mandatory &&
                                    <VButton className={button3Css.Css} type={button3Css.Type} disabled={disabled} icon={<SkipForward />} text="N/A" onClick={() => this.saveCurrency(this.state.currency, this.state.numval, true)} />
                                }
                            </div>
                            <div className="question-button">&nbsp;</div>
                            <div className="question-button">
                                <VButton className={button1Css.Css} type={button1Css.Type} disabled={buttonDisabled} icon={<CheckOutlined />} text={updateButtonText} onClick={() => this.saveCurrency(this.state.currency, this.state.numval, false)} />
                            </div>
                        </div>
                    </div>

                break;

            case QuestionType.Date: // Date,
                button1Css = this.GetButtonCss(this.props.question.answer, 1, answered);
                button3Css = this.GetButtonCss(this.props.question.answer, 3, answered);

                questionfields =
                    <Row className="Row">
                        <DatePicker disabled={disabled} className={"Input Date " + controlBorder} allowClear={false} placeholder="Please pick a date" format={"LL"} onChange={this.saveDate} value={this.state.dateval} />
                    </Row>

                action3 =
                    <div className="question-button-body">
                        <div className="question-buttons">
                            <div className="question-button">
                                {!this.props.question.mandatory &&
                                    <VButton className={button3Css.Css} type={button3Css.Type} disabled={disabled} icon={<SkipForward />} text="N/A" onClick={() => this.save(this.state.dateval, true)} />
                                }
                            </div>
                            <div className="question-button">&nbsp;</div>
                            <div className="question-button">
                                <VButton className={button1Css.Css} type={button1Css.Type} disabled={disabled} icon={<CheckOutlined />} text={updateButtonText} onClick={() => this.save(this.state.dateval, false)} />
                            </div>
                        </div>
                    </div>

                break;

            case QuestionType.OneOf: // One Of,
                button1Css = this.GetButtonCss(this.props.question.answer, 1, answered);
                button3Css = this.GetButtonCss(this.props.question.answer, 3, answered);
                checkCss = this.getCheckRadioCss(answered, 0, disabled ?? false);

                buttonDisabled = disabled;
                if (!disabled) {
                    buttonDisabled = !ValidateText(this.state.textval, this.props.question.mandatory, this.state.validation)
                    if (buttonDisabled) {
                        button1Css.Css = VButtonDisabledCss;
                    }
                }

                const optionArr = this.props.question.options.split(";");
                const normalize = (str) => str.toLowerCase().replace(/\s+/g, '');
                const normalizedOptions = optionArr.map(normalize);

                var options = optionArr.map((o, i) => {

                    return <>
                        <div className="group-column radio" >
                            <Radio key={"ONOF" + i.toString()} value={i.toString()}>
                                {o}
                            </Radio>
                        </div>
                    </>
                });
                let radioGroupValue;

                const answers = this.state.textval;
                if (answers !== null) {
                    const normalizedAnswer = normalize(answers);
                    const index = parseInt(normalizedAnswer, 10);
                    if (!isNaN(index) && normalizedOptions[index] !== undefined) {
                        radioGroupValue = index.toString();
                    } else {
                        const stringIndex = normalizedOptions.indexOf(normalizedAnswer);
                        if (stringIndex !== -1) {
                            radioGroupValue = stringIndex.toString();
                        } else {
                            radioGroupValue = normalizedAnswer;
                        }
                                }
                            }

                questionfields =
                    <Row className="Row">
                        <Radio.Group className={checkCss} disabled={disabled} onChange={this.setOption} value={radioGroupValue}>
                            <Row className="group-row" key="radio-row">
                                {options}
                            </Row>
                        </Radio.Group>
                    </Row>

                action3 =
                    <div className="question-button-body">
                        <div className="question-buttons">
                            <div className="question-button">
                                {!this.props.question.mandatory &&
                                    <VButton className={button3Css.Css} type={button3Css.Type} disabled={disabled} icon={<SkipForward />} text="N/A" onClick={() => this.saveOption(this.state.textval, true)} />
                                }
                            </div>
                            <div className="question-button">&nbsp;</div>
                            <div className="question-button">
                                <VButton className={button1Css.Css} type={button1Css.Type} disabled={buttonDisabled} icon={<CheckOutlined />} text={updateButtonText} onClick={() => this.saveOption(this.state.textval, false)} />
                            </div>
                        </div>
                    </div>

                break;

            case QuestionType.ManyOf: // Many Of,
                button1Css = this.GetButtonCss(this.props.question.answer, 1, answered);
                button3Css = this.GetButtonCss(this.props.question.answer, 3, answered);
                checkCss = this.getCheckRadioCss(answered, 0, disabled ?? false);

                buttonDisabled = disabled;
                if (!disabled) {
                    buttonDisabled = !ValidateText(this.state.textval, this.props.question.mandatory, this.state.validation)
                    if (buttonDisabled) {
                        button1Css.Css = VButtonDisabledCss;
                    }
                }

                var checked = this.state.textval.split(";");

                var checks = this.props.question.options.split(";").map((o, i) => {
                    return <>
                        <div className="group-column radio" >
                            <Checkbox value={i.toString()}>{o}</Checkbox>
                        </div>
                    </>

                });

                questionfields =
                    <Row className="Row">
                        <Checkbox.Group className={checkCss} disabled={disabled} onChange={(e) => this.setState({ textval: e.join(";") })} defaultValue={checked} value={checked}  >
                            <Row className="group-row" key="check-row">
                                {checks}
                            </Row>
                        </Checkbox.Group>
                    </Row>

                action3 =
                    <div className="question-button-body">
                        <div className="question-buttons">
                            <div className="question-button">
                                {!this.props.question.mandatory &&
                                    <VButton className={button3Css.Css} type={button3Css.Type} disabled={disabled} icon={<SkipForward />} text="N/A" onClick={() => this.saveOption(this.state.textval, true)} />
                                }
                            </div>
                            <div className="question-button">&nbsp;</div>
                            <div className="question-button">
                                <VButton className={button1Css.Css} type={button1Css.Type} disabled={buttonDisabled} icon={<CheckOutlined />} text={updateButtonText} onClick={() => this.saveOption(this.state.textval, false)} />
                            </div>
                        </div>
                    </div>

                break;

            case QuestionType.Rate: // Rate,
                button1Css = this.GetButtonCss(this.props.question.answer, 1, answered);
                button3Css = this.GetButtonCss(this.props.question.answer, 3, answered);

                questionfields =
                    <Row className="Row">
                        <Rate disabled={disabled} onChange={(e) => this.setState({ numval: e })} value={this.state.numval} />
                    </Row>

                action3 =
                    <div className="question-button-body">
                        <div className="question-buttons">
                            <div className="question-button">
                                {!this.props.question.mandatory &&
                                    <VButton className={button3Css.Css} type={button3Css.Type} disabled={disabled} icon={<SkipForward />} text="N/A" onClick={() => this.save(this.state.numval.toString(), true)} />
                                }
                            </div>
                            <div className="question-button">&nbsp;</div>
                            <div className="question-button">
                                <VButton className={button1Css.Css} type={button1Css.Type} disabled={disabled} icon={<CheckOutlined />} text={updateButtonText} onClick={() => this.save(this.state.numval.toString(), false)} />
                            </div>
                        </div>
                    </div>

                break;

            case QuestionType.ReadDoc: // Read Document,
                button1Css = this.GetButtonCss(this.props.question.answer, 1, answered);
                button3Css = this.GetButtonCss(this.props.question.answer, 3, answered);
                checkCss = this.getCheckRadioCss(answered, 0, disabled ?? false);
                var ticked = answered ? this.props.question.answer?.answer === "True" : false;

                questionfields = <>
                    <Row className="Row">

                        <div className="question-button-body">
                            <div className="question-buttons">
                                <div className="question-button">
                                    {!this.props.question.mandatory &&
                                        <VButton className={button3Css.Css} type={button3Css.Type} disabled={disabled} icon={<SkipForward />} text="N/A" onClick={() => this.save("False", true)} />
                                    }
                                </div>
                                <div className="question-button">
                                    {/* <VButton className={button1Css.Css} type={button1Css.Type} disabled={disabled} icon={<DownloadOutlined style={{ fontSize: 24 }} />} text="Read"
                                        onClick={() => this.showDocument("https://mysasure-bucket.s3.eu-west-2.amazonaws.com/" + this.props.question.options, "Terms and Conditions")}
                                    /> */}
                                    &nbsp;
                                </div>

                                <div className="question-button">
                                    <a href={"https://mysasure-bucket.s3.eu-west-2.amazonaws.com/" + this.props.question.options} target="_blank" rel="noreferrer" >
                                        <VButton className={button1Css.Css} type={button1Css.Type} disabled={disabled} icon={<DownloadOutlined style={{ fontSize: 24 }} />} text="Download" />
                                    </a>
                                </div>
                            </div>
                        </div>

                    </Row>

                    <Row className="Row">
                        <Col span={24} className={checkCss}>
                            <Checkbox disabled={disabled} checked={ticked} onChange={this.saveRead} >Click to confirm you have read the document.</Checkbox>
                        </Col>
                    </Row></>

                break;

            case QuestionType.Sign: // Sign,
                button1Css = this.GetButtonCss(this.props.question.answer, 1, answered, answered === true);
                button2Css = this.GetButtonCss(this.props.question.answer, 1, answered);
                button3Css = this.GetButtonCss(this.props.question.answer, 3, answered);

                const answer = this.props.question.answer?.answer ?? "";

                var downLoad = answered && answer.length > 5
                    ? <VButton className={button2Css.Css} type={button2Css.Type} disabled={disabled} icon={<DownloadOutlined style={{ fontSize: 24 }} />} text="Download" onClick={() => this.helloSignDocLink(this.props.question.answer?.answer)} />
                    : <>&nbsp;</>

                action3 =
                    <>


                        <div className="question-button-body">
                            <div className="question-buttons">

                                <div className="question-button">
                                    {!this.props.question.mandatory &&
                                        <VButton className={button3Css.Css} type={button3Css.Type} disabled={disabled} icon={<SkipForward />} text="N/A" onClick={() => this.save("False", true)} />
                                    }
                                </div>
                                <div className="question-button">{downLoad}</div>
                                <div className="question-button">
                                    <HelloSignService
                                        user={this.props.user}
                                        questionId={this.props.question.id}
                                        certificateId={this.props.certificatesurveyid}
                                        answer={this.props.question.answer}
                                        onSave={this.saveExternal}
                                        disabled={disabled}
                                        className={button1Css.Css}
                                    />
                                </div>

                            </div>
                        </div>

                    </>

                break;

            case QuestionType.ContactList: // Contact List,
                questionfields =
                    <Row className="Row">
                        <ContactList disabled={disabled} options={this.props.question.options} contacts={this.state.textval} onChange={this.saveContacts} />
                    </Row>

                break;

            case QuestionType.ItemList: // User Defined List,
                questionfields =
                    <Row className="Row">
                        <UserDefinedList disabled={disabled} options={this.props.question.options} items={this.state.textval} onChange={this.saveContacts} />
                    </Row>

                break;

            case QuestionType.Image: // Image,
                button3Css = this.GetButtonCss(this.props.question.answer, 3, answered);

                ansimage = <FileImageOutlined />;
                if (this.props.question.answer != null) {
                    if (this.props.question.answer.evidence != null) {
                        ansimage = <img src={this.props.question.answer?.evidence?.url || ""} className="AnswerImage" />
                    }
                }

                action2 =
                    <>
                        <Row className="Row">
                            <Col style={{ width: "100%" }}>
                                <Dragger action={API + "/survey/venusaddevidence"}
                                    className="Dragger"
                                    disabled={disabled}
                                    beforeUpload={
                                        file => {
                                            if (["image/png", "image/jpg", "image/jpeg"].includes(file.type)) {
                                                this.setState({ uploadMessage: "Uploading..." });
                                                return true;
                                            }
                                            this.setState({ uploadMessage: "Wrong type of file" });
                                            return false;
                                        }
                                    }

                                    itemRender={this.renderuploaditem}
                                    onChange={this.handleChange}
                                    listType="picture"

                                    data={{
                                        APIKey: APIKEY,
                                        user: this.props.user,
                                        questionID: this.props.question.id,
                                        CertificateSurveyID: this.props.certificatesurveyid,
                                    }}>

                                    {/* <p className="ant-upload-drag-icon" >
                                        {ansimage}
                                    </p> */}

                                    <div className="dragger-body">
                                        <div className="icon">{ansimage}</div>
                                        <div className="text">
                                            <p className="ant-upload-text">{this.props.question.evidencename}</p>
                                            <p className="ant-upload-hint bold">Click or drag file to this area to upload</p>
                                            <p className="ant-upload-hint">{this.props.question.evidenceguidance}</p>

                                        </div>
                                    </div>
                                </Dragger>

                            </Col>
                        </Row>
                        {!this.props.question.mandatory &&
                            <Row className="Row">
                                <div className="question-button-body">
                                    <div className="question-buttons">

                                        <div className="question-button">
                                            {!this.props.question.mandatory &&
                                                <VButton className={button3Css.Css} type={button3Css.Type} disabled={disabled} icon={<SkipForward />} text="N/A" onClick={this.saveEvidenceNA} />
                                            }
                                        </div>
                                        <div className="question-button">&nbsp;</div>
                                        <div className="question-button">&nbsp;</div>
                                    </div>

                                </div>
                            </Row>
                        }

                        {this.state.uploadMessage !== "" &&
                            <Row className="Row">
                                {this.state.uploadMessage}
                            </Row>
                        }


                    </>

                break;

            case QuestionType.Video: // Video,
                button3Css = this.GetButtonCss(this.props.question.answer, 3, answered);

                ansimage = <VideoCameraOutlined />;
                if (this.props.question.answer != null) {
                    if (this.props.question.answer.evidence != null) {
                        ansimage = <ReactPlayer url={this.props.question.answer?.evidence?.url || ""} width={250} controls={true} className="AnswerImage" />
                    }
                }

                action2 =
                    <>
                        <Row className="Row" >
                            <Col style={{ width: "100%" }}>
                                <Dragger action={API + "/survey/venusaddevidence"}
                                    className="Dragger" style={{ width: "100%" }}
                                    disabled={disabled}
                                    beforeUpload={
                                        file => {
                                            if (["video/mpg", "video/mp4","video/quicktime"].includes(file.type)) {
                                                this.setState({ uploadMessage: "Uploading..." });
                                                return true;
                                            }
                                            this.setState({ uploadMessage: "Wrong type of file" });
                                            return false;
                                        }
                                    }

                                    itemRender={this.renderuploaditem}
                                    onChange={this.handleChange}
                                    listType="picture-card"

                                    data={{
                                        APIKey: APIKEY,
                                        user: this.props.user,
                                        questionID: this.props.question.id,
                                        CertificateSurveyID: this.props.certificatesurveyid,
                                    }}>

                                    <div className="dragger-body">
                                        <div className="icon">{ansimage}</div>
                                        <div className="text">
                                            <p className="ant-upload-text">{this.props.question.evidencename}</p>
                                            <p className="ant-upload-hint bold">Click or drag file to this area to upload</p>
                                            <p className="ant-upload-hint">{this.props.question.evidenceguidance}</p>
                                        </div>
                                    </div>

                                </Dragger>

                            </Col>
                        </Row>
                        {!this.props.question.mandatory &&
                            <Row className="Row">
                                <div className="question-button-body">
                                    <div className="question-buttons">

                                        <div className="question-button">

                                            {!this.props.question.mandatory &&
                                                <VButton className={button3Css.Css} type={button3Css.Type} disabled={disabled} icon={<SkipForward />} text="N/A" onClick={this.saveEvidenceNA} />
                                            }
                                        </div>
                                        <div className="question-button">&nbsp;</div>
                                        <div className="question-button">&nbsp;</div>

                                    </div>
                                </div>
                            </Row>
                        }

                        {this.state.uploadMessage !== "" &&
                            <Row className="Row">
                                {this.state.uploadMessage}
                            </Row>
                        }

                    </>

                break;

            case QuestionType.Document: {
                var $this = this;

                const uploadTo = API + "/survey/venusaddevidence";

                button1Css = this.GetButtonCss(this.props.question.answer, 1, answered);
                button2Css = this.GetButtonCss(this.props.question.answer, 2, answered);
                button3Css = this.GetButtonCss(this.props.question.answer, 3, answered);
                buttonDisabled = disabled;

                const answer = this.props.question.answer?.evidence?.url ?? "";
                const fileName = this.props.question.answer?.evidence?.originalname ?? "";
                const fileIcon = fileName.indexOf(".pdf") > 0
                    ? <FilePdfOutlined />
                    : fileName.indexOf(".doc") > 0 || fileName.indexOf(".docx")
                        ? <FileWordOutlined />
                        : <FileOutlined />

                // var downLoad = answered
                //     ? <VButton className={button2Css.Css} type={button2Css.Type} disabled={disabled} icon={<DownloadOutlined style={{ fontSize: 24 }} />} text="Download" onClick={() => this.openWin(answer)} />
                //     : <>&nbsp;</>

                var grace = "";

                if (this.props.question.mandatoryGracePeriod > 0) {

                    var startDate = this.props.startDate ? new Date(this.props.startDate) : new Date();
                    var days = Math.round(this.props.question.mandatoryGracePeriod - DateDiffDays(new Date(), new Date(startDate)));

                    grace = days > 1
                        ? `${days} days`
                        : `${days} day`
                    this.props.intl.formatMessage({ id: "mandatory.graceperiod.help" }).replace("{period}", grace)

                }
                const props = {
                    name: "file",
                    action: uploadTo,
                    onChange(info: any) {
                        $this.handleChange(info)
                    },

                    data: {
                        APIKey: APIKEY,
                        user: this.props.user,
                        questionID: this.props.question.id,
                        CertificateSurveyID: this.props.certificatesurveyid,
                    }
                }

                action2 = answered && !this.props.question.answer?.na
                    ? <div className="doc-url" onClick={() => this.openWin(answer)}>
                        <div className="icon">{fileIcon}</div>
                        <div className="link">{fileName}</div>
                    </div>
                    : <></>

                action3 =
                    <>
                        <Row>
                            <Col style={{ width: "100%" }}>
                                <div className="question-button-body">
                                    <div className="question-buttons">
                                        <div className="question-button">
                                            {!Mandatory(this.props.question, this.props.startDate ?? new Date()) &&
                                                <VButton className={button3Css.Css} type={button3Css.Type} disabled={disabled} icon={<SkipForward />} text="N/A" onClick={() => this.saveAddress(true)} />
                                            }
                                        </div>
                                        <div className="question-button">&nbsp;</div>
                                        <div className="question-button">
                                            <Upload
                                                beforeUpload={

                                                    file => {
                                                        const validation = this.state.validation;
                                                        if (["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"].includes(file.type)) {
                                                            validation.validated = true;
                                                            validation.validationFailMessage = ""
                                                            this.setState({ loading: true, validation: validation, handleUpload: true });
                                                            return true;
                                                        }

                                                        validation.validated = false;
                                                        validation.validationFailMessage = "Please use PDF or MS word documents"
                                                        this.setState({ validation: validation, handleUpload: false, loading: false });
                                                        return false;
                                                    }
                                                }
                                                {...props} >
                                                <VButton type={button1Css.Type} loading={this.state.loading} loadingText="Uploading..." className={button1Css.Css} disabled={buttonDisabled} icon={<UploadOutlined />} text="Upload" />
                                            </Upload>
                                        </div>
                                    </div>

                                </div>
                            </Col>
                            {this.props.question.mandatoryGracePeriod > 0 &&
                                <div className="card-help-info">
                                    {this.props.intl.formatMessage({ id: "mandatory.graceperiod.help" }).replace("{period}", grace)}
                                </div>
                            }
                        </Row>

                    </>

                break;
            }

            case QuestionType.Address: {

                button1Css = this.GetButtonCss(this.props.question.answer, 1, answered);
                button3Css = this.GetButtonCss(this.props.question.answer, 3, answered);
                buttonDisabled = disabled;

                if (!disabled) {
                    buttonDisabled = !ValidateAddress(this.state.address).validated
                    if (buttonDisabled) {
                        button1Css.Css = VButtonDisabledCss;
                    }
                }

                questionfields =
                    <div className="InputAddress">
                        <Row className="Row">
                            <Input className={"Input Text " + controlBorder} disabled={disabled} placeholder="Address #1" onChange={(e) => this.setAddress("address1", e)} value={this.state.address.address1} />
                        </Row>

                        <Row className="Row">
                            <Input className={"Input Text " + controlBorder} disabled={disabled} placeholder="Address #2 (Optional)" onChange={(e) => this.setAddress("address2", e)} value={this.state.address.address2} />
                        </Row>

                        <Row className="Row">
                            <Input className={"Input Text City " + controlBorder} disabled={disabled} placeholder="City" onChange={(e) => this.setAddress("city", e)} value={this.state.address.city} />
                        </Row>

                        <Row className="Row">
                            <Input className={"Input Text State " + controlBorder} disabled={disabled} placeholder="State  (Optional)" onChange={(e) => this.setAddress("state", e)} value={this.state.address.state} />
                        </Row>

                        <Row className="Row">
                            <Input className={"Input Text Zip " + controlBorder} disabled={disabled} placeholder="Zip/Postal Code" onChange={(e) => this.setAddress("postcode", e)} value={this.state.address.postCode} />
                        </Row>

                        <Row className="Row">
                            <Input className={"Input Text Country " + controlBorder} disabled={disabled} placeholder="Country" onChange={(e) => this.setAddress("country", e)} value={this.state.address.country} />
                        </Row>
                    </div>

                action3 =
                    <div className="question-button-body">
                        <div className="question-buttons">
                            <div className="question-button">
                                {!this.props.question.mandatory &&
                                    <VButton className={button3Css.Css} type={button3Css.Type} disabled={disabled} icon={<SkipForward />} text="N/A" onClick={() => this.saveAddress(true)} />
                                }
                            </div>
                            <div className="question-button">&nbsp;</div>
                            <div className="question-button">
                                <VButton type={button1Css.Type} className={button1Css.Css} disabled={buttonDisabled} icon={<CheckOutlined />} text={updateButtonText} onClick={() => this.saveAddress(false)} />
                            </div>
                        </div>
                    </div>


                break;
            }
            default: {
                break;
            }

        }
        return (
            <div>
                <div className={"question-header"}>
                    <div className="question-header-body">
                        <div className="question-header-text">
                            <p className="QuestionText">{this.props.question.description}</p>
                        </div>
                        <div className="question-header-icons">
                            {(this.props.question.operatorGuidance || this.props.question.helpURL) &&
                                <>{this.assistPopOver(this.props.question.operatorGuidance, this.props.question.helpURL)}</>
                            }

                            {this.props.question.premium &&
                                <div className="AssistIcon" >
                                    <CertPlus height="30px" width="29px" />
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <div className="question-button-body">
                    <div className="question-buttons">
                        {questionfields}
                    </div>
                </div>

                {/* {action1}  */}
                <div>
                    {action2}
                    {action3}
                </div>
                <div className="validation-error" style={{ display: !this.state.validation.validated ? "block" : "none" }}>
                    {this.state.validation.validationFailMessage}
                </div>

                {this.state.documentUri && this.state.showDocument &&
                    <DocumentViewer
                        visible={this.state.showDocument}
                        title={this.state.documentTitle}
                        documentUri={this.state.documentUri}
                        containerType={EContainerType.AntdModal}
                        mode={EMode.DocViewer}
                        onOK={this.hideDocument}
                        onCancel={this.hideDocument}
                        containerClassName="DocViewer"
                        width="90%"

                    />
                }

            </div>
        );
    }
}
//<PaperClipOutlined />
export default injectIntl(Question2);
