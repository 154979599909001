import React, { Component } from 'react';
import { Modal } from 'antd';
import 'antd/dist/antd.css';

export enum EContainerType {
    AntdModal = 1,
    FluentUIModal = 2,
    None = 3
}

export enum EMode {
    DocViewer = 1,
    FileViewer = 2,
}


interface IProps {
    containerType?: EContainerType
    mode?: EMode
    style?: React.CSSProperties
    onOK?: any
    onCancel?: any
    visible?: boolean | null
    documentUri?: string | null
    title?: string | null
    className?: string | undefined
    containerClassName?: string | undefined
    width?: string | number | undefined
}

interface IState {
    visible: boolean
}

export default class DocumentViewer extends Component<IProps, IState>{

    constructor(props: any) {
        super(props);

        this.state = {
            visible: this.props.visible ?? false
        }

        this.handleOK = this.handleOK.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.visible = this.visible.bind(this);
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.onError = this.onError.bind(this);

    }

    visible(): boolean {
        return this.state.visible;
    }


    onError(e: any) {
        
    }

    open() {
        this.setState({ visible: true });
    }

    close() {
        this.setState({ visible: false });
    }

    handleOK(e: any) {

        if (this.props.onOK) {
            this.props.onOK(e);
        }
        this.close();

    }

    handleCancel(e: any) {

        if (this.props.onCancel) {
            this.props.onCancel(e);
        }
        this.close();

    }

    render() {

        const contents = this.props.documentUri
            ?<>To do</>
            : <>Document uri not supplied</>

        const containerType = this.props.containerType ?? EContainerType.None;

        const container = containerType === EContainerType.None
            ? <div className={this.props.containerClassName}  style={this.props.style} >{contents}</div>
            
            : containerType === EContainerType.AntdModal
                ? <Modal className={this.props.containerClassName} style={this.props.style} title={this.props.title ?? "Document View"} visible={this.visible()} onOk={this.handleOK} onCancel={this.handleCancel} 
                 width={this.props.width}
                >
                    {contents}
                </Modal>
                : <></>

        return (
            <>
                {container}
            </>
        );

    }

}