import React, { Component } from 'react';
import logo from './images/logo/MYSA.png';
import { Layout } from 'antd';
import axios from 'axios';
import { API } from './IVesta';
import { QRCode } from './components/QRCode';

import './App.css';
import 'antd/dist/antd.css';

const { Header } = Layout;

interface IState {
    results: IVerifyResponse | null
    loading: boolean
    invalid: boolean
    premium: boolean
}

interface IProps {
    id: string,
}


interface IVerifyResponse {
    name: string
    clientName: string
    buildingAddress: string | null
    approved: boolean
    premium: boolean
    issuedOn?: Date | null
    expires?: string | null
}

export class Verify extends Component<IProps, IState> {

    constructor(props: any) {
        super(props);

        this.state = {
            results: null,
            loading: true,
            invalid: false,
            premium: false,
        };

        this.refresh = this.refresh.bind(this);


    }


    componentDidMount() {
        this.refresh();
    }


    refresh() {
        var $this = this;
        this.setState({ loading: true, invalid: false });
        axios.post(`${API}/stamp/verify/${this.props.id}`)
            .then(response => {
                var results: IVerifyResponse | null = response.data;
                this.setState({ results: results, loading: false });
            })
            .catch(function (error) {
                console.log(error);
                $this.setState({ loading: false, invalid: true });
            });
    }


    render() {

        var hasText = (this.state.results?.clientName  && this.state.results?.clientName  !== "") || ( this.state.results?.buildingAddress && this.state.results?.buildingAddress !== "");
        var hasClientName = (this.state.results?.clientName && this.state.results?.clientName !== "");

        var text = hasText
            ? hasClientName
                ? <div className="title">
                    Certificate for {this.state.results?.clientName}
                </div>

                : <>
                    <div className="title">
                        Certificate for
                    </div>
                    <div className="address">
                        {this.state.results?.buildingAddress}
                    </div>
                </>
            :
            <></>

        return (
            <Layout className="layout">
                <Header>
                    <div id="header" className="box-shadow">
                        <div className="VerifyHeaderWrapper">
                            <div className="logo" >
                                <img src={logo} className="logo" id="home-img" alt="logo" />
                            </div>
                            <div className="mysa" onClick={() => window.open("https://mysa.global")}>
                                Find out more about MYSA
                            </div>
                        </div>
                    </div>
                </Header>

                <br />
                <br />
                <br />

                <div className="verify-cert">
                    {text}
                    <QRCode
                        id={this.props.id}
                        name={this.state.results?.name}
                        issuedOn={this.state.results?.issuedOn}
                        expiresOn={this.state.results?.expires ?? ""}
                        canUse={false}
                        canclick={false}
                        invalid={this.state.invalid}
                        premium={this.state.results?.premium ?? false}
                    />
                </div>


            </Layout>
        );

    }

}
