import React, { Component } from 'react';
import { NavLink, } from 'react-router-dom';
import axios from 'axios';
import { API, APIKEY, IBuilding, ICertificate, ICertificateSurvey, } from './IVesta';
import { Card, Space, Row, Col, Divider, } from 'antd';
import BuildingCard from './BuildingCard'
import CertificateCard from './CertificateCard'
import CertificateView from './View/CertificateView'

import './App.css';
import 'antd/dist/antd.css';

const { Meta } = Card;

interface IState {
  building: IBuilding,
  certificate: ICertificate,
}

interface IProps {
  user: string,
  certificate: string,
}


class Certificate extends Component<IProps, IState>
{
  constructor(props: any) {
    super(props);

    this.state = {
      certificate: {} as ICertificate,
      building: { address1: "" } as IBuilding,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    const post = {
      security: {
        APIKey: APIKEY,
        user: this.props.user,
      },
      certificateid: this.props.certificate,
    }
    axios.post(API + "/todo/certificate", post)
      .then(response => {
        this.setState({
          building: response.data.building,
          certificate: response.data.certificate,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  render() {
    if (this.state.certificate == null) return <Space></Space>;
    if (this.state.certificate.surveys == null) return <Space></Space>;

    var surveys;


    var onsites = <Row></Row>;
    var answers;


    if (this.state.certificate.issued) {
      answers = <CertificateView user={this.props.user}
        certificate={this.state.certificate}
      />
    }
    else {
      surveys = this.state.certificate.surveys
        .filter(function (survey) {
          return survey.survey.completionMethod !== 1;
        })
        .map(function (element: ICertificateSurvey, i: number) {
          var img = "/images/desk.jpg";
          if (element.survey.completionMethod === 0) img = "/images/certificate.jpg";
          else if (element.survey.completionMethod === 1) img = "/images/onsite.jpg";
          return (
            <Col flex="250px" key={element.id}>
              <NavLink to={"/survey/" + element.id}>
                <Card
                  hoverable
                  style={{ width: 240 }}
                  cover={<img alt="example" src={img} />}
                >
                  <Meta title={element.survey.name} description="" />
                </Card>
              </NavLink>
            </Col>
          )
        });
      const onsitescount = this.state.certificate.surveys
        .filter(function (survey) {
          return survey.survey.completionMethod === 1;
        })
        .length;
      if (onsitescount > 0) {
        onsites = <Row justify="start">
          <Col flex="fill">
            You also have {onsitescount} On Site Surveys to do.
          </Col>
        </Row>
      }
    }

    return (
      <>
        <Row justify="start">
          <BuildingCard building={this.state.building} />
          <CertificateCard certificate={this.state.certificate} />
        </Row>
        <Row justify="start">
          <Col flex={24}>
            <Divider orientation="left">Surveys</Divider>
          </Col>
        </Row>
        <Row gutter={[16, 16]} justify="start">
          {surveys}
        </Row>
        {onsites}
        {answers}
      </>);
  }

}

export default (Certificate);
