import React, { Component } from 'react';
import axios from 'axios';
import { API, APIKEY, IBuilding, ICertificate, ICertificateSurvey, IQuestion } from './IVesta';
import {  Space,  Upload, Row, Col,  Modal,  Typography,  Card,  Button,} from 'antd';
import { QuestionCircleOutlined, FileImageOutlined, VideoCameraOutlined, FilePdfOutlined, } from '@ant-design/icons';

import './App.css';
import 'antd/dist/antd.css';

const { Dragger } = Upload;
const { Text } = Typography;

interface IState {
  building: IBuilding,
  certificate: ICertificate,
  certificatesurvey: ICertificateSurvey,
  uploadMessage: string,
  imageVisible: boolean,
}

interface IProps {
  user: string,
  question: IQuestion,
  certificatesurveyid: string,
  onAnswered: any,
  displayQuestion: any,
  disabled?: boolean,
  onSiteSurvey?: boolean | null | undefined
  completed?: boolean
}


class Evidence2 extends Component<IProps, IState>
{
  constructor(props: any) {
    super(props);

    this.state = {
      certificate: {} as ICertificate,
      building: { address1: "" } as IBuilding,
      certificatesurvey: {} as ICertificateSurvey,
      uploadMessage: "",
      imageVisible: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
  }

  save() {

    if(this.props.disabled) return;
    
    const post = {
      security: {
        APIKey: APIKEY,
        user: this.props.user,
      },
    }
    axios.post(API + "/todo/survey", post)
      .then(response => {
        this.setState({
          building: response.data.building,
          certificate: response.data.certificate,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleChange(info) {

    if (info.file != null) {
      if (info.file.status === "done") {
        if (info.file.response.apiStatus.errorCode === 0) {
          this.setState({ uploadMessage: "" });
          this.props.onAnswered();
          return;
        }
        else {
          this.setState({ uploadMessage: info.file.response.apiStatus.message });
        }
      }
    }

    return;
  }

  renderuploaditem() {
    return <></>
  }

  render() {
    if (this.props.question == null) return <Space></Space>;
    if (this.props.disabled || false) {
      return (
        <Card
          hoverable
          style={{ background: "#c0c0c0", width: 300, height: 350, padding: 0, }}
          title="Evidence"
        >
        </Card>
      );
    }



    var uploadicon;
    var filetypes = [] as string[];
    switch (this.props.question.evidencetype) {
      case 1:
        uploadicon = <FileImageOutlined />
        filetypes.push("image/png");
        filetypes.push("image/jpg");
        filetypes.push("image/jpeg");
        break;
      case 2:
        uploadicon = <VideoCameraOutlined />
        filetypes.push("video/mp4");
        break;
      case 3:
        uploadicon = <FilePdfOutlined />
        filetypes.push("application/pdf");
        break;
    }

    var evidence;
    if (this.props.question.answer?.evidence != null) {
      evidence = this.props.question.answer?.evidence.mimetype;
      switch (this.props.question.answer?.evidence.mimetype) {
        case "image/png":
        case "image/jpg":
        case "image/jpeg":
          evidence = <FileImageOutlined style={{ fontSize: '32px', color: '#08c' }} onClick={e => { this.setState({ imageVisible: true }); }} />
          break;
        case "video/mp4":
          evidence = <VideoCameraOutlined style={{ fontSize: '32px', color: '#08c' }} />
          break;
        case "application/pdf":
          evidence = <a href={this.props.question.answer?.evidence.url} target="_blank" rel="noreferrer" ><FilePdfOutlined style={{ fontSize: '32px', color: '#08c' }} /></a>
          break;
      }
    }

    var help = <></>
    if (this.props.question.evidenceHelpURL !== "" && this.props.question.evidenceHelpURL != null) {
      help = <Button className="VButtonRound" type="primary" shape="circle" icon={<QuestionCircleOutlined />} size="large" onClick={() => { window.open("https://" + this.props.question.evidenceHelpURL); }} />
    }
    return (
      <>
        <Row className="Row" >
          <Col style={{ width: "100%" }}>
            <Dragger action={API + "/survey/venusaddevidence"}
              className="Dragger" style={{ width: "100%" }}
              beforeUpload={
                file => {
                  if (filetypes.includes(file.type)) {
                    this.setState({ uploadMessage: "Uploading..." });
                    return true;
                  }
                  this.setState({ uploadMessage: "Wrong type of file" });
                  return false;
                }
              }
              itemRender={this.renderuploaditem}
              onChange={this.handleChange}
              listType="picture"
              data={{
                APIKey: APIKEY,
                user: this.props.user,
                questionID: this.props.question.id,
                CertificateSurveyID: this.props.certificatesurveyid,
              }}>
              <div className="Evidence">
                <div className="EvidenceUploaded">
                  {help} {evidence}  <Text type="danger">{this.state.uploadMessage}</Text>
                </div>
                <div>

                  {/* <p className="ant-upload-drag-icon">
                    {uploadicon}
                  </p> */}

                  <div className="dragger-body">
                    <div className="icon">{uploadicon}</div>
                    <div className="text">
                      <p className="ant-upload-text">{this.props.question.evidencename}</p>
                      <p className="ant-upload-hint bold">Click or drag file to this area to upload</p>
                      <p className="ant-upload-hint">{this.props.question.evidenceguidance}</p>
                    </div>
                  </div>
                  {/*  */}
                </div>
              </div>
            </Dragger>

          </Col>
        </Row>

        <Modal width={700} title="Image Evidence" visible={this.state.imageVisible} onOk={e => { this.setState({ imageVisible: false }); }}>
          <img src={this.props.question.answer?.evidence?.url || ""} className="EvidenceViewModalImage" alt="evidence" />
        </Modal>

      </>
    );
  }

}

export default (Evidence2);
