import React, { Component } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import HelloSign from 'hellosign-embedded';
import { API, APIKEY } from '../IVesta';
import { VButton } from './VButtons'
import { AlibabaOutlined } from '@ant-design/icons';
import { notification, Spin } from 'antd'
import axios from 'axios';

const client = new HelloSign({
  clientId: '50caab3817efbcd084318edc3659ca77',
  skipDomainVerification: true,

});

//client.open("https://app.hellosign.com/editor/embeddedSign?signature_id=18c811654b28c8d3adc198ba2e8efbab&token=1b0b30c96544703724e12a139c3ce075");

interface IProps extends WrappedComponentProps {
  user: string,
  questionId: string | undefined,
  certificateId: string | undefined,
  answer: any | undefined | null,
  onSave: any
  disabled?: boolean | null | undefined
  className?: string | null | undefined
}


interface IState {
  errorMessage: string | undefined | null
  inProgress: boolean
}

class HelloSignService extends Component<IProps, IState>
{


  constructor(props: any) {
    super(props);

    this.state = {
      errorMessage: null,
      inProgress: false
    }

    this.startSignIn = this.startSignIn.bind(this);
  }

  startSignIn() {

    var $this = this;

    this.setState({ errorMessage: null });

    const post = {
      security: {
        APIKey: APIKEY,
        user: this.props.user,
      },

      email: this.props.user

    }

    var errorMessage = this.props.intl.formatMessage({ id: "err.oops" });
    var url = API + "/hellosign/CreateSurveySignature";


    axios.post(url, post)
      .then(response => {
        if (response.data.status.errorCode === 0) {
          var url = response.data.getSignUrlResponse.signUrl;
          client.open(url)

          client.on('sign', () => {
            this.checkSigned(response.data);
          });

        }
        else {
          this.showNotification(errorMessage);
          // this.setState({ errorMessage: errorMessage });
        }
      })
      .catch(function (error) {
        console.log(error);
        $this.showNotification(errorMessage);
        //$this.setState({ errorMessage:errorMessage });
      });

  }

  checkSigned(response) {

    var $this = this;

    this.setState({ errorMessage: null, inProgress: true });

    const post = {
      security: {
        APIKey: APIKEY,
        user: this.props.user,
      },

      signatureID: response.createEmbeddedSignatureResponse.signatureRequestId,
      certificateID: this.props.certificateId,
      questionID: this.props.questionId

    }

    var errorMessage = this.props.intl.formatMessage({ id: "err.oops" });
    var url = API + "/survey/AnswerCheckSigned";

    axios.post(url, post)
      .then(response => {

        if (response.data.status.errorCode === 0) {
          const signed = response.data.signed || false;

          if (!signed) {
            errorMessage = this.props.intl.formatMessage({ id: "err.hello.notsigned" });
            this.showNotification(errorMessage);
            //this.setState({ errorMessage: errorMessage });
          }
          else {
            this.props.onSave();
          }

          this.setState( {inProgress: false} );

        }
        else {
          this.showNotification(errorMessage);
          this.setState( {inProgress: false} );
          //this.setState({ errorMessage: errorMessage });
        }

      })
      .catch(function (error) {
        console.log(error);
        $this.showNotification(errorMessage);
        $this.setState( {inProgress: false} );
        //$this.setState({ errorMessage: errorMessage });
      });

  }

  showNotification(message: string) {
    notification.open({
      message: 'Sign',
      description: message,
      placement: 'bottomRight'
    });
  }

  render() {

    const classname = this.props.className
      ? this.props.className
      : "VButton"

    const buttonText = this.state.inProgress ? "Signing..." : "Sign"
    const buttonIcon = this.state.inProgress ? <Spin style={{ fontSize: "24px" }}/> : <AlibabaOutlined style={{ fontSize: "28px" }} />
    const disabled = this.state.inProgress || this.props.disabled

    return (

      
       
        <VButton className={classname} disabled={disabled} type="primary" onClick={() => this.startSignIn()} text={buttonText} icon={buttonIcon} />
              
     

      

    );


  }

}

export default injectIntl(HelloSignService)