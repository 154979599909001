import React, { Component } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import logo from './images/logo/MYSA.png';
import { UserOutlined, LockOutlined, CheckOutlined, RedoOutlined } from '@ant-design/icons';
import { VButton } from './components/VButtons'
import { Alert, Input, Form, Checkbox } from 'antd';
import { VerifyUser, RequestPin } from './lib/ContactController';

import './App.css';
import 'antd/dist/antd.css';

interface IState {
    user: string,
    hash: string,
    errorMessage: any,
    showRequestPin: boolean,
    showWaiting: boolean,
    success: boolean,
    email: any,
    clientLevel: boolean
}

var MSG_INVALIDCREDS;
var MSG_OOPS;
var MSG_LOCKED;
var MSG_EXPIRED;
var MSG_INVALIDEMAIL;
var MSG_PINSUCCESS;

interface IProps extends WrappedComponentProps {
    setUser: any,
    setContact: any,
}

class Login extends Component<IProps, IState>
{

    constructor(props: any) {
        super(props);

        this.onFinish.bind(this);
        this.onFinishFailed.bind(this);
        this.showRequestPin.bind(this);
        this.Waiting.bind(this);
        this.onPinRequestClick.bind(this);
        this.onEmailChange.bind(this);

        this.state = {
            user: "micklord49@gmail.com",
            hash: "",
            errorMessage: null,
            showRequestPin: false,
            showWaiting: false,
            success: false,
            email: "",
            clientLevel: false
        };

    }



    onEmailChange(event) {
        this.setState({ ...this.state, email: event.target.value })
    }

    onPinRequestClick() {
        var $this = this;
        var value = this.state.email;
        if (value === null || value === "") return;

        this.Waiting(true);

        RequestPin(value, true,

            function (response: any) {
                if (response.data.status.errorCode === 0) {
                    $this.props.setUser(null);
                    $this.setState({ ...$this.state, success: true, errorMessage: MSG_PINSUCCESS });
                }
                else {
                    $this.setState({ ...$this.state, success: false, errorMessage: MSG_INVALIDEMAIL });
                }
                $this.Waiting(false);
            },

            function (error: any) {
                $this.props.setUser(null);
                $this.setState({ ...$this.state, success: false, errorMessage: MSG_OOPS });
                $this.Waiting(false);
            }
        );

    }

    showRequestPin(value: boolean) {
        this.setState({ ...this.state, errorMessage: null, showRequestPin: value })
    }

    onFinish(values: any) {
        var $this = this;
        var usr = { email: values.username, hash: values.password, keepLoggedIn: values.keepLoggedIn, clientLevel: values.clientLevel };

        this.Waiting(true);

        VerifyUser(usr,

            function (response: any) {
                if (response.data.status.errorCode === 0) {
                    usr.hash = response.data.pinHash;
                    usr.clientLevel = response.data.clientLevel;
                    $this.props.setUser(usr);
                    $this.props.setContact(response.data.contact);
                    $this.setState({ ...$this.state, success: true, errorMessage: null });
                }
                else {
                    if (response.data.expired) {
                        $this.setState({ ...$this.state, success: false, errorMessage: MSG_EXPIRED });
                        $this.props.setUser(null);
                        $this.props.setContact(null);
                    }
                    else if (response.data.status.errorCode === 3005) {
                        $this.setState({ ...$this.state, success: false, errorMessage: MSG_LOCKED });
                    }
                    else {
                        $this.setState({ ...$this.state, success: false, errorMessage: MSG_INVALIDCREDS });
                    }
                }
                $this.Waiting(false);
            },

            function (error: any) {
                $this.props.setUser(null);
                $this.props.setContact(null);
                $this.setState({ ...$this.state, success: false, errorMessage: MSG_OOPS });
                $this.Waiting(false);
            }
        );

    };

    Waiting(show: boolean) {
        this.setState({ ...this.state, showWaiting: show });
    }

    onFinishFailed(errorInfo: any) {
        this.Waiting(false);
    };

    render() {

        MSG_INVALIDCREDS = this.props.intl.formatMessage({ id: 'login.err.invalidcreds' });
        MSG_OOPS = this.props.intl.formatMessage({ id: 'login.err.oops' });
        MSG_LOCKED = this.props.intl.formatMessage({ id: 'login.err.accountlocked' });
        MSG_EXPIRED = this.props.intl.formatMessage({ id: 'login.pin.expired' });
        MSG_INVALIDEMAIL = this.props.intl.formatMessage({ id: 'login.pin.invalidemail' });
        MSG_PINSUCCESS = this.props.intl.formatMessage({ id: 'login.pin.success' });

        return (
            <div className="window-container">
                <div className="logo-container">
                    <img src={logo} className="logo" alt="Logo" />
                </div>

                <div className="LoginContainer">



                    <div className="login-form">

                        <div className="header">
                            <h1>Log In</h1>
                        </div>

                        <Form
                            name="loginForm"
                            initialValues={{ keepLoggedIn: true }}
                            onFinish={(values) => this.onFinish(values)}
                            onFinishFailed={(errorInfo) => this.onFinishFailed(errorInfo)}
                        >

                            <div className="fields">

                                {/* <div className="label" ><label htmlFor="username">Email</label></div> */}
                                <div className="field">
                                    <div className="icon">
                                        <UserOutlined />
                                    </div>
                                    <Form.Item name="username" rules={[{ required: true, message: 'Please enter your email address', },]}>
                                        <Input className="TextArea" allowClear size="large" placeholder="Email Address" onChange={(event) => this.onEmailChange(event)} />
                                    </Form.Item>
                                </div>

                                {/* <div className="label"><label htmlFor="pin">Pin</label></div> */}
                                <div className="field">
                                    <div className="icon">
                                        <LockOutlined />
                                    </div>
                                    <Form.Item name="password" rules={[{ required: true, message: 'Please enter your pin number', },]}>
                                        <Input.Password className="TextArea" allowClear size="large" placeholder="Pin" />
                                    </Form.Item>
                                </div>

                                <Form.Item name="keepLoggedIn" valuePropName="checked">
                                    <Checkbox>Keep me logged in</Checkbox>
                                </Form.Item>

                                {!this.state.success && this.state.errorMessage != null &&
                                    <Form.Item>
                                        <Alert
                                            message="Error"
                                            description={this.state.errorMessage}
                                            type="error"
                                        />
                                    </Form.Item>
                                }

                                {this.state.success && this.state.errorMessage != null &&
                                    <Form.Item>
                                        <Alert
                                            message="Success"
                                            description={this.state.errorMessage}
                                            type="info"
                                        />
                                    </Form.Item>
                                }


                            </div>

                            <div className="button-container">


                                <Form.Item>

                                    <div className="button-container">
                                        <div className="buttons">
                                            <div className="button"><VButton className="VButtonLogin" type="primary" htmlType="submit" text="Login" icon={<CheckOutlined />} /></div>
                                            <div className="button"><VButton className="VButtonLogin" type="primary" onClick={() => this.onPinRequestClick()} text="Request New Pin" icon={<RedoOutlined />} /></div>
                                        </div>
                                    </div>

                                </Form.Item>

                            </div>

                        </Form>

                    </div>


                </div>
            </div>
            // <Layout style={{ minHeight: '100vh', backgroundColor: '#dfdfdf' }}>
            //     <Header>MYSASURE Venus</Header>
            //     <Content>
            //     {this.state.showRequestPin &&
            //          <RequestNewPin setUser={this.props.setUser} onClose={() => this.showRequestPin(false)} />
            //     }     
            //     {!this.state.showRequestPin &&      
            //         <Row align="middle" style={{ minHeight: '100vh' }}>
            //             <Col span={9}>

            //             </Col>
            //             <Col span={9}>
            //            {/* <Search
            //                 placeholder="input email address"
            //                 allowClear
            //                 enterButton="Login"
            //                 size="large"
            //                 onSearch={this.props.setUser}
            //             />*/}

            //             <Form
            //                 {...layout}
            //                 name="loginForm"
            //                 initialValues={{keepLoggedIn: true}}
            //                 onFinish={(values) => this.onFinish(values)}
            //                 onFinishFailed={(errorInfo) =>this.onFinishFailed(errorInfo)}
            //             >
            //                 <h1>Log in</h1>

            //                 {this.state.showWaiting && 
            //                 <Form.Item>
            //                     <Spinner text="Please wait..." />
            //                 </Form.Item>
            //                 } 
            //                 {!this.state.showWaiting && 
            //                 <>
            //                 <Form.Item

            //                     name="username"
            //                     rules={[
            //                     {
            //                         required: true,
            //                         message: 'Please enter your email address',
            //                     },
            //                     ]}
            //                 >
            //                     <Input allowClear size="large" placeholder="Email Address" onChange={(event) => this.onEmailChange(event)}/>
            //                 </Form.Item>

            //                 <Form.Item

            //                     name="password"
            //                     rules={[
            //                     {
            //                         required: true,
            //                         message: 'Please enter your pin number',
            //                     },
            //                     ]}
            //                 >
            //                     <Input.Password allowClear size="large" placeholder="Pin" />

            //                 </Form.Item>

            //                 <Form.Item  name="keepLoggedIn" valuePropName="checked">
            //                      <Checkbox>Keep me logged in</Checkbox>
            //                 </Form.Item>

            //                 <Form.Item>

            //                     <div className="button-container">
            //                     <div className="button-item"><Button type="primary" htmlType="submit" >Login</Button></div>
            //                     <div className="button-item"><Button type="primary" onClick={ () => this.onPinRequestClick()}  >Request New Pin</Button></div>
            //                     </div>    

            //                 </Form.Item>  

            //                 {!this.state.success  && this.state.errorMessage != null &&
            //                  <Form.Item>
            //                     <Alert
            //                     message="Error"
            //                     description={this.state.errorMessage}
            //                     type="error"
            //                     />        
            //                 </Form.Item>
            //                 }

            //                 {this.state.success && this.state.errorMessage != null &&
            //                  <Form.Item>
            //                     <Alert
            //                     message="Success"
            //                     description={this.state.errorMessage}
            //                     type="info"
            //                     />        
            //                 </Form.Item>
            //                 }

            //                 </>
            //                 }                          


            //             </Form>



            //             </Col>
            //             <Col span={9}>

            //             </Col>
            //         </Row>
            //     }
            //     </Content>
            //     <Footer>Footer</Footer>
            // </Layout>

        );
    }

}

export default injectIntl(Login);
