import React, { Component } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { VButton } from './components/VButtons'
import { Avatar, Form, Table, Space, Row, Input, Drawer, } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';

import './App.css';
import 'antd/dist/antd.css';



interface col {
    title: string,
    dataIndex?: string,
    key: string,
    fixed?: string,
    width?: number,
    render?: any,
}

interface IState {
    items: string[][],
    visible: boolean,
    selectedIndex?: number,
}

interface IProps extends WrappedComponentProps {
    onChange: (value: string) => void,
    items: string,
    options: string,
    form?: any,
    disabled?: boolean
}

class UserDefinedList extends Component<IProps, IState>
{
    formRef = React.createRef<FormInstance>();

    constructor(props: IProps) {
        super(props);

        var items: string[][] = [];
        if (this.props.items === "") {

        }
        else {
            var lines = this.props.items.split("\n");
            items = lines.map((l, i) => {
                var fields = l.split(";");
                return fields;
            });

        }

        this.state = {
            items: items,
            visible: false,
            selectedIndex: undefined,
        };

        this.saveText = this.saveText.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onOpen = this.onOpen.bind(this);
        this.onFinish = this.onFinish.bind(this);
        this.onFinishFail = this.onFinishFail.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onAdd = this.onAdd.bind(this);
    }

    onSave() {
        var res = "";
        const lines = this.state.items.map(c => {
            return c.join(";");
        });
        res = lines.join("\n");
        this.props.onChange(res);
        this.setState({ visible: false, })
    }

    onSelect(i: any, index: number) {
        this.setState({ selectedIndex: index })
        var vals = {};
        vals["Field0"] = (index || 0).toString();
        this.state.items[index].forEach((o, i) => {
            vals["Field" + (i + 1)] = o;
        });
        this.formRef.current!.setFieldsValue(vals);

    }

    onDelete(i: any) {
        var a = this.state.items;
        var del = i;
        a.splice(del, 1);
        this.setState({ items: [...a] });
    }

    onClose() {
        this.setState({ visible: false, })
    }

    onOpen() {
        this.setState({ visible: true, })
    }

    componentDidMount() {
    }

    onAdd() {
        this.setState({ selectedIndex: undefined, });
        this.formRef.current!.resetFields();
    }

    saveText(e: any) {
       
    }

    onFinish(values: any) {
        var items: string[][] = JSON.parse(JSON.stringify(this.state.items));
        var vals: string[] = Object.values(values);

        if (values["Field0"] === undefined) {
            vals.shift();
            items.push(vals);
        }
        else {
            vals.shift();
            items[parseInt(values["Field0"] || "0")] = vals;
        }

        this.setState({
            selectedIndex: undefined,
            items: items
        });

        this.formRef.current!.resetFields();

    }

    onFinishFail() {

    }

    render() {

        var avatars = <Avatar >{this.state.items.length}</Avatar>;

        var columns: col[] = [
            {
                title: '#',
                dataIndex: '0',
                key: 'index',
                render: (text, record, index) => {
                    return <>{index}</>
                }
            },
        ];
        var fields: JSX.Element[] = [
            <Form.Item key={0}
                label="Record"
                name="Field0"
                rules={[{}]}
            >
                <Input disabled className="Input" />
            </Form.Item>
        ];

        var opts = this.props.options.split(";");
        opts.forEach((o, i) => {
            columns.push({
                title: o,
                dataIndex: (i + 1).toString(),
                key: o + 'Key',
                render: (text, record, index) => {
                    return <>{record[i]}</>
                }
            });

            fields.push(
                <Form.Item key={i + 1}
                    label={o}
                    name={"Field" + (i + 1)}
                    rules={[{}]}
                >
                    <Input disabled={this.props.disabled} placeholder="" className="Input" />
                </Form.Item>
            );

        })

        columns.push({
            title: 'Action',
            key: 'operation',
            fixed: 'right',
            width: 100,
            render: (d, i) => <a onClick={() => this.onDelete(d)}>Delete</a>,
        });

        var buttons;

        if (this.state.selectedIndex === undefined) {
            // Save
            buttons =
                <Form.Item key="Save">
                    <VButton disabled={this.props.disabled} className="VButtonIcon" size="middle" type="primary" htmlType="submit" icon={<PlusOutlined />} />
                </Form.Item>
        }
        else {
            //Save, Add
            buttons = <>
                <Form.Item key="buttons">
                    <Space>
                        <VButton disabled={this.props.disabled} className="VButtonIcon" type="primary" size="middle" htmlType="submit" icon={<CheckOutlined />} />
                        <VButton disabled={this.props.disabled} className="VButtonIcon" onClick={this.onAdd} type="primary" htmlType="submit" icon={<PlusOutlined />} />
                    </Space>
                </Form.Item>
            </>

        }

        var avatarRow;
        if (this.state.items.length === 0) {
            avatarRow =
                <div className="NoItems">
                    {this.props.intl.formatMessage({ id: 'udflist.nobuildings' })}
                </div>
        }
        else {
            avatarRow = <Avatar.Group maxCount={3} >
                {avatars}
            </Avatar.Group>
        }

        return (
            <div className="UserDefinedList"  >
                <Row onClick={this.onOpen} >
                    {avatarRow}
                </Row>
                <Drawer
                    title="List"
                    width={720}
                    className="Drawer"
                    onClose={this.onClose}
                    visible={this.state.visible}
                    footer={
                        <div style={{ textAlign: 'right', }}>

                            <VButton className="VButtonIcon" onClick={this.onClose} type="primary" size="middle" htmlType="submit" icon={<CloseOutlined />} />
                            <VButton disabled={this.props.disabled} className="VButtonIcon" onClick={this.onSave} type="primary" size="middle" htmlType="submit" icon={<CheckOutlined />} />

                            {/* 

                            <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                                Cancel
                            </Button>
                            <Button onClick={this.onSave} type="primary">
                                Save
                            </Button> */}

                        </div>
                    }
                >
                    <Form
                        name="basic"
                        initialValues={{ remember: true }}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFail}
                        layout="vertical"
                        ref={this.formRef}
                    >
                        {fields}
                        {buttons}
                    </Form>
                    <Table onRow={(r, i: number | undefined) => ({
                        onClick: () => this.onSelect(r, i || 0)
                    })}
                        dataSource={this.state.items}
                        columns={columns as any}
                    />;

                </Drawer>
            </div>
        );
    }

}
//<PaperClipOutlined />
export default injectIntl(UserDefinedList);
