import React, { useState } from 'react';
import { NavLink, } from 'react-router-dom';
import { Menu, Modal, Button } from 'antd';
import { UserOutlined, UpOutlined, LeftOutlined } from '@ant-design/icons';
import { ColorAvatar } from './ColorAvatar'
import logo from '../images/logo/MYSA.png';
import { CertificateIcon, Mail } from './VenusIcons';
import Avatar from 'react-avatar-edit'
import blueTheme from '../themes/blue.json';
import yellowTheme from '../themes/yellow.json';
import tealTheme from '../themes/teal.json';
import MysaTheme from '../themes/MYSA.json';
import { IContact } from '../IVesta';

import '../App.css';
import 'antd/dist/antd.css';

const { SubMenu } = Menu;

interface IProps {
    setUser: any,
    setUserType: any
    userType: any
    certMenu: any
    setTheme: any
    contact: IContact | null
    user: any
}

function KeyName(key: any) {
    return key + ":" + window.location.hostname + ":Venus"
}


function useStickyState(defaultValue: any, key: any) {

    const [value, setValue] = React.useState(() => {
        var stickyValue = window.localStorage.getItem(KeyName(key));
        var objValue: any = null;

        if (stickyValue != null) {
            objValue = JSON.parse(stickyValue);
        }

        return objValue !== null
            ? objValue
            : defaultValue;

    });
    React.useEffect(() => {

        if (value)
            window.localStorage.setItem(KeyName(key), JSON.stringify(value));

    }, [key, value]);
    return [value, setValue];
}

export const MainMenu = (props: IProps) => {

    const [userExpanded, setUserExpanded] = useState(false);
    const [uploadVisible, setUploadVisible] = useState(false);
    const [preview, setPreview] = useState("");
    const [avatar, setAvatar] = useStickyState(null, 'avatar:' + props.user.email);

    var expandedIcon = userExpanded
        ? <UpOutlined className="SubMenuArrow" />
        : <LeftOutlined className="SubMenuArrow" />

    var loginItem = <span><UserOutlined className="MenuItemIconUser" />{expandedIcon}</span>

    var contactItem = props.contact
        ? <>
            <div className="contact-info">
                <div className="avatar">

                    <ColorAvatar text={props.contact?.name ?? "?"} src={avatar} size={45} />
                </div>
                <div className="contact-details">
                    <div className="name">
                        {props.contact.name}
                    </div>
                    <div>
                        {props.contact.email}
                    </div>
                </div>
                <div>
                    {expandedIcon}
                </div>
            </div>
            <div className="contact-info-response">
                {loginItem}
            </div>
        </>
        : <>{loginItem}</>

    function menuClick(e) {
        setUserExpanded(false);
    }

    function onCrop(preview) {
        setPreview(preview)
    }

    function onClose() {
        setUploadVisible(false)
        setPreview("");
    }

    function onOK() {
        setAvatar(preview);
        onClose();
    }

    return (

        <div id="header" className="box-shadow">

            <div className="MainMenuWrapper">

                <div className="logo" >
                    <img src={logo} className="logo" id="home-img" alt="logo" />
                </div>

                <Menu className="MainMenu group" mode="horizontal" selectable={true} triggerSubMenuAction="click" onClick={menuClick}  >
                    <Menu.Item key="2" icon={<><span><Mail className="MenuItemIcon" /></span></>}><NavLink to="/Messages"> Messages</NavLink></Menu.Item>
                    <Menu.Item key="7" icon={<><span><CertificateIcon className="MenuItemIcon" /></span></>}><NavLink to="/Certificates2">Certificates</NavLink></Menu.Item>
                    {props.certMenu}

                    <SubMenu className="SubMenu"
                        icon={contactItem}
                        onTitleClick={() => setUserExpanded(!userExpanded)}
                        key={4}
                    >
                        <Menu.ItemGroup key="g1" title="Themes" >
                            <Menu.Item key="t1" onClick={() => props.setTheme(blueTheme)}>Blue</Menu.Item>
                            <Menu.Item key="t2" onClick={() => props.setTheme(tealTheme)}>Teal</Menu.Item>
                            <Menu.Item key="t3" onClick={() => props.setTheme(yellowTheme)}>Yellow</Menu.Item>
                            <Menu.Item key="t4" onClick={() => props.setTheme(MysaTheme)}>MYSA Brand</Menu.Item>
                        </Menu.ItemGroup>

                        <Menu.ItemGroup key="g2" title="User" >
                            <Menu.Item key="l1"><div onClick={() => { setUploadVisible(true) }} >Upload Photo</div></Menu.Item>
                            <Menu.Item key="l2"><div onClick={() => { props.setUser(null); }} >Logout</div></Menu.Item>
                        </Menu.ItemGroup>
                    </SubMenu>

                </Menu>

            </div>

            <Modal
                title="Upload your photo"
                visible={uploadVisible}

                // onOk={handleOk}
                // confirmLoading={confirmLoading}
                onCancel={onClose}
                onOk={onOK}
                footer={[
                    <>
                        <Button onClick={onClose} >
                            Cancel
                        </Button>

                        <Button key="submit" type="primary" onClick={onOK} disabled={preview === ""}>
                            Upload
                        </Button>
                    </>
                ]}

            >
                <Avatar
                    width={390}
                    height={295}
                    onCrop={onCrop}

                />
            </Modal>

        </div>
    );
}