import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  RouteComponentProps,
  NavLink,
} from "react-router-dom";
import { Layout, Menu } from "antd";
import { INav, ITheme, IThemeColorVar } from "./IVesta";
import ToDo from "./ToDo";
import Building from "./Building";
import Certificate from "./Certificate";
import Survey from "./Survey";
import Messages from "./Messages";
import Buildings from "./Buildings";
import Certificates2 from "./Certificates2";
import Certificates from "./Certificates";
import Login from "./Login";
import { VerifyUser } from "./lib/ContactController";
import { MainMenu } from "./components/MainMenu";
import { CertificateIcon } from "./components/VenusIcons";
import { Verify } from "./Verify";

import "./App.css";
import "antd/dist/antd.css";

const { Header, Content } = Layout;

var doCheckUser = true;
var doSetUser: any;
var doSetContact: any;
var routeToVerifyCertificate: boolean = false;

type TParams = { id: string };

function KeyName(key: any) {
  return key + ":" + window.location.hostname + ":Venus";
}

function useStickyState(defaultValue: any, key: any) {
  const [value, setValue] = React.useState(() => {
    if (routeToVerifyCertificate) return null;

    var stickyValue = window.localStorage.getItem(KeyName(key));
    var objValue: any = null;

    if (stickyValue != null) {
      objValue = JSON.parse(stickyValue);
    }

    return objValue !== null ? objValue : defaultValue;
  });
  React.useEffect(() => {
    if (!routeToVerifyCertificate)
      window.localStorage.setItem(KeyName(key), JSON.stringify(value));
    //setState(state => ({...state}));
  }, [key, value]);
  return [value, setValue];
}

function useTheme(theme: ITheme | null) {
  const [value, setValue] = React.useState(() => {
    if (routeToVerifyCertificate) return null;

    var stickyValue = window.localStorage.getItem(KeyName("theme"));
    var objValue: any = null;

    if (stickyValue != null) {
      objValue = JSON.parse(stickyValue);
    }

    return objValue !== null ? objValue : theme;
  });
  React.useEffect(() => {
    if (!routeToVerifyCertificate)
      window.localStorage.setItem(KeyName("theme"), JSON.stringify(value));
    if (value) {
      value.colors.forEach((color: IThemeColorVar) => {
        document.body.style.setProperty(color.varName, color.value);
      });
    }
  }, ["theme", value]);
  return [value, setValue];
}

function CheckUser(user: any, setUser: any) {
  if (user == null) return;
  if (user.email === null || user.email === "") return;

  VerifyUser(
    user,

    function (response: any) {
      if (response.data.status.errorCode === 0) {
        user.status = 0;

        doSetContact(response.data.contact);
        doSetUser(user);
      } else {
        doSetUser(null);
        doSetContact(null);
      }
    },

    function (error: any) {
      doSetUser(null);
      doSetContact(null);
    }
  );
}

function RouteToVerifyCertificate() {
  const loc = window.location;
  return loc.href.toLowerCase().indexOf("/verify/") > 0;
}

const defaultNav: INav = {
  certificate: {
    buildingId: null,
    certificateId: null,
  },
};

function App() {
  //const [user, setUser] = React.useState('')

  routeToVerifyCertificate = RouteToVerifyCertificate();

  const [user, setUser] = useStickyState(null, "user");
  const [userType, setUserType] = useStickyState(true, "userstate");
  const [nav, setNav] = useState(defaultNav);
  const [contact, setContact] = useState(null);
  // const [clientLogin, getClientLogin] = useState(false);
  const [theme, setTheme] = useTheme(null);
  var certMenu;

  if (!routeToVerifyCertificate) {
    doSetUser = setUser;
    doSetContact = setContact;

    if (user != null && doCheckUser) {
      CheckUser(user, setUser);
      doCheckUser = false;
    }

    if (user == null) {
      return <Login setUser={setUser} setContact={setContact} />;
    }

    if (user.clientLevel) {
      certMenu = (
        <Menu.Item
          key="8"
          icon={
            <>
              <span className="MenuItemIconCert">
                <CertificateIcon height="24px" />
              </span>
            </>
          }
        >
          <NavLink to="/BrandSurveys">Brand Survey</NavLink>
        </Menu.Item>
      );
    }
  }

  return (
    <Router>
      <div className="App">
        <Layout className="layout">
          {!routeToVerifyCertificate && (
            <Header>
              <MainMenu
                setTheme={setTheme}
                contact={contact}
                user={user}
                setUser={setUser}
                setUserType={setUserType}
                userType={userType}
                certMenu={certMenu}
              />
            </Header>
          )}
          <Content style={{ padding: "0 50px" }}>
            <div className="site-layout-content">
              {!routeToVerifyCertificate && (
                <>
                  <Route
                    exact
                    path="/ToDo"
                    render={(props) => <ToDo {...props} user={user.email} />}
                  />
                  <Route
                    exact
                    path="/Certificates"
                    render={(props) => (
                      <Certificates {...props} user={user.email} />
                    )}
                  />
                  <Route
                    exact
                    path="/Messages"
                    render={(props) => (
                      <Messages
                        {...props}
                        user={user.email}
                        forManager={!user.clientLevel}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/Buildings"
                    render={(props) => (
                      <Buildings
                        {...props}
                        user={user.email}
                        forManager={userType}
                      />
                    )}
                  />

                  <Route
                    exact
                    path="/Certificates2"
                    render={(props) => (
                      <Certificates2
                        {...props}
                        user={user.email}
                        forManager={!user.clientLevel}
                        nav={nav}
                        setNav={setNav}
                      />
                    )}
                  />

                  <Route
                    exact
                    path="/BrandSurveys"
                    render={(props) => (
                      <Certificates2
                        {...props}
                        user={user.email}
                        forBrand={user.clientLevel}
                        nav={nav}
                        setNav={setNav}
                      />
                    )}
                  />

                  <Route
                    path="/building/:id"
                    component={({ match }: RouteComponentProps<TParams>) => {
                      return (
                        <Building
                          user={user.email}
                          building={match.params.id}
                        />
                      );
                    }}
                  />
                  <Route
                    path="/certificate/:id"
                    component={({ match }: RouteComponentProps<TParams>) => {
                      return (
                        <Certificate
                          user={user.email}
                          certificate={match.params.id}
                        />
                      );
                    }}
                  />
                  <Route
                    path="/survey/:id"
                    component={({ match }: RouteComponentProps<TParams>) => {
                      return (
                        <Survey user={user.email} survey={match.params.id} />
                      );
                    }}
                  />
                </>
              )}

              {routeToVerifyCertificate && (
                <>
                  <Route
                    path="/Verify/:id"
                    component={({ match }: RouteComponentProps<TParams>) => {
                      return <Verify id={match.params.id} />;
                    }}
                  />
                </>
              )}
            </div>
          </Content>
        </Layout>
        ,
      </div>
    </Router>
  );
}

export default App;
