import axios from 'axios';

import { API, APIKEY, } from '../IVesta';

export function VerifyUser(userInfo, onSuccess, onFail) {
   
    const data = {
        security: {
          APIKey: APIKEY,
          user: userInfo.email,
        },

        PinHash: userInfo.hash,
        KeepLoggedIn: userInfo.keepLoggedIn
      }

      Call("VerifyPin", data, 
        function(response)
        {
            if(onSuccess) onSuccess(response, userInfo);
        },
        function(error){
            if(onFail) onFail(error);
        }
      );
}

export function RequestPin(email, NotifyPinDetails, onSuccess, onFail) {
  const data = {
    security: {
      APIKey: APIKEY,
      user: email,
    },

    NotifyPinDetails: NotifyPinDetails
  }

  Call("RequestPin", data, 
        function(response)
        {
            if(onSuccess) onSuccess(response);
        },
        function(error){
            if(onFail) onFail(error);
        }
      );  
}

function Call(method, data, onSuccess, onFail){
    axios.post(`${API}/contact/${method}`, data)
    .then(response => {
        if(onSuccess) onSuccess(response);
    })
    .catch(function (error) {
       if(onFail) onFail(error);
      });

}

