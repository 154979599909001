import React, { PureComponent } from 'react'
import debounce from 'lodash.debounce'
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';

export default class ScrollableContainer extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      items: [...Array(0).keys()],
      hasOverflow: false,
      canScrollLeft: false,
      canScrollRight: false
    }

    this.handleClickAddItem = this.handleClickAddItem.bind(this);
    this.handleClickRemoveItem = this.handleClickRemoveItem.bind(this);
    this.checkForOverflow = this.checkForOverflow.bind(this);
    this.checkForScrollPosition = this.checkForScrollPosition.bind(this);
    this.debounceCheckForOverflow = debounce(this.checkForOverflow, 1000);
    this.debounceCheckForScrollPosition = debounce(this.checkForScrollPosition, 200);
    this.buildItems = this.buildItems.bind(this);
    this.initItems = this.initItems.bind(this);
    this.windowResize = this.windowResize.bind(this);
    this.container = null
    this.items = null;

  }

  componentDidMount() {
   // this.checkForOverflow()
   // this.checkForScrollPosition()

    this.container.addEventListener(
      'scroll',
      this.debounceCheckForScrollPosition
    )

    window.addEventListener('resize', this.windowResize);

    this.initItems();
  }

  componentWillUnmount() {
    this.container.removeEventListener(
      'scroll',
      this.debounceCheckForScrollPosition
    )

    window.removeEventListener('resize', this.windowResize);

    this.debounceCheckForOverflow.cancel()
  }

  componentDidUpdate(prevProps, prevState) {
    // if (prevState.items.length !== this.state.items.length) {
    //   this.checkForOverflow()
    //   this.checkForScrollPosition()
    // }
    this.windowResize();
  }

  windowResize() {
    try {
      this.debounceCheckForScrollPosition();
      this.checkForScrollPosition();
      this.checkForOverflow();
    }
    catch (ex) {

    }
  }

  checkForScrollPosition() {
    try {
      if (this.container) {
        const { scrollLeft, scrollWidth, clientWidth } = this.container
        this.setState({
          canScrollLeft: scrollLeft > 0,
          canScrollRight: scrollLeft !== scrollWidth - clientWidth
        })
      }
    } catch (ex) { }
  }

  checkForOverflow() {
    try {
      if (this.container) {
        const { scrollWidth, clientWidth } = this.container
        const hasOverflow = scrollWidth > clientWidth
        this.setState({ hasOverflow })
      }
    }
    catch (ex) { }
  }

  handleClickAddItem() {
    this.setState({ items: [...this.state.items, this.state.items.length] })
  }

  handleClickRemoveItem() {
    this.setState({ items: this.state.items.slice(0, -1) });
  }

  scrollContainerBy(distance) {
    this.container.scrollBy({ left: distance, behavior: 'smooth' })
    this.checkForOverflow()
  }

  initItems() {
    const { children } = this.props
    const tmp = [...Array(0).keys()];

    React.Children.map(children, (child) => {

      tmp.push(child);

      this.setState({ items: tmp });

    });
  }

  buildItems() {
    const { children } = this.props
    var key = 0;

    return React.Children.map(children, (child) => {
     // this.windowResize();
      return (
        <li key={key++}>
          {child}
        </li>
      )

    });

  }

  buildControls() {
    const { canScrollLeft, canScrollRight } = this.state
    return (
      <div className="item-controls">
        <button
          type="button"
          disabled={!canScrollLeft}
          onClick={() => {
            this.scrollContainerBy(-200)
          }}
        >
          Previous
        </button>

        <button type="button" onClick={this.handleClickAddItem}>
          Add Item
        </button>

        <button type="button" onClick={this.handleClickRemoveItem}>
          Remove Item
        </button>

        <button
          type="button"
          disabled={!canScrollRight}
          onClick={() => {
            this.scrollContainerBy(200)
          }}
        >
          Next
        </button>
      </div>
    )
  }

  render() {
    const { canScrollLeft, canScrollRight } = this.state
    const scrollBy = this.props.scrollBy
      ? this.props.scrollBy
      : 200;

    const navTop = this.props.navTop
      ? this.props.navTop
      : -70;

    return (
      <>
        <ul

          className="mysa-scroller-item-container"
          ref={node => {
            this.container = node
          }}
        >

          {this.buildItems()}

        </ul>

        <div className="mysa-scroller-nav group" style={{ top: navTop }}>

          {canScrollLeft &&
            <span className="mysa-scroller-nav-left"><LeftCircleOutlined className="icon-normal" onClick={() => {
              this.scrollContainerBy(-(scrollBy))
            }} /></span>
          }

          {canScrollRight &&
            <span className="mysa-scroller-nav-right"><RightCircleOutlined className="icon-normal" onClick={() => {
              this.scrollContainerBy(scrollBy)
            }} /></span>
          }
        </div>

      </>
    )
  }
}
