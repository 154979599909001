import React, { Component } from 'react';
import { NavLink, } from 'react-router-dom';
import axios from 'axios';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { API, APIKEY, ICertificate, } from './IVesta';
import { Row, Col, Card, } from 'antd';

import './App.css';
import 'antd/dist/antd.css';

const { Meta } = Card;

interface IState {
  certificates: ICertificate[],
}

interface IProps extends WrappedComponentProps {
  user: string,
}


class Certificates extends Component<IProps, IState>
{
  constructor(props: any) {
    super(props);

    this.state = {
      certificates: [] as ICertificate[],
    };
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    const post = {
      security: {
        APIKey: APIKEY,
        user: this.props.user,
      },
      clientEmail: this.props.user,
    }
    var url = API + "/certificate/search";

    axios.post(url, post)
      .then(response => {
        this.setState({
          certificates: response.data.certificates,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  render() {

    const certificates =
      this.state.certificates.map(function (element: ICertificate, i: number) {
        return (
          <Col flex="250px" key={element.id}>
            <NavLink to={"/certificate/" + element.id}>
              <Card
                hoverable
                style={{ width: 240 }}
                cover={<img alt="example" src="/images/certificate.jpg" />}
              >
                <Meta title={element.name} description="" />
              </Card>
            </NavLink>
          </Col>
        )
      });


    return (
      <Row>
        <Col span={24}>
          <h1>{this.props.intl.formatMessage({ id: 'certificates.certificates' })}</h1>
          {certificates}
        </Col>
      </Row>

    );
  }

}

export default injectIntl(Certificates);
