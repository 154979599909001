import { Component } from 'react'
import { Dropdown, Menu, Button } from 'antd'
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

import '../App.css';
import 'antd/dist/antd.css';

interface IState {
    selectedItem: IVDropdownItem | undefined | null
    listShowing: boolean

}

interface IProps {
    items: IVDropdownItem[],
    onItemSelect?: any
    selectedItem?: IVDropdownItem | undefined | null
    title?: string
    spin?: boolean | undefined | null
}

export interface IVDropdownItem {
    key?: string,
    text: any,
    index: number
}


export default class VDropdownList extends Component<IProps, IState> {

    constructor(props: any) {
        super(props);

        this.state = {
            selectedItem: this.props.selectedItem,
            listShowing: false
        };

        this.itemClick = this.itemClick.bind(this);
        this.visibleChange = this.visibleChange.bind(this);

    }

    hasValue(value?: any | null | undefined, match?: any | null | undefined): boolean {
        if (!(value === null || value === undefined)) {

            if (!(match === null || match === undefined))
                return value === match;

            return true;
        }

        return false;
    }


    itemClick(e: any) {

        var item = this.findItem(e.key);
        this.setState({ selectedItem: item })

        if (this.props.onItemSelect) {
            this.props.onItemSelect(e, item);
        }

    }


    visibleChange(e: any) {
        this.setState({ listShowing: e });
    }

    findItem(key: string): IVDropdownItem | null | undefined {

        const ret = this.props.items.find(x => x.key === key);

        // for (var i = 0; i < this.props.items.length; i++) {
        //     if (key === this.props.items[i].key)
        //         return this.props.items[i];
        // }

        return ret;
    }


    render() {

        const list = (
            <Menu className="VMenuItems" onClick={this.itemClick}>
                {this.props.items.map(function (element: IVDropdownItem, i: number) {
                    return (
                        <Menu.Item key={element.key ? element.key : i.toString()} className="item" >
                            {element.text}
                        </Menu.Item>
                    )
                })}
            </Menu>
        );

        const icon = this.hasValue(this.props.spin, true)
            ? <Spin />
            : this.state.listShowing
                ? <UpOutlined />
                : <DownOutlined />


        return (
            <div className="VDropdown">
                <Dropdown overlay={list} onVisibleChange={this.visibleChange} >
                    <Button className="drop-button" >
                        <div className="selector group">

                            {this.props.title ?? this.props.selectedItem?.text}

                            <div className="selector-icon" >

                                {icon}

                            </div>
                        </div>
                    </Button>

                </Dropdown>

            </div>
        );

    }
}




