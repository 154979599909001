import { IAddress, IValidation } from '../IVesta';

export function ValidateAddress(address: IAddress): IValidation {
    var ret: IValidation = { validate: true, validated: true, validationFailMessage: "", validationMin: 0, validationMax: 0, validationExtra: "" }

    if (address.address1.trim() === "") {
        ret.validated = false;
        ret.validationFailMessage = "First line of address is required";
        return ret;
    }

    if (address.city.trim() === "") {
        ret.validated = false;
        ret.validationFailMessage = "city is required";
        return ret;
    }

    if(address.state.trim() !== "" && address.state.length < 2) {
        ret.validated = false;
        ret.validationFailMessage = "please enter a valid state or state code";
        return ret;
    }

    if (address.city.length < 2) {
        ret.validated = false;
        ret.validationFailMessage = "please enter a valid city or city code";
        return ret;
    }

    if (address.postCode.trim() === "") {
        ret.validated = false;
        ret.validationFailMessage = "Zip/Postal code is required";
        return ret;
    }

    if (address.postCode.length < 2) {
        ret.validated = false;
        ret.validationFailMessage = "please enter a valid Zip/Postal code";
        return ret;
    }


    if (address.country.trim() === "") {
        ret.validated = false;
        ret.validationFailMessage = "Country is required";
        return ret;
    }

    if (address.country.length < 2) {
        ret.validated = false;
        ret.validationFailMessage = " please enter a valid country or country code";
        return ret;
    }



    return ret;
}

export function ValidateText(value: string, mandatory: boolean, validation: IValidation): boolean {

    if (!validation.validate && mandatory) return !(value.trim() === "");

    if (validation.validationMin === 0 && mandatory) return !(value.trim() === "");

    if (value.length < validation.validationMin) return false;

    if (value.length > validation.validationMax && validation.validationMax > 1) return false;

    return true;
}

export function ValidateInteger(value: any, mandatory: boolean, validation: IValidation): boolean {

    if(!isNumeric(value)) return false;

    var intVal = parseInt(value);

    if(!validation.validate && mandatory) return intVal > 0;

    if(intVal < validation.validationMin) return false;

    if(intVal > validation.validationMax && validation.validationMax > 1) return false

    return true;
}

export function ValidateCurrency(curr: string, value: any, mandatory: boolean, validation: IValidation): boolean {
    return ValidateInteger(value, mandatory, validation);
}




export function isNumeric(value) {
    return /^-?\d+$/.test(value);
}