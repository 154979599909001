import React, { Component } from 'react';

import { ReactComponent as IcoCircle } from '../images/Circle.svg';
import IcoCircleWhite from '../images/CircleWhite.svg';
import IcoCircleRed from '../images/CircleRed.svg';
import { ReactComponent as IcoApproved } from '../images/Approved.svg';
import IcoApprovedWhite from '../images/ApprovedWhite.svg';
import { ReactComponent as IcoMail } from '../images/Mail.svg';
import IcoCertPlus from '../images/CertPlus.svg';
import { ReactComponent as IcoCert } from '../images/Certificate.svg';
import { ReactComponent as IcoEnter } from '../images/enter3.svg';
import { ReactComponent as  IcoSkipForward }  from '../images/next.svg';
import { ReactComponent as IcoCopy } from '../images/copy.svg';
import { ReactComponent as IcoMonitor } from '../images/desktop.svg';
import { ReactComponent as IcoTrash } from '../images/bin.svg';


import '../App.css'

interface IProps {
  width?: string,
  height?: string,
  color?: string,
  viewBox?: string,
  style?: any,
  className?: string
  fill?: string
  onClick?: any
}

export class CircleX extends Component<IProps> {

  render() {

    const width = this.props.width ? this.props.width : "24px"
    const height = this.props.height ? this.props.height : "24px"
    const color = this.props.color ? this.props.color : "#000000"
    const viewBox = this.props.viewBox ? this.props.viewBox : "0 0 24 24"

    return (
      <div>
        <svg className="circlex" width={width} height={height} fill={color} viewBox={viewBox} version="1.1" xmlns="http://www.w3.org/2000/svg">
          <title id="title">Circle X</title>
          <path d="M12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 Z M12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 Z M8.29289322,8.29289322 C8.68341751,7.90236893 9.31658249,7.90236893 9.70710678,8.29289322 L9.70710678,8.29289322 L12,10.585 L14.2928932,8.29289322 C14.6533772,7.93240926 15.2206082,7.90467972 15.6128994,8.20970461 L15.7071068,8.29289322 C16.0976311,8.68341751 16.0976311,9.31658249 15.7071068,9.70710678 L15.7071068,9.70710678 L13.415,12 L15.7071068,14.2928932 C16.0675907,14.6533772 16.0953203,15.2206082 15.7902954,15.6128994 L15.7071068,15.7071068 C15.3165825,16.0976311 14.6834175,16.0976311 14.2928932,15.7071068 L14.2928932,15.7071068 L12,13.415 L9.70710678,15.7071068 C9.34662282,16.0675907 8.77939176,16.0953203 8.38710056,15.7902954 L8.29289322,15.7071068 C7.90236893,15.3165825 7.90236893,14.6834175 8.29289322,14.2928932 L8.29289322,14.2928932 L10.585,12 L8.29289322,9.70710678 C7.93240926,9.34662282 7.90467972,8.77939176 8.20970461,8.38710056 Z" id="path-2"></path>
        </svg>
      </div>
    )
  }
}


export class Copy extends Component<IProps> {
  
  render() {

    const width = this.props.width ? this.props.width : "24px"
    const height = this.props.height ? this.props.height : "24px"

    return (

      <IcoCopy className={this.props.className} width={width} height={height} onClick={this.props.onClick} />

    )
  }
}


export class Circle extends Component<IProps> {

  render() {

    const width = this.props.width ? this.props.width : "24px"
    const height = this.props.height ? this.props.height : "24px"

    return (

      <IcoCircle className={this.props.className} width={width} height={height} onClick={this.props.onClick} />

    )
  }
}

export class CircleWhite extends Component<IProps> {

  render() {

    const width = this.props.width ? this.props.width : "24px"
    const height = this.props.height ? this.props.height : "24px"

    return (

      <img src={IcoCircleWhite} width={width} height={height} className={this.props.className} onClick={this.props.onClick} />

    )
  }
}

export class CircleRed extends Component<IProps> {

  render() {

    const width = this.props.width ? this.props.width : "24px"
    const height = this.props.height ? this.props.height : "24px"

    return (

      <img src={IcoCircleRed} width={width} height={height} onClick={this.props.onClick} />

    )
  }
}

export class Approved extends Component<IProps> {

  render() {

    const width = this.props.width ? this.props.width : "24px"
    const height = this.props.height ? this.props.height : "24px"

    return (

      <IcoApproved className={this.props.className} width={width} height={height} onClick={this.props.onClick} />

    )
  }
}

export class ApprovedWhite extends Component<IProps> {

  render() {

    const width = this.props.width ? this.props.width : "24px"
    const height = this.props.height ? this.props.height : "24px"

    return (

      <img src={IcoApprovedWhite} width={width} height={height} onClick={this.props.onClick} />

    )
  }
}

export class Mail extends Component<IProps> {

  render() {

    const width = this.props.width ? this.props.width : "24px"
    const height = this.props.height ? this.props.height : "24px"

    return (
      <IcoMail className={this.props.className} width={width} height={height} onClick={this.props.onClick} />
    )
  }
}

export class CertPlus extends Component<IProps> {

  render() {

    const width = this.props.width ? this.props.width : "24px"
    const height = this.props.height ? this.props.height : "24px"

    return (
      <img src={IcoCertPlus} width={width} height={height} onClick={this.props.onClick} />
    )
  }
}

export class CertificateIcon extends Component<IProps> {

  render() {

    const width = this.props.width ? this.props.width : "24px"
    const height = this.props.height ? this.props.height : "24px"

    return (
      <IcoCert className={this.props.className} width={width} height={height} onClick={this.props.onClick} />
    )
  }
}

export class EnterIcon extends Component<IProps> {

  render() {

    const width = this.props.width ? this.props.width : "20px"
    const height = this.props.height ? this.props.height : "20px"

    return (
       <IcoEnter className={this.props.className} width={width} height={height} onClick={this.props.onClick} />
   
    )
  }
}

export class SkipForward extends Component<IProps> {

  render() {

    const width = this.props.width ? this.props.width : "12px"
    const height = this.props.height ? this.props.height : "12px"

    return (
      <IcoSkipForward className={this.props.className} width={width} height={height} onClick={this.props.onClick} />
    )
  }
}

export class MonitorIco extends Component<IProps> {

  render() {

    const width = this.props.width ? this.props.width : "18px"
    const height = this.props.height ? this.props.height : "18px"

    return (
      <IcoMonitor className={this.props.className} width={width} height={height} onClick={this.props.onClick} />
    )
  }
}

export class TrashIco extends Component<IProps> {

  render() {

    const width = this.props.width ? this.props.width : "18px"
    const height = this.props.height ? this.props.height : "18px"

    return (
      <IcoTrash className={this.props.className} width={width} height={height} onClick={this.props.onClick} />
    )
  }
}