import React, { Component } from 'react';
import axios from 'axios';
import SurveyFill3 from './SurveyFill3'
import { API, APIKEY, IBuilding, ICertificate, ICertificateSurvey } from './IVesta';
import { Space } from 'antd';

import './App.css';
import 'antd/dist/antd.css';


interface IState {
  building: IBuilding,
  certificate: ICertificate,
  certificatesurvey: ICertificateSurvey,
  viewevidence: boolean,
}

interface IProps {
  user: string,
  survey: string,
}


class Survey extends Component<IProps, IState>
{
  constructor(props: any) {
    super(props);

    this.state = {
      certificate: {} as ICertificate,
      building: { address1: "" } as IBuilding,
      certificatesurvey: {} as ICertificateSurvey,
      viewevidence: false,
    };

    this.onAnswered = this.onAnswered.bind(this);
    this.submit = this.submit.bind(this);
    this.toggleEvidence = this.toggleEvidence.bind(this);
    this.refresh = this.refresh.bind(this);

  }


  toggleEvidence() {
    this.setState({ viewevidence: !this.state.viewevidence })
  }


  componentDidMount() {
    this.refresh();
  }

  refresh() {
    const post = {
      security: {
        APIKey: APIKEY,
        user: this.props.user,
      },
      certificatesurveyid: this.props.survey,
    }
    axios.post(API + "/todo/survey", post)
      .then(response => {

        this.setState({
          building: response.data.building,
          certificate: response.data.certificate,
          certificatesurvey: response.data.certificateSurvey,
        });
        this.forceUpdate();
        
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  onAnswered() {
    this.refresh();
  }

  submit() {
    const post = {
      security: {
        APIKey: APIKEY,
        user: this.props.user,
      },
      ID: this.props.survey,
    }
    axios.post(API + "/survey/submit", post)
      .then(response => {
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  render() {
    if (this.state.certificate == null) return <Space></Space>;
    if (this.state.certificate.surveys == null) return <Space></Space>;

    var disp;
    if (this.state.certificatesurvey.isCompleted) {

      disp = <SurveyFill3
        certificate={this.state.certificate}
        survey={this.props.survey}
        building={this.state.building}
        user={this.props.user}
        certificatesurvey={this.state.certificatesurvey}
        refresh={this.refresh}
        completed={true}

      />

      // disp =  <>
      //           <Row justify="start">
      //                 Display Evidence
      //                 <Switch checked={this.state.viewevidence} onChange={this.toggleEvidence} />
      //           </Row>
      //           <SurveyView
      //               expanded
      //               certificatesurvey={this.state.certificatesurvey}
      //               displayEvidence={this.state.viewevidence}
      //             />
      //         </> 
    }
    else {
      disp = <SurveyFill3
        certificate={this.state.certificate}
        survey={this.props.survey}
        building={this.state.building}
        user={this.props.user}
        certificatesurvey={this.state.certificatesurvey}
        refresh={this.refresh}
        completed={false}
      />
    }

    return (
      <>
        {/* <Row justify="start">
                <BuildingCard building={this.state.building} />
                <CertificateCard certificate={this.state.certificate} />
                <SurveyCard survey={this.state.certificatesurvey} />
              </Row> */}
        {disp}
      </>);
  }

}

export default (Survey);
