import React, { Component } from "react";
import axios from "axios";
import {
  API,
  APIKEY,
  IBuilding,
  ICertificate,
  ICertificateSurvey,
  INav,
} from "./IVesta";
import VDropdownList, { IVDropdownItem } from "./components/VDropdownList";
import { FormatBuilding, AllSectionsCompleted } from "./lib/venus";
import SectionCard from "./components/SectionCard";
import ScrollableContainer from "./components/ScrollableContainer";
import {
  Circle,
  Approved,
  CertificateIcon,
  MonitorIco,
} from "./components/VenusIcons";
import history from "./components/history";
import { Row, Col, Typography, notification } from "antd";
import { MobileOutlined } from "@ant-design/icons";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { QRCode } from "./components/QRCode";
import Moment from "react-moment";
import { Collapse } from "antd";

import "./App.css";
import "antd/dist/antd.css";

const { Title } = Typography;
const { Panel } = Collapse;

interface IState {
  buildings: IBuilding[];
  buildingList: IVDropdownItem[];
  currentBuilding: IBuilding | null | undefined;
  selectedBuilding: IVDropdownItem | null | undefined;

  certificates: ICertificate[];
  currentCertificateSurveys: ICertificateSurvey | null | undefined;
  currentCertificate: ICertificate | null | undefined;
  currentCertificateStatus: any;
  spin?: boolean | undefined | null;

  activeTab: number;
  windowResize: boolean;
}

interface IProps extends WrappedComponentProps {
  history?: any;
  user: string;
  forManager?: boolean;
  nav: INav;
  setNav: React.Dispatch<React.SetStateAction<INav>>;
  forBrand?: boolean;
}

interface IBuildItem {
  item: ITabItem | null | undefined;
  tabs: ITabItem[] | null | undefined;
}

interface ITabItem {
  label: string;
  header?: React.ReactNode;
  sectionHeader?: React.ReactNode;
  item: JSX.Element;
  className: string;
}

interface ISectionCardData {
  cerificateId: string;
  certificateSurvey: ICertificateSurvey;
}

class Certificates2 extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      buildings: [],
      buildingList: [],
      certificates: [],
      currentBuilding: null,
      selectedBuilding: null,
      currentCertificate: null,
      currentCertificateStatus: "",
      currentCertificateSurveys: null,
      spin: false,

      activeTab: 1,
      windowResize: false,
    };

    this.refresh = this.refresh.bind(this);
    this.onSelectBuilding = this.onSelectBuilding.bind(this);
    this.setCertificate = this.setCertificate.bind(this);
    this.getCurrentBuilding = this.getCurrentBuilding.bind(this);
    this.getCurrentCertificate = this.getCurrentCertificate.bind(this);
    this.setNav = this.setNav.bind(this);
    this.getNav = this.getNav.bind(this);
    this.setSpin = this.setSpin.bind(this);
    this.saveCertificate = this.saveCertificate.bind(this);
    this.updateUnit = this.updateUnit.bind(this);
    this.windowResize = this.windowResize.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.windowResize);
    this.refresh();
  }

  componentDidUpdate() {
    // this.initTabs();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.windowResize);
  }

  setSpin(spin: boolean) {
    this.setState({ spin: spin });
  }

  tabVisible(tab): boolean | null {
    var x = document.getElementById(tab);
    if (x === null) return null;
    return window.getComputedStyle(x).display !== "none";
  }

  windowResize() {
    this.setState({ windowResize: true });
  }

  copyToClipboard(toCopy: string) {
    navigator.clipboard.writeText(toCopy);

    notification.open({
      message: "Clip Board",
      description: "You certificate url has been copied to the clip board",
      placement: "topRight",
    });
  }

  openWin(url: string) {
    window.open(url);
  }

  saveCertificate(
    certificateID: string | null | undefined,
    certificateSurvey: ICertificateSurvey | null | undefined
  ) {
    if (!certificateID || !certificateSurvey) return;

    const post = {
      security: {
        APIKey: APIKEY,
        user: this.props.user,
      },
      certificateID: certificateID,
      certificateSurvey: certificateSurvey,
    };
    var url = API + "/survey/UpdateCertificateSurvey";

    axios
      .post(url, post)
      .then((response) => {
        this.refresh();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  refresh() {
    const $this = this;
    this.setSpin(true);

    var post;
    var url = API + "/building/formanager";
    if (!this.props.forManager) url = API + "/building/forclient";

    if (this.props.forBrand) {
      post = {
        security: {
          APIKey: APIKEY,
          user: this.props.user,
        },
        clientEmail: this.props.user,
        showExternal: false,
      };

      url = API + "/certificate/search";
    } else {
      post = {
        security: {
          APIKey: APIKEY,
          user: this.props.user,
        },
      };
    }

    axios
      .post(url, post)
      .then((response) => {
        if (this.props.forBrand) {
          this.setState({
            certificates: response.data.certificates,
            currentCertificate: response.data.certificates[0],
          });
        } else {
          const list = this.getBuildingList(response.data.buildings);

          const currentBuilding = this.getCurrentBuilding(
            response.data.buildings
          );

          this.setState({
            buildings: response.data.buildings,
            buildingList: list,
            currentBuilding: currentBuilding,
            selectedBuilding: list[0],
          });

          var nav = this.getNav().certificate;
          this.setNav(currentBuilding?.id, nav.certificateId);

          this.getCertificates(currentBuilding);

          this.setSpin(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        $this.setSpin(false);
      });
  }

  getCertificates(building: IBuilding | null | undefined) {
    const $this = this;
    this.setSpin(true);

    if (!building) {
      this.setState({
        certificates: [],
      });
      return;
    }

    const post = {
      security: {
        APIKey: APIKEY,
        user: this.props.user,
      },
      buildingid: building.id,
      loadAnswers: true,
      disableConditionals: true,
    };

    axios
      .post(API + "/todo/building", post)
      .then((response) => {
        const currentCertificate = this.getCurrentCertificate(
          response.data.certificates
        );

        this.setState({
          certificates: response.data.certificates,
          currentCertificate: currentCertificate,
        });

        var nav = this.getNav().certificate;
        this.setNav(nav.buildingId, currentCertificate?.id);

        this.setSpin(false);
      })
      .catch(function (error) {
        console.log(error);
        $this.setSpin(false);
      });
  }

  getBuildingList(buildings: IBuilding[]): IVDropdownItem[] {
    var ret: IVDropdownItem[] = [];

    for (var i = 0; i < buildings.length; i++) {
      const element = buildings[i];
      ret.push({ key: element.id, text: FormatBuilding(element), index: i });
    }

    return ret;
  }

  getCurrentBuilding(buildings: IBuilding[]): IBuilding | null | undefined {
    var ret: IBuilding | null | undefined;
    const nav = this.getNav().certificate;

    if (nav.buildingId) {
      ret = buildings.find((x) => x.id === nav.buildingId);
      if (ret) return ret;
    }

    ret =
      this.state.currentBuilding ?? buildings.length > 0 ? buildings[0] : null;

    return ret;
  }

  getCurrentCertificate(
    certs: ICertificate[]
  ): ICertificate | null | undefined {
    var ret: ICertificate | null | undefined;
    const nav = this.getNav().certificate;

    if (nav.certificateId) {
      ret = certs.find((x) => x.id === nav.certificateId);
      if (ret) return ret;
    }

    ret = this.state.currentCertificate ?? certs.length > 0 ? certs[0] : null;

    return ret;
  }

  onSelectBuilding(e: any, item: IVDropdownItem | null | undefined) {
    const currentBuilding = item ? this.state.buildings[item.index] : null;
    const nav = this.getNav().certificate;

    this.setState({ currentBuilding: currentBuilding });

    if (currentBuilding) {
      this.setNav(currentBuilding?.id, nav.certificateId);
      this.getCertificates(currentBuilding);
    } else {
      this.setState({ certificates: [] });
    }
  }

  setCertificate(e: ICertificate) {
    const nav = this.getNav().certificate;

    this.setNav(nav.buildingId, e.id);
    this.setState({ currentCertificate: e });
  }

  setSurvey(e: ICertificateSurvey) {}

  setNav(
    buildingId: string | null | undefined,
    certificateId: string | null | undefined
  ): void {
    var nav = this.getNav();

    nav.certificate.buildingId = buildingId;
    nav.certificate.certificateId = certificateId;

    this.props.setNav(nav);
  }

  getNav(): INav {
    return this.props.nav;
  }

  renderTabBar = (props, DefaultTabBar) => (
    <DefaultTabBar
      key="defaultTabBar"
      {...props}
      className={
        this.state.activeTab === 1
          ? "SurveyTabs tab1-css"
          : "SurveyTabs tab2-css"
      }
    />
  );

  certStatus(completed: boolean, cert: ICertificate | null | undefined) {
    return !completed ? (
      <>Incomplete</>
    ) : cert?.issuedOn ? (
      <>Approved</>
    ) : (
      <>Submitted</>
    );
  }

  updateUnit(unit: string, data: ISectionCardData) {
    if (unit.trim() === "" || !data) return;
    if (data.cerificateId.trim() === "") return;

    data.certificateSurvey.unit = unit;
    this.saveCertificate(data.cerificateId, data.certificateSurvey);
  }

  render() {
    var navTop = -70;
    const $this = this;

    const certificates = this.state.certificates.map(function (
      element: ICertificate,
      i: number
    ) {
      const isSelected = element.id === $this.state.currentCertificate?.id;
      var isCompleted =
        element.surveys.filter(function (survey) {
          return !survey.isCompleted;
        }).length === 0;
      var cssStyle = "sectionCard";
      var subText = $this.certStatus(isCompleted, element);
      var extraText = <></>;
      if (element.hasExpectedCompletedDate && element.expectedCompletedDate) {
        const formattedDate = (
          <Moment format="DD/MMM/yyyy">
            {new Date(element.expectedCompletedDate)}
          </Moment>
        );
        extraText = (
          <>
            Expected completion {formattedDate}
          </>
        );
        navTop = -80;
      }

      if (isSelected) {
        cssStyle = "sectionCard selected";
      }

      const icon = isSelected ? (
        isCompleted ? (
          <Approved
            className="icon-completed-accent"
            width="36px"
            height="36px"
          />
        ) : (
          <Circle className="icon-selected" width="36px" height="36px" />
        )
      ) : isCompleted ? (
        <Approved
          className="icon-completed-accent"
          width="36px"
          height="36px"
        />
      ) : (
        <Circle className="icon-normal" width="36px" height="36px" />
      );

      var last = i === $this.state.certificates.length - 1;

      return (
        <SectionCard
          key={element.id}
          className={cssStyle}
          icon={icon}
          name={element.name}
          subText={subText}
          extraText={extraText}
          onClick={(e) => $this.setCertificate(element)}
          last={last}
        />
      );
    });

    var inProgressCount = 0;

    const inProgressSurveys = this.state.currentCertificate?.surveys
      .filter((x) => !x.isCompleted)
      .map(function (element: ICertificateSurvey, i: number) {
        const linkTo = "/survey/" + element.id;
        var canEdit = false;
        const subText =
          element.answers === null ? "Not yet started" : "In Progress";

        const deviceIcon =
          element.survey.completionMethod === 1 ? (
            <MobileOutlined />
          ) : (
            <MonitorIco />
          );

        ++inProgressCount;

        if (element.survey.completionMethod !== 1) {
          canEdit = false;
        }

        var data: ISectionCardData = {
          cerificateId: $this.state.currentCertificate?.id ?? "",
          certificateSurvey: element,
        };

        // if (element.unit) {

        const name =
          element.unit === "DESK BASED" || !element.unit
            ? element.survey.name
            : `${element.unit} - ${element.survey.name}`;

        return (
          <div className="sectionCard-wrapper">
            <SectionCard
              key={element.id}
              icon={
                <Circle className="icon-normal" width="36px" height="36px" />
              }
              name={name}
              subText={subText}
              canEdit={canEdit}
              linkTo={linkTo}
              editPlaceHolder="Rename Unit"
              onUpdate={(e, data) => $this.updateUnit(e, data)}
              data={data}
              defaultInputValue={true}
              deviceIcon={deviceIcon}
            />
          </div>
        );
      });

    var completedCount = 0;

    //const completedSurveys = this.state.currentCertificate?.surveys.filter(x => AllSectionsCompleted(x.survey.sections)).map(function (element: ICertificateSurvey, i: number) {
    const completedSurveys = this.state.currentCertificate?.surveys
      .filter((x) => x.isCompleted)
      .map(function (element: ICertificateSurvey, i: number) {
        const cssStyle = {};
        const linkTo = "/survey/" + element.id;
        var completed =
          AllSectionsCompleted(element.survey.sections) || element.isCompleted;
        var subText = $this.certStatus(
          completed,
          $this.state.currentCertificate
        );
        const deviceIcon =
          element.survey.completionMethod === 1 ? (
            <MobileOutlined />
          ) : (
            <MonitorIco />
          );

        ++completedCount;

        return (
          <div className="sectionCard-wrapper">
            {/* <NavLink to={"/survey/" + element.id}> */}
            <div onClick={() => history.push("/survey/" + element.id)}>
              <SectionCard
                key={element.id}
                icon={
                  <Approved
                    className="icon-completed-accent"
                    width="36px"
                    height="36px"
                  />
                }
                style={cssStyle}
                name={element.survey.name}
                subText={subText}
                onClick={(e) => $this.setSurvey(e)}
                data={{}}
                linkTo={linkTo}
                deviceIcon={deviceIcon}
              />
            </div>
            {/* </NavLink> */}
          </div>
        );
      });

    const tabTodo = <>{inProgressSurveys}</>;

    const tabComplete = (
      <>
        {completedCount === 0 && (
          <>
            <br />
            <p>
              There are currently no completed surveys, please complete the
              surveys listed in the to do section
            </p>
          </>
        )}
        {completedSurveys}
      </>
    );

    const tabCertificate = (
      <QRCode
        id={this.state.currentCertificate?.id ?? ""}
        name={this.state.currentCertificate?.name}
        issuedOn={this.state.currentCertificate?.issuedOn}
        expiresOn={this.state.currentCertificate?.expiresOn}
        canUse={true}
        canclick={true}
        premium={this.state.currentCertificate?.premium || false}
      />
    );

    function buildItems(isBrandSurvey) {
      var buildItems: IBuildItem[] = [];
      var tabItems: ITabItem[] = [];

      const toDoLabel = "To Do";
      const toDoHeader = (
        <>
          <Circle className="icon-normal accordion-panel-header" />
          <span className="accordion-header"> {toDoLabel}</span>
          <span className="counter">
            ( {inProgressCount} Surveys remaining )
          </span>
        </>
      );
      const toDoCss = "surveytodo";
      const toDoSectionHeader = (
        <>
          <Circle
            className="icon-normal section-panel-header"
            width="36px"
            height="36px"
          />{" "}
          {toDoLabel}
        </>
      );

      const completedLabel = "Completed";
      const completedHeader = (
        <>
          <Approved className="icon-completed-accent accordion-panel-header" />
          <span className="accordion-header"> {completedLabel}</span>
          <span className="counter">( {completedCount} Surveys )</span>
        </>
      );
      const completedCss = "surveycomplete";
      const completedSectionHeader = (
        <>
          <Approved
            className="icon-completed-accent section-panel-header"
            width="36px"
            height="36px"
          />{" "}
          {completedLabel}
        </>
      );

      const certificateLabel = "Certificate";
      const certificateHeader = (
        <>
          <CertificateIcon className="icon-approved-accent accordion-panel-header" />
          <span className="accordion-header"> {certificateLabel}</span>
        </>
      );
      const certificateCss = "surveyapproved";
      const certificateSectionHeader = (
        <>
          <CertificateIcon
            className="icon-approved-accent section-panel-header"
            width="36px"
            height="36px"
          />{" "}
          {certificateLabel}
        </>
      );

      // var TabBreaks:boolean[] = [true,]

      var tabBreak1 = $this.tabVisible("TabBreak1");
      var tabBreak2 = $this.tabVisible("TabBreak2");

      if (inProgressCount === 0) {
        tabBreak2 = tabBreak1;
        tabBreak1 = null;
      }

      if (tabBreak2) {
        if (inProgressCount > 0) {
          tabItems.push({
            label: toDoLabel,
            header: toDoHeader,
            item: tabTodo,
            className: toDoCss,
          });
        }

        tabItems.push({
          label: completedLabel,
          header: completedHeader,
          item: tabComplete,
          className: completedCss,
        });

        if (!isBrandSurvey) {
          tabItems.push({
            label: certificateLabel,
            header: certificateHeader,
            item: tabCertificate,
            className: certificateCss,
          });
        }

        buildItems.push({ item: null, tabs: tabItems });
      } else {
        if (inProgressCount > 0) {
          buildItems.push({
            item: {
              label: toDoLabel,
              sectionHeader: toDoSectionHeader,
              header: toDoHeader,
              item: tabTodo,
              className: toDoCss,
            },
            tabs: null,
          });
        }

        buildItems.push({
          item: {
            label: completedLabel,
            sectionHeader: completedSectionHeader,
            header: completedHeader,
            item: tabComplete,
            className: completedCss,
          },
          tabs: null,
        });

        if (!isBrandSurvey) {
          buildItems.push({
            item: {
              label: certificateLabel,
              sectionHeader: certificateSectionHeader,
              header: certificateHeader,
              item: tabCertificate,
              className: certificateCss,
            },
            tabs: null,
          });
        }
      }

      var columns = buildItems
        .filter((x) => x.item != null)
        .map(function (item: IBuildItem, i: number) {
          return (
            <Col
              key={item.item?.label}
              flex="6"
              className={item.item?.className}
            >
              <Title level={4} className="title">
                {item.item?.sectionHeader}
              </Title>
              {item?.item?.item}
            </Col>
          );
        });

      var tabs = buildItems
        .filter((x) => x.tabs != null)
        .map(function (tab: IBuildItem, i: number) {
          return tab.tabs?.map(function (tab: ITabItem, i: number) {
            return (
              <Panel
                header={tab.header}
                key={i + 1}
                className={tab.className}
                style={{ padding: 0 }}
              >
                <Col key={"col" + tab.label} flex="6">
                  {tab.item}
                </Col>
              </Panel>
            );
          });
        });

      return (
        <>
          {columns?.length > 0 && columns}

          {tabs.length > 0 && (
            <Col key="collpaseCol" flex={6}>
              <div>
                <Collapse
                  bordered={false}
                  key="collapse"
                  defaultActiveKey={["1"]}
                >
                  {tabs}
                </Collapse>
              </div>
            </Col>
          )}
        </>
      );
    }

    return (
      <>
        <div className="BuildingsPage" style={{ height: "100hv" }}>
          <br />

          {!this.props.forBrand && (
            <Row className="header">
              <div className="building-list">
                <VDropdownList
                  spin={this.state.spin}
                  title={this.props.intl.formatMessage({
                    id: "todo.properties",
                  })}
                  items={this.state.buildingList}
                  onItemSelect={this.onSelectBuilding}
                  selectedItem={this.state.selectedBuilding}
                />{" "}
              </div>
              <div className="building-address">
                {FormatBuilding(this.state.currentBuilding)}
              </div>
            </Row>
          )}
          <Row>
            <div className="SectionScroller height121">
              <ScrollableContainer scrollBy={370} navTop={navTop}>
                {certificates}
              </ScrollableContainer>
            </div>
          </Row>

          <div id="TabBreak1"></div>
          <div id="TabBreak2"></div>
          <div id="TabBreak3"></div>

          <Row justify="start" className="win-size">
            {buildItems(this.props.forBrand)}
          </Row>
        </div>
      </>
    );
  }
}

export default injectIntl(Certificates2);
