import React, { Component } from 'react';
import { NavLink, } from 'react-router-dom';
import { IBuilding, } from './IVesta';
import { Space, Col, Image, Typography } from 'antd';
import { CSSProperties } from 'react';

import './App.css';
import 'antd/dist/antd.css';

const { Text, } = Typography;

interface IState {
}

interface IProps {
    building: IBuilding,
}

class BuildingCard extends Component<IProps, IState>
{
    constructor(props: any) {
        super(props);

        this.state = {
        };
    }


    render() {

        const wrapper: CSSProperties = {
            width: '350px',
            height: '140px',
            margin: '20px auto',
            borderRadius: '7px 7px 7px 7px',
            /* VIA CSS MATIC https://goo.gl/cIbnS */
            boxShadow: '0px 14px 32px 0px rgba(0, 0, 0, 0.15)'
        }

        const productImg: CSSProperties = {
            float: 'left',
            height: '140px',
            width: '205px'
        }


        const productInfo: CSSProperties = {
            float: "left",
            width: '145px',
            height: '140px',
            borderRadius: '0 7px 10px 7px',
            backgroundColor: '#ffffff'
        }

        if (this.props.building == null) return <Space></Space>;
        return (
            <Col flex="400px">
                <NavLink to={"/building/" + this.props.building.id} >
                    <div style={wrapper}>
                        <div style={productImg}>

                            <Image
                                width={200}
                                src={`/images/building.jpg`}
                            />
                        </div>
                        <div style={productInfo}>
                            <Text>{this.props.building.buildingName}</Text><br />
                            <Text>{this.props.building.address1}</Text><br />
                            <Text>{this.props.building.address2}</Text><br />
                            <Text>{this.props.building.city}</Text><br />
                            <Text>{this.props.building.postcode}</Text>
                        </div>
                    </div>
                </NavLink>
            </Col>
        );
    }
}

export default (BuildingCard);
