import React, { Component } from 'react';
import { Button, Spin } from 'antd';

import '../App.css';
import 'antd/dist/antd.css';

interface IProps {
    contents?: any,
    className?: string,
    shape?: any,
    size?: any,
    style?: any,
    icon?: any,
    text?: any,
    onClick?: any
    type?: any
    altText?: string;
    altTextWindowSize?: number
    disabled?: boolean | null | undefined,
    htmlType?: "button" | "submit" | "reset" | undefined
    loading?: boolean
    loadingIcon?: any
    loadingText?: string
}

interface IState {
    //text: string;
}

export class VButton extends Component<IProps, IState> {

    constructor(props: any) {
        super(props);

        this.state = {
            // text: this.props.text ?? ""
        }

        this.windowResize = this.windowResize.bind(this);
        this.getContents = this.getContents.bind(this);
    }

    componentDidMount() {
        this.windowResize();
        window.addEventListener('resize', this.windowResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.windowResize);
    }

    windowResize() {
        var buttonText = "";
        if (this.props.altText && this.props.altTextWindowSize) {
            if (this.props.altTextWindowSize <= window.innerWidth) {
                buttonText = this.props.text ?? ""
            }
            else {
                buttonText = this.props.altText;
            }

            this.setState({ text: buttonText })
        }
    }

    onClick() {
        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    getContents() {

        const icon = !this.props.loading
            ? this.props.icon
            : this.props.loadingIcon
                ? this.props.loadingIcon
                : <Spin style={{ fontSize: "24px" }} />


        const text = !this.props.loading
            ? this.props.text
            : this.props.loadingText
                ? this.props.loadingText
                : this.props.text

        if (this.props.icon || this.props.text) {
            return (
                <div className="ButtonContents">
                    <div className="ButtonIcon">{icon}</div>
                    <div className="ButtonText">{text}</div>
                </div>
            );
        }

        if (this.props.contents) {
            return this.props.contents;
        }

        return (<></>);
    }

    render() {
        const disabled = this.props.disabled || this.props.loading ? true : false;
        const shape = this.props.shape ? this.props.shape : "round";
        const size = this.props.size ? this.props.size : "large";
        const className = this.props.className
            ? this.props.className
            : "VButtonContents"

        return (
            <Button
                onClick={this.props.onClick}
                className={className}
                size={size}
                shape={shape}
                type={this.props.type}
                style={this.props.style}
                disabled={disabled}
                htmlType={this.props.htmlType}
            >
                {this.getContents()}
            </Button>
        );

    }

}