import React, { Component } from 'react';
import axios from 'axios';
import { NavLink, } from 'react-router-dom';
import { API, APIKEY, IBuilding, ICertificate } from './IVesta';
import { Card, Space, Row, Col, Divider } from 'antd';
import  BuildingCard from './BuildingCard'

import './App.css';
import 'antd/dist/antd.css'; 

const { Meta } = Card;

interface IState {
    building: IBuilding,
    certificates: ICertificate[],
}
  
interface IProps  {
  user: string,
  building: string,
}

class Building extends Component<IProps,IState>
{
    constructor(props: any) {
        super(props);
        
        this.state = {
          certificates: [],
          building: {address1: ""} as IBuilding,
        };
    }

    componentDidMount()
    {
      this.refresh();
    }

    refresh()
    {      
      const post = {
        security: {
          APIKey: APIKEY,
          user: this.props.user,
        },
        buildingid: this.props.building,
      }
      axios.post(API + "/todo/building",post)
        .then(response => {
            this.setState({
              building: response.data.building,
              certificates: response.data.certificates,
            });
        })
        .catch(function (error) {
          console.log(error);
        });
    }


    render() 
    {
      if(this.state.building == null) return <Space></Space>;
        const certificates = 
              this.state.certificates.map(function(element: ICertificate,i: number)  {
                return (
                    <Col  flex="250px" key={element.id}>
                      <NavLink to={"/certificate/" + element.id}>
                      <Card
                          hoverable
                          style={{ width: 240 }}
                          cover={<img alt="example" src="/images/certificate.jpg" />}
                      >
                          <Meta title={element.name} description="" />
                      </Card>
                      </NavLink>
                    </Col>
                )
              });
    
    
        return (
            <>
              <Row justify="start">
                <BuildingCard building={this.state.building} />
              </Row>
              <Row justify="start">
                <Col flex={24}>
                  <Divider orientation="left">Certificates</Divider>
                </Col>
              </Row>
              <Row>
               {certificates}
              </Row>
            </>);
    }
      
}

export default (Building);
