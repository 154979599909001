import React, { Component } from 'react';
import { IBuilding, ICertificate, ICertificateSurvey } from '../IVesta';
import { Switch, Space, Typography, Row, Col } from 'antd';
import SurveyView from './SurveyView';

import '../App.css';
import 'antd/dist/antd.css';

const { Title } = Typography;

interface IState {
    building: IBuilding,
    certificatesurvey: ICertificateSurvey,
    viewevidence: boolean,
    viewanswers: boolean,
    openedsurvey: string,
}

interface IProps {
    user: string,
    certificate: ICertificate,
}


class CertificateView extends Component<IProps, IState>
{
    constructor(props: any) {
        super(props);

        this.state = {
            building: { address1: "" } as IBuilding,
            certificatesurvey: {} as ICertificateSurvey,
            viewevidence: false,
            viewanswers: false,
            openedsurvey: "",
        };

        this.showImage = this.showImage.bind(this);
    }

    componentDidMount() {
    }

    showImage(url) {
    }

    render() {
        if (this.props.certificate == null) return <Space></Space>;


        var surveys;

        if (this.state.viewanswers) {
            surveys = this.props.certificate.surveys.map(i => {

                return <SurveyView
                    key={i.id}
                    certificatesurvey={i}
                    displayEvidence={this.state.viewevidence}
                    expanded
                />

            });
        }
        else {
            surveys = this.props.certificate.surveys.map(i => {
                if (i.id === this.state.openedsurvey) {
                    return <SurveyView
                        key={i.id}
                        certificatesurvey={i}
                        displayEvidence={this.state.viewevidence}
                        expanded
                        onExpand={() => { this.setState({ openedsurvey: "" }) }}
                    />

                }
                return <SurveyView
                    key={i.id}
                    certificatesurvey={i}
                    displayEvidence={this.state.viewevidence}
                    expanded={false}
                    onExpand={() => { this.setState({ openedsurvey: i.id }) }}
                />;
            });
        }

        return (
            <div>
                <Row justify="start">
                    <Title level={3} >{this.props.certificate.name}</Title>
                </Row>
                <Row justify="start">
                    <Col span={8}>
                        <Space>
                            Show All Answers
                            <Switch checked={this.state.viewanswers} onChange={e => this.setState({ viewanswers: !this.state.viewanswers })} />
                        </Space>
                    </Col>
                    <Col span={8}>
                        <Space>
                            Display Evidence
                            <Switch checked={this.state.viewevidence} onChange={e => this.setState({ viewevidence: !this.state.viewevidence })} />
                        </Space>
                    </Col>
                </Row>
                {surveys}
            </div>
        );
    }

}

export default (CertificateView);
