import React, { Component } from 'react';
import axios from 'axios';
import ScrollableContainer from './components/ScrollableContainer'
import { API, APIKEY, IBuilding, ICertificate, ICertificateSurvey, ISurveySection } from './IVesta';
import { Space, Row, Col, Typography, PageHeader, Tabs } from 'antd';
import QuestionCard2 from './QuestionCard'
import SectionCard from './components/SectionCard';
import { Circle, Approved, MonitorIco } from './components/VenusIcons'
import { FormatBuilding, AllSectionsCompleted } from './lib/venus';
import { ArrowRightOutlined, MobileOutlined } from '@ant-design/icons';
import storeImage from './images/store.png';

import './App.css';
import 'antd/dist/antd.css';
import Moment from 'react-moment';

const { Title } = Typography;
const { TabPane } = Tabs;

interface IState {
  viewall: boolean,
  submitEnabled: boolean,
  showSubmit: boolean,
  currentsection: ISurveySection,
  activeTab: number,
  showTodoCol: boolean,
  showCompletedCol: boolean,
  tabsVisible?: boolean | null,
}

interface IProps {
  user: string,
  survey: string,
  building: IBuilding,
  certificate: ICertificate,
  certificatesurvey: ICertificateSurvey,
  refresh: any,
  completed?: boolean
}

class SurveyFill3 extends Component<IProps, IState>
{
  constructor(props: IProps) {
    super(props);

    this.state = {
      viewall: false,
      submitEnabled: this.dovalidate(),
      showSubmit: this.showSubmit(),
      currentsection: props.certificatesurvey.survey.sections[0],
      activeTab: 1,
      tabsVisible: null,
      showTodoCol: true,
      showCompletedCol: true
    };

    this.onAnswered = this.onAnswered.bind(this);
    this.submit = this.submit.bind(this);
    this.showSubmit = this.showSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.dovalidate = this.dovalidate.bind(this);
    this.setSection = this.setSection.bind(this);
    this.formatBuilding = this.formatBuilding.bind(this);

    this.initTabs = this.initTabs.bind(this);
    this.onTabClick = this.onTabClick.bind(this);
    this.windowResize = this.windowResize.bind(this);
    this.renderTabBar = this.renderTabBar.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.windowResize);
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.certificatesurvey !== prevProps.certificatesurvey) {
      this.validate();

      var sec = this.props.certificatesurvey.survey.sections.find(s => { return s.id === this.state.currentsection.id });
      if (sec != null) {
        this.setState({ currentsection: sec });
      }
      else {
      }
    }

    this.initTabs();

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResize);
  }


  setSection(val: ISurveySection) {
    this.setState({ currentsection: val });
  }

  onAnswered() {
    this.props.refresh();
  }

  validate() {
    var valid = this.dovalidate();
    this.setState({ submitEnabled: valid });
  }

  dovalidate() {
    const valid = AllSectionsCompleted(this.props.certificatesurvey.survey.sections)
    return (valid);
  }

  showSubmit() {
    if (this.props.certificate.issued || this.props.certificatesurvey.isCompleted) {
      return false;
    }

    return true;
  }

  submit() {
    const post = {
      security: {
        APIKey: APIKEY,
        user: this.props.user,
      },
      ID: this.props.survey,
    }
    axios.post(API + "/survey/submit", post)
      .then(response => {
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  formatBuilding(unit?: string | null | undefined) {
    return FormatBuilding(this.props.building, unit)
  }

  tabsVisible(): boolean | null {
    var x = document.getElementById("SurveyTabs");
    if (x === null) return null;
    return window.getComputedStyle(x).display !== "none";
  }

  initTabs() {
    const tabsvisible = this.tabsVisible();

    if (tabsvisible !== null && this.state.tabsVisible === null) {
      var showTodoCol = this.state.showTodoCol;
      var showCompletedCol = this.state.showCompletedCol;

      if (tabsvisible) {
        showTodoCol = this.state.activeTab === 1;
        showCompletedCol = this.state.activeTab === 2;
      }

      this.setState({ showTodoCol: showTodoCol, showCompletedCol: showCompletedCol, tabsVisible: tabsvisible });
    }

  }

  windowResize() {

    try {
      const tabsvisible = this.tabsVisible();
      var showTodoCol = true;
      var showCompletedCol = true;

      if (tabsvisible) {
        showTodoCol = this.state.activeTab === 1;
        showCompletedCol = this.state.activeTab === 2;
      }

      this.setState({ showTodoCol: showTodoCol, showCompletedCol: showCompletedCol, tabsVisible: tabsvisible });

    } catch (ex) { }

  }

  onTabClick(activeKey: string, e: React.KeyboardEvent | React.MouseEvent) {
    const activeTab = parseInt(activeKey);
    const showTodoCol = activeTab === 1;
    const showCompletedCol = activeTab === 2;

    this.setState({ activeTab: activeTab, showTodoCol: showTodoCol, showCompletedCol: showCompletedCol });
  }

  renderTabBar = (props, DefaultTabBar) => (
    <DefaultTabBar {...props} className={this.state.activeTab === 1 ? "SurveyTabs tab1-css" : "SurveyTabs tab2-css"} />
  );



  render() {
    if (this.props.certificate == null) return <Space></Space>;
    if (this.props.certificate.surveys == null) return <Space></Space>;
    const id = this.props.certificatesurvey.id;

    var allquestions;
    var completedCount = 0;
    var onSiteSurvey = this.props.certificatesurvey.survey.completionMethod === 1

    const $this = this;
    const sections = this.props.certificatesurvey.survey.sections
      .map(function (element: ISurveySection, i: number) {
        var cssStyle = "sectionCard";
        var isSelected = element.id === $this.state.currentsection.id;

        if (element.id === $this.state.currentsection.id) {
          cssStyle = "sectionCard selected"
        }

        var questioncount = 0;
        var answercount = 0;
        var completed = true;
        element.questions.forEach(q => {
         
          completed = !(q.answer == null) && completed;

          questioncount++;

          // if (q.type === 6) {
          //   ++answercount;
          // }
           if (q.answer?.na === true) {
            ++answercount
          }
          else if (q.answer !== null) {
            ++answercount
          }

        });

        const icon = isSelected
          ? completed ? <Approved className="icon-completed-accent" width="36px" height="36px" /> : <Circle className="icon-selected" width="36px" height="36px" />
          : completed ? <Approved className="icon-completed-accent" width="36px" height="36px" /> : <Circle className="icon-normal" width="36px" height="36px" />

          
        const subText = <span className="answer-progress">({answercount} / {questioncount})</span>

        if (completed) ++completedCount;

        var last = i === $this.props.certificatesurvey.survey.sections.length - 1;

        return (

          <SectionCard
            key={i}
            className={cssStyle}
            icon={icon}
            name={element.name}
            subText={subText}
            onClick={(e) => $this.setSection(element)}
            last={last}
          />

        )
      });

    const lastUpdated = this.props.certificatesurvey.lastUpdated === null
      ? <>Never</>
      : <Moment format="DD MMM YYYY">{this.props.certificatesurvey.lastUpdated}</Moment>


    const unitName = this.props.building && this.props.certificatesurvey.unit && this.props.certificatesurvey.unit !== "DESK BASED"
      ? `${this.props.certificatesurvey.unit} - `
      : ""

    const surveyIcon = onSiteSurvey
      ? <MobileOutlined />
      : <MonitorIco width="24px" height="24px" />

    var ApartmentInfoAndSubmit =
      <Row className="ApartmentInfoAndSubmit" style={{ maxWidth: this.props.completed ? "1000px" : "100%" }}>
        <div className="HotelInfoPanel" >

          {this.props.building &&
            this.formatBuilding()
          }

          <div className="title Yellow">
            <div className="icon">
              {surveyIcon}
            </div>
            <div className="text">
              {unitName}{this.props.certificatesurvey.survey.name}
            </div>
          </div>

          <div className="progress">
            In Progress Last Saved : {lastUpdated}
          </div>

        </div>

        {this.state.showSubmit &&
          <div className="SubmitPanel">

            <button className="complete-button" disabled={!this.state.submitEnabled} onClick={() => this.submit()}>
              <div className="content">
                <div>
                  Submit Survey
                </div>
                <div>
                  Completed Sections ({completedCount}/{this.props.certificatesurvey.survey.sections.length})
                </div>

              </div>
              <div className="icon">
                <ArrowRightOutlined />
              </div>
            </button>

          </div>
        }

      </Row>

  
    var ansquestions;
    const section = this.state.currentsection;
    //
    //  All the uploads

    const unanswered = section.questions.filter(q => {

      if (q.answer === null) return true;
      if (q.evidencerequired === 0) return false;
      if (q.evidencerequired === 1) {
        if (q.answer?.evidence === null) return true;
        return false;
      }
      if (q.answer?.evidence != null) return false;
      if (q.evidencerequired === 2) {
        return (q.answer?.answer === "True")
      }
      return (q.answer?.answer === "False")

    });

    allquestions = unanswered.map(q => {
      return <QuestionCard2 key={q.id} user={this.props.user} question={q} onSiteSurvey={onSiteSurvey} certificatesurveyid={id} onAnswered={this.onAnswered} startDate={this.props.certificatesurvey?.startDate ?? this.props.certificatesurvey?.assignedDate} />
    });

    const answered = section.questions.filter(q => {
      //if(q.type == 6) return true;    //  Never hide multi choice questions
      if (q.answer === null) return false;

      if (q.evidencerequired === 0) return true;

      if (q.evidencerequired === 1) return (!(q.answer?.evidence === null));
      //   if (q.answer?.evidence === null) return false;
      //   return true;
      // }

      if (q.answer?.evidence != null) return true;

      if (q.evidencerequired === 2) return (!(q.answer?.answer === "True"));

      return (!(q.answer?.answer === "False"))

    });
    ansquestions = answered.map(q => {
      return <QuestionCard2 key={q.id} user={this.props.user} question={q} completed={this.props.completed ?? false} onSiteSurvey={onSiteSurvey} certificatesurveyid={id} onAnswered={this.onAnswered} />
    });



    const store = onSiteSurvey
      ? <img key="storeImage" src={storeImage} height={52} />
      : <></>

    return (
      <>
        <br />

        <PageHeader
          title="All Certificates"
          ghost={false}
          onBack={() => window.history.back()}
          style={{ backgroundColor: 'rgba(240, 242, 245,0.0)' }}
          extra={[store]}
          key="pageHeader"
        />

        {ApartmentInfoAndSubmit}

        <Row>
          <div className="SectionScroller" style={{ maxWidth: this.props.completed ? "1000px" : "100%" }}>

            <ScrollableContainer scrollBy={370} >
              {sections}
            </ScrollableContainer>

          </div>
        </Row>

        {!this.props.completed &&
          <Row id="SurveyTabs" className="SurveyTabs" >
            <Tabs defaultActiveKey="1" onTabClick={this.onTabClick} renderTabBar={this.renderTabBar} >

              <TabPane tab="To Do" key="1" className="tab1-css">
              </TabPane>


              <TabPane tab="Completed" key="2" className="tab2-css">
              </TabPane>
            </Tabs>
          </Row>
        }

        <Row justify="start">
          {!this.props.completed &&
            <Col flex="11" className="unansweredCol" style={{ display: this.state.showTodoCol ? "block" : "none" }} >
              <Title level={4} className="title"><><Circle className="icon-normal section-panel-header" width="36px" height="36px" /><span className="accordion-header"> To Do</span></></Title>
              {allquestions}
            </Col>
          }
          <Col flex="11" className="answeredCol" style={{ display: this.state.showCompletedCol ? "block" : "none", maxWidth: this.props.completed ? "1000px" : "100%" }}>
            <Title level={4} className="title"><><Approved className="icon-completed-accent section-panel-header" width="36px" height="36px" /><span className="accordion-header"> Completed</span></></Title>
            {ansquestions}
          </Col>
        </Row>

      </>
    );
  }

}

export default (SurveyFill3);
