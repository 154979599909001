import React, { Component } from 'react';
import { NavLink, } from 'react-router-dom';
import axios from 'axios';
import Moment from 'react-moment';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';
import { API, APIKEY, IMessage, ICertificateSurvey, } from './IVesta';
import { Drawer, Input, notification } from 'antd';
import { SendOutlined, MailOutlined, CloseOutlined } from '@ant-design/icons';
import { VButton } from './components/VButtons'

import './App.css';
import 'antd/dist/antd.css';

const { TextArea } = Input;

interface IState {
  open: boolean,
  survey?: ICertificateSurvey,
  reply: boolean,
  replyText: string,
  showFooterButtons: boolean
}

interface IProps extends WrappedComponentProps {
  user: string,
  message: IMessage,
  visible: boolean,
  onClose: any,
}

class Message extends Component<IProps, IState>
{
  constructor(props: any) {
    super(props);

    this.state = {
      open: false,
      reply: false,
      replyText: "",
      survey: {} as ICertificateSurvey,
      showFooterButtons: true
    };

    this._reply = this._reply.bind(this);
    this._doReply = this._doReply.bind(this);
    this._dontReply = this._dontReply.bind(this);
    this._onChange = this._onChange.bind(this);
    this._read = this._read.bind(this);
    this.onClose = this.onClose.bind(this);

    this._read();
  }

  showNotification(title: string | null | undefined, message: string | null | undefined, placement: any = 'topRight') {
    notification.open({
      message: title ?? "Error",
      description: message ?? "",
      placement: placement

    });
  }

  _read() {
    if (this.props.message?.id == null) return;
    const post = {
      security: {
        APIKey: APIKEY,
        user: this.props.user,
      },
      messageid: this.props.message.id,
    }
    axios.post(API + "/message/Read", post)
      .then(response => {
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  _reply() {
    const $this = this;
    const post = {
      security: {
        APIKey: APIKEY,
        user: this.props.user,
      },
      messageid: this.props.message.id,
      reply: this.state.replyText,
    }
    axios.post(API + "/message/Reply", post)
      .then(response => {
        this.showNotification("Message", "Your Message was sent successfully")
      })
      .catch(function (error) {
        $this.showNotification("Message Failure", "Your Message failed to send")
        console.log(error);
      });

    this.setState({ reply: false, replyText: "", showFooterButtons: true })

  }

  _doReply() {
    this.setState({ reply: true, showFooterButtons: false })
  }

  _dontReply() {
    this.setState({ reply: false, replyText: "", showFooterButtons: true })
  }

  componentDidMount() {
    this.refresh();
  }

  componentDidUpdate(prevProps) {
    if (this.props.message !== prevProps.message) {
      this.refresh();
      this._read();
    }

  }

  refresh() {
    if (this.props.message.certificate?.surveys != null) {
      var s = this.props.message.certificate.surveys.find(e => e.id === this.props.message.surveyID);
      this.setState({ survey: s });
    }
  }

  onClose() {
    this.setState({ reply: false });

    if (this.props.onClose) {
      this.props.onClose()
    }

  }

  _onChange = (e) => {
    this.setState({ replyText: e.target.value })
  }

  render() {
    var evidence;
    var reply;

    const footerButtons = this.state.showFooterButtons
      ?
      <div className="footer-buttons">
        <VButton className="VButton" type="primary" text={<FormattedMessage id="message.reply" />} onClick={this._doReply} icon={<MailOutlined />} />
        <VButton className="VButton" text={<FormattedMessage id="std.close" />} onClick={this.onClose} icon={<CloseOutlined />} />
      </div>
      : <></>

    if (this.state.reply) {
      reply = <div className="reply">
        <div className="label">{this.props.intl.formatMessage({ id: 'message.reply' })}</div>
        <div className="text">
          <TextArea className="TextArea" rows={6} onChange={this._onChange} value={this.state.replyText} />
        </div>
        <div className="buttons">
          <VButton className="VButton" text={<FormattedMessage id="message.send" />} type="primary" icon={<SendOutlined />} onClick={this._reply} />
          <VButton className="VButton" text={<FormattedMessage id="std.cancel" />} icon={<CloseOutlined />} onClick={this._dontReply} />
        </div>

      </div>
    }
    return (
      <Drawer
        title={this.props.intl.formatMessage({ id: 'message.message' })}
        width={720}
        onClose={this.props.onClose}
        visible={this.props.visible}
        bodyStyle={{ paddingBottom: 80 }}
        className="Drawer"
        footer={
          <>{footerButtons}</>
        }
      >

        <div className="details">
          <h1>Message Details</h1>

          <div className="user-item">
            <div className="label">{this.props.intl.formatMessage({ id: 'message.from' })}</div>
            <div className="text">{this.props.message.userFrom?.email || ""}</div>
          </div>

          <div className="user-item">
            <div className="label">{this.props.intl.formatMessage({ id: 'message.for.cert' })}</div>
            <div className="text">
              <NavLink to={"/survey/" + this.props.message.surveyID}>
                {this.state.survey?.survey?.name || ""}
              </NavLink>
              <NavLink to={"/certificate/" + this.props.message.certificateID}>
                {this.props.message.certificate?.name || ""} <br />
              </NavLink>
              <NavLink to={"/building/" + this.props.message.certificate?.building?.id || ""}>
                {this.props.message.certificate?.building?.address1 || ""} {this.props.message.certificate?.building?.address2 || ""}  <br />
              </NavLink>
            </div>
          </div>

          {/* <div className="user-item">
            <div className="label">{this.props.intl.formatMessage({ id: 'message.sent' })}</div>
            <div className="text"><Moment format="ddd DD MMM YYYY HH:mm">{this.props.message.dateSent}</Moment></div>
          </div>

          <div className="user-item">
            <div className="label">{this.props.intl.formatMessage({ id: 'message.subject' })}</div>
            <div className="text">{this.props.message.subject}</div>
          </div> */}

          <div className="message">
            <div className="label">{this.props.intl.formatMessage({ id: 'message.receivemessage' })}</div>
            <div className="text">{this.props.message.fromMessage}</div>

            {evidence &&
              <div className="evidence">{evidence}</div>
            }

          </div>
          {/* <div className="message">
            <div className="label">{this.props.intl.formatMessage({ id: 'message.sendmessage' })}</div>
            <div className="text">{this.props.message.message}</div>

            {evidence &&
              <div className="evidence">{evidence}</div>
            }

          </div> */}

          {(this.props.message?.messageHistory && this.props.message?.messageHistory.length > 0) &&
            <div className='message-history'>
                {this.props.message?.messageHistory.map((val, index) => {
                  if(!val.isReceived) {
                    return <div className='from-history'>
                      <div className="from-chat">
                      <div className='from-message'>{val.message}</div>
                      <div className="from-date"><Moment format="ddd DD MMM YYYY HH:mm">{val.dateSent}</Moment></div>
                    </div>
                    </div>;
                  }else{
                    return <div className='to-history'>
                      <div className="to-chat">
                      <div className='from-message'>{val.message}</div>
                      <div className='from-date'><Moment format="ddd DD MMM YYYY HH:mm">{val.dateSent}</Moment></div>
                    </div>
                    </div>;
                  }
                })}
            </div>
          }
          {reply &&
            <>{reply}</>
          }

        </div>

      </Drawer>
    );
  }

}

export default injectIntl(Message);
