import React, { Component } from 'react';
import Moment from 'react-moment';
import axios from 'axios';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { API, APIKEY, IMessage, } from './IVesta';
import { Row, Col, Space, Table, Typography, Avatar, Popconfirm, notification, Select } from 'antd';
import { TrashIco } from './components/VenusIcons'
import { StringToColor } from './lib/venus';
import Message from "./Message";
import { ColumnsType } from 'antd/lib/table';

import './App.css';
import 'antd/dist/antd.css';

const { Text } = Typography;
const { Option } = Select;

interface IPagination {
  current: number,
  pageSize: number,
  sortField: string,
  sortOrder: string,
}

interface IState {
  myMessages: boolean,
  filteredMessages: IMessage[],
  messages: IMessage[],
  open: boolean,
  message: IMessage,
  loading: boolean,
  pagination: IPagination,
  winSize: number,
  deleting: boolean
}

interface IProps extends WrappedComponentProps {
  user: string,
  unread?: boolean,
  forManager?: boolean,
}


class Messages extends Component<IProps, IState>
{
  constructor(props: any) {
    super(props);

    this.state = {
      myMessages: true,
      filteredMessages: [] as IMessage[],
      messages: [] as IMessage[],
      message: {} as IMessage,
      open: false,
      loading: false,
      winSize: window.innerWidth,
      deleting: false,

      pagination: {
        current: 1,
        pageSize: 10,
        sortField: "",
        sortOrder: "asc",
      },
    };

    this.onSelect = this.onSelect.bind(this);
    this.onClose = this.onClose.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.windowResize = this.windowResize.bind(this);
    this.fixedCell = this.fixedCell.bind(this);
    this.showCell = this.showCell.bind(this);
    this.flipCells = this.flipCells.bind(this);
    this.setScroll = this.setScroll.bind(this);

    this.onDelete = this.onDelete.bind(this);
    this.doDelete = this.doDelete.bind(this);
    this.deleteService = this.deleteService.bind(this);
    this.filterMessages = this.filterMessages.bind(this);
    this.onHandleFilterMessages = this.onHandleFilterMessages.bind(this);

  }

  componentDidMount() {
    //this.refresh();
    const { pagination } = this.state;
    this.fetch({ pagination });
    window.addEventListener('resize', this.windowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResize);
  }

  // componentDidUpdate() {
  //   this.windowResize();
  // }

  windowResize() {
    this.setState({ winSize: window.innerWidth });
  }
  onClose() {
    this.setState({ open: false })
  }

  onDelete(r: IMessage) {
    this.setState({ message: r });
  }

  async doDelete() {
    var message = this.state.message;
    this.setState({ deleting: true });
    this.deleteService(message.id);

  }

  onSelect(r) {
    this.setState({ open: true, message: r });
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  };

  fetch = (params: any = {}) => {
    this.setState({ loading: true });
    const post = {
      security: {
        APIKey: APIKEY,
        user: this.props.user,
      },
      messageFor: !this.props.forManager ? 1 : 2,
      unreadOnly: this.props.unread || false,
      current: params.pagination.current,
      pagesize: params.pagination.pageSize,
      sortfield: params.pagination.sortField,
      sortorder: params.pagination.sortOrder,
    }

    // var url = API + "/message/ForManager";
    // if (!this.props.forManager) url = API + "/message/forclient";

    var url = API + "/message/GetMessages";

    axios.post(url, post)
      .then(response => {

        this.setState({
          messages: response.data.messages,
          loading: false,
          pagination: {
            ...params.pagination,
            total: response.data.totalCount,
          }
        });

        if (!this.props.forManager)
          this.setState({ myMessages: true, filteredMessages: this.filterMessages(true) });

      })
      .catch(function (error) {
        console.log(error);
      });
  };

  async deleteService(messageId: string) {
    var $this = this;

    const post = {
      security: {
        APIKey: APIKEY,
        user: this.props.user,
      },

      messageid: messageId
    }

    var url = API + "/message/Delete";

    axios.post(url, post)
      .then(response => {

        if (response.data.status.errorCode === 0) {
          const { pagination } = this.state;
          this.fetch({ pagination });
        }
        else {
          $this.showNotification("Error", "Oops, something went wrong");
        }

        this.setState({ deleting: false });

      })
      .catch(function (error) {
        console.log(error);
        $this.showNotification("Error", "Oops, something went wrong");
        $this.setState({ deleting: false });
      });

  }

  showNotification(title: string, description: string) {
    notification.open({
      message: title,
      description: description,
      placement: 'topRight'
    });
  }

  fixedCell(cell: string): any {
    const p = this.state.winSize;

    if (cell === "trash" && p > 800 && p <= 1104) return 'right';
    if (cell === "sent" && p <= 900) return 'right';
    return false;
  }

  showCell(cell: string | null | undefined): boolean {

    if (cell) {
      if (cell === "multi" && this.state.winSize <= 800) return true

      return false;
    }

    return this.state.winSize > 800;
  }

  flipCells() {
    return this.state.winSize <= 900;
  }

  setScroll() {
    const p = this.state.winSize;

    if (p > 800 && p <= 1104) return 1100

    return 0;
  }

  onHandleFilterMessages(value: string) {
    var myMessages = value === "0";
    this.setState({ myMessages: myMessages });
    var messages = this.filterMessages(myMessages);
    this.setState({ filteredMessages: messages });
  }

  filterMessages(myMessages: boolean): IMessage[] {

    if (!this.state) return [];

    if (this.props.forManager) return this.state.messages;

    if (this.state.messages.length === 0) return this.state.messages;

    var messages: IMessage[] = [];

    for (var i = 0; i < this.state.messages.length; i++) {
      const message = this.state.messages[i];

      if (myMessages) {

        if (this.props.user === message.contactTo.email)
          messages.push(message);

      } else {
        if (this.props.user !== message.contactTo.email)
          messages.push(message);
      }


    };


    return messages;

  }

  render() {

    var columns: ColumnsType<IMessage> = [];


    // columns.push({
    //   title: "",
    //   dataIndex: "avatar",
    //   key: "avatar",
    //   width: "60px",
    //   onCell: (r, a) => ({
    //     onClick: (e) => this.onSelect(r),
    //   }),

    //   render: (text, record: IMessage, index) => {
    //     const init = record.userFrom?.email ? record.userFrom.email : "?";
    //     const color = StringToColor(init);
    //     return <Avatar
    //       style={{
    //         backgroundColor: color,
    //         verticalAlign: 'middle',
    //       }}>

    //       {init.substr(0, 1).toUpperCase()}

    //     </Avatar>
    //   }
    // });


    // if (this.showCell(null))
    //   columns.push(
    //     {
    //       title: this.props.intl.formatMessage({ id: 'messages.from' }),
    //       dataIndex: 'userFrom.email',
    //       key: 'from',
    //       className: "text-col",
    //       onCell: (r, a) => ({
    //         onClick: (e) => this.onSelect(r),
    //       }),
    //       render: (text, record: IMessage, index) => {
    //         const colorCss = (record.read ? "read" : "unread") + " ellipses"
    //         //if(record.read) return <Text type="secondary">{record.userFrom?.email}</Text>

    //         return <Text className={colorCss}>{record.userFrom?.email}</Text>
    //       }
    //     });

    if (this.showCell(null))
      columns.push(
        {
          title: this.props.intl.formatMessage({ id: 'messages.subject' }),
          dataIndex: 'subject',
          key: 'subject',
          ellipsis: true,
          className: "text-col",
          onCell: (r, a) => ({
            onClick: (e) => this.onSelect(r),
          }),
          render: (text, record: IMessage) => {
            const colorCss = (record.read ? "read" : "unread") + " ellipses"
            //if(record.read) return <Text type="secondary">{record.subject}</Text>
            return <Text className={colorCss}>{record.subject}</Text>
          }
        });


    const sent = {
      title: this.props.intl.formatMessage({ id: 'messages.sent' }),
      dataIndex: 'dateSent',
      key: 'datesent',
      className: "date-col",
      fixed: this.fixedCell('sent'),
      onCell: (r, a) => ({
        onClick: (e) => this.onSelect(r),
      }),
      render: (text, record: IMessage) => {
        const colorCss = record.read ? "read" : "unread"
        //if(record.read) return <Text type="secondary"><Moment format="llll">{record.dateSent}</Moment></Text>
        return <Text className={colorCss}><Moment format="llll">{record.dateSent}</Moment></Text>
      }

    }

    const read = {
      title: this.props.intl.formatMessage({ id: 'messages.read' }),
      dataIndex: 'dateRead',
      key: 'dateread',
      className: "date-col",
      onCell: (r, a) => ({
        onClick: (e) => this.onSelect(r),
      }),
      render: (text, record: IMessage) => {
        const colorCss = record.read ? "read" : "unread"
        if (record.read === true) {
          return <Text className={colorCss}><Moment format="llll">{record.dateRead}</Moment></Text>
        }
        return <></>
      }

    }

    if (this.showCell(null)) {
      if (this.flipCells()) {
        columns.push(read);
        columns.push(sent);
      }
      else {
        columns.push(sent);
        columns.push(read);
      }

      if (!this.props.unread) {
        columns.push();
      }
    }

    if (this.showCell("multi")) {
      columns.push({

        title: this.props.intl.formatMessage({ id: 'messages.subject' }),
        dataIndex: 'multi',
        key: 'mutli',

        className: "text-col",
        onCell: (r, a) => ({
          onClick: (e) => this.onSelect(r),
        }),
        render: (text, record: IMessage) => {
          const colorCss = (record.read ? "read" : "unread") + " ellipses"
          if (record.read === true) {
            return <div>
              <div className={colorCss}>{record.userFrom?.email}</div>
              <div className={colorCss}>{record.subject}</div>
              <div className={colorCss}><Moment format="llll">{record.dateSent}</Moment></div>
            </div>
          }
          return <></>
        }

      });

    }

    columns.push({
      title: '',
      dataIndex: 'id',
      key: 'action',
      width: '50px',
      fixed: this.fixedCell('trash'),
      onCell: (r, a) => ({
        onClick: (e) => this.onDelete(r),
      }),
      render: (text, record: IMessage) => (
        <Space size="middle">
          <Popconfirm
            title="Delete this Message?"
            onConfirm={this.doDelete}
            okButtonProps={{ loading: this.state.deleting }}
            okText="Delete"
            placement="left"
          >
            <TrashIco onClick={() => this.onDelete(record)} />
          </Popconfirm>
        </Space>
      )
    }
    );

    const messageSelector = !this.props.forManager
      ? <Select defaultValue="0" style={{ width: 200 }} onChange={this.onHandleFilterMessages}>
        <Option value="0" >My Messages</Option>
        <Option value="1"> Manager Messages</Option>
      </Select>
      : <></>

    const messages: IMessage[] = this.props.forManager
      ? this.state.messages
      : this.state.filteredMessages;

    return (

      <Row className="Messages">
        <Col span={24}>
          <br />
          <h1>{(!this.props.unread) ? this.props.intl.formatMessage({ id: 'messages.messages' }) : this.props.intl.formatMessage({ id: 'messages.unread.messages' })}</h1>
          {messageSelector}
          {/* <div>
            {this.state.winSize}px
          </div> */}
          <Table<IMessage>
            rowKey="id"
            dataSource={messages}
            columns={columns}
            pagination={this.state.pagination}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            // onRow={(r, a) => ({
            //   onClick: (e) => this.onSelect(r, a, e),
            // })}

            scroll={{ x: this.setScroll() }}
            rowClassName="messages-row"
          />
          <Message user={this.props.user} visible={this.state.open} onClose={this.onClose} message={this.state.message} />
        </Col>

      </Row>

    );
  }

}

export default injectIntl(Messages);
