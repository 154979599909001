import React, { Component } from 'react';
import Moment from 'moment';
import { IAnswer, IBuilding, ICertificate, ICertificateSurvey, IQuestion } from '../IVesta';
import { Space, Typography, Row, Col, Rate } from 'antd';
import {
    CheckOutlined,
    CloseOutlined,
    VideoCameraOutlined,
    PictureOutlined,
    FilePdfOutlined,
    CloudDownloadOutlined,
} from '@ant-design/icons';

import '../App.css';
import 'antd/dist/antd.css';


const { Text, Title } = Typography;

interface IState {
    building: IBuilding,
    certificate: ICertificate,
    certificatesurvey: ICertificateSurvey,
}

interface IProps {
    question: IQuestion,
    answers: IAnswer[],
    displayEvidence: boolean,
    showImage: any,
}


class QuestionView extends Component<IProps, IState>
{
    constructor(props: any) {
        super(props);

        this.state = {
            certificate: {} as ICertificate,
            building: { address1: "" } as IBuilding,
            certificatesurvey: {} as ICertificateSurvey,
        };

        this.showImage = this.showImage.bind(this);
    }

    componentDidMount() {
    }

    showImage(url) {
        this.props.showImage(url);
    }



    render() {
        if (this.props.question == null) return <Space></Space>;
        var questionfields = <div></div>;
        var title = "";
        var questioncolwidth = 18;
        var answercolwidth = 6;
        var answer = this.props.answers.find(i => i.questionID === this.props.question.id);

        var evidence = <></>;
        var evidenceicon = <></>;

        if (this.props.displayEvidence) {
            switch (answer?.evidence?.mimetype) {
                case "image/jpeg":
                case "image/jpg":
                case "image/png":
                    evidence = <Row justify="end">
                        <img src={answer?.evidence?.url} className="EvidenceViewImage" />
                    </Row>
                    break;
                case "video/mpeg":
                    evidence = <Row justify="end">
                        <Text type="success"><VideoCameraOutlined style={{ fontSize: '32px' }} /></Text>
                    </Row>
                    break;
                case "application/pdf":
                    evidence = <Row justify="end">
                        <div className="EvidenceViewIcon">
                            <a href={answer?.evidence?.url} target="_blank" rel="noreferrer" ><Text type="success"><CloudDownloadOutlined style={{ fontSize: '50px' }} /></Text></a>
                        </div>
                    </Row>
                    break;

            }
        }
        else {
            evidenceicon = <></>;
            switch (answer?.evidence?.mimetype) {
                case "image/jpeg":
                case "image/jpg":
                    evidenceicon = <div><Text type="success" ><PictureOutlined onClick={e => this.showImage(answer?.evidence?.url)} style={{ fontSize: '32px' }} /></Text></div>
                    break;
                case "video/mpeg":
                    evidenceicon = <Text type="success"><VideoCameraOutlined style={{ fontSize: '32px' }} /></Text>
                    break;
                case "application/pdf":
                    evidenceicon = <a href={answer?.evidence?.url} target="_blank" rel="noreferrer" ><Text type="success"><FilePdfOutlined style={{ fontSize: '32px' }} /></Text></a>
                    break;

            }

        }

        switch (this.props.question.type) {
            case 0: //  Y/N
                title = "Yes/No";
                questionfields =
                    <Space>
                        {((answer?.answer || "") === "True" ?
                            <Text type="success"><CheckOutlined style={{ fontSize: '32px' }} /></Text> :
                            <Text type="danger"><CloseOutlined style={{ fontSize: '32px' }} /></Text>
                        )}
                        {evidenceicon}
                    </Space>;
                break;
            case 1: // Text,
                title = "Text";
                questionfields = <Text>{(answer?.answer || "")}</Text>;
                break;
            case 2: // Number,
                title = "Number";
                questionfields = <Text>{(answer?.answer || "")}</Text>;
                break;
            case 3: // Currency,
                title = "Currency";
                var m = Moment(answer?.answer || "");
                questionfields = <Text>{m}</Text>;
                break;
            case 4: // Date,
                title = "Date";
                questionfields = <Text>{answer?.answer}</Text>;
                break;
            case 5: // PickOne,
                title = "Pick One";
                var opts = this.props.question.options.split(";");
                var opt = parseInt(answer?.answer || "0");
                questionfields = <Text>{opts[opt]}</Text>;
                break;
            case 6: // Rating,
                title = "Rating";
                questionfields = <Rate value={parseInt(answer?.answer || "0")} />;
                break;
        }

        return (
            <div className="QuestionViewRow">
                <Row justify="start" >
                    <Text type="secondary" disabled>{title}</Text>
                </Row>
                <Row justify="start">
                    <Col span={questioncolwidth} >
                        <Title level={3} >{this.props.question.description}</Title>
                    </Col>
                    <Col span={answercolwidth}>
                        {questionfields}
                    </Col>
                </Row>
                {evidence}
            </div>
        );
    }

}

export default (QuestionView);
