import React, { Component } from 'react';
import Moment from 'react-moment';
import { ICertificateSurvey } from '../IVesta';
import { Row, Col, Modal, Typography, Space } from 'antd';
import { RightCircleOutlined, DownCircleOutlined } from '@ant-design/icons';
import QuestionView from './QuestionView';

import '../App.css';
import 'antd/dist/antd.css';


const { Title } = Typography;

interface IState {
    imageVisible: boolean,
    imageURL: string,
}

interface IProps {
    certificatesurvey: ICertificateSurvey,
    displayEvidence: boolean,
    showImage?: any,
    expanded: boolean,
    onExpand?: any,
}

class SurveyView extends Component<IProps, IState>
{
    constructor(props: any) {
        super(props);

        this.state = {
            imageVisible: false,
            imageURL: "",
        };

        this.showImage = this.showImage.bind(this);
        this.hideImage = this.hideImage.bind(this);

    }

    showImage(url) {
        if (this.props.showImage != null) {
            this.props.showImage(url);
        }
        else {
            this.setState({ imageURL: url, imageVisible: true, })
        }
    }

    hideImage() {
        this.setState({ imageVisible: false, })
    }

    componentDidMount() {
    }

    render() {
        var allquestions;
        var imageModal = <></>;
        var icon;
        if (this.props.expanded) {
            if (this.props.certificatesurvey.isCompleted) {
                allquestions = this.props.certificatesurvey.survey.sections.map(section => {
                    const quest = section.questions.map(q => {
                        return <Col key={q.id}><QuestionView showImage={this.showImage} answers={this.props.certificatesurvey.answers} question={q} displayEvidence={this.props.displayEvidence} /></Col>
                    });
                    return quest
                });
            }

            if (this.props.showImage == null) {
                imageModal =
                    <Modal width={700} title="Image Evidence" visible={this.state.imageVisible} onOk={this.hideImage}>
                        <img src={this.state.imageURL} className="EvidenceViewModalImage" />
                    </Modal>
            }
            icon = <DownCircleOutlined onClick={() => { if (this.props.onExpand != null) this.props.onExpand(false) }} />;
        }
        else {
            icon = <RightCircleOutlined onClick={() => { if (this.props.onExpand != null) this.props.onExpand(true) }} />;
        }


        return (
            <>
                <Row justify="start">
                    <Col span={12} className="ColLeft">
                        <Title level={4}>
                            <Space>
                                {icon}
                                {this.props.certificatesurvey.survey.name}
                            </Space>
                        </Title>
                    </Col>
                    <Col span={6}>
                        <h4>{(this.props.certificatesurvey.survey.completionMethod === 2) ? "Desk Based" : "On Site"}</h4>
                    </Col>
                    <Col span={6} className="ColLeft">
                        <h4>Approved On <Moment format="LL">{this.props.certificatesurvey.approvedOn}</Moment></h4>
                    </Col>
                </Row>
                {allquestions}
                {imageModal}
            </>);
    }
}

export default (SurveyView);
