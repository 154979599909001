import React, { Component } from 'react';
import { NavLink, } from 'react-router-dom';
import axios from 'axios';
import { API, APIKEY, IBuilding, ICertificateSurvey } from './IVesta';
import { Card, Space, Row, Col } from 'antd';
import './App.css';
import 'antd/dist/antd.css';

interface IState {
  buildings: IBuilding[],
}

interface IProps {
  history?: any,
  user: string,
  forManager?: boolean,
}


class Buildings extends Component<IProps, IState>
{

  constructor(props: any) {
    super(props);

    this.state = {
      buildings: [],
    };

    this.onSelect = this.onSelect.bind(this);
  }

  onSelect(r: ICertificateSurvey) {
    this.props.history.push("/survey/" + r.id);
  }


  componentDidMount() {
    this.refresh();
  }

  refresh() {
    const post = {
      security: {
        APIKey: APIKEY,
        user: this.props.user,
      },
    }
    var url = API + "/building/formanager";
    if (!this.props.forManager) url = API + "/building/forclient";

    axios.post(url, post)
      .then(response => {
        this.setState({
          buildings: response.data.buildings,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  render() {
    const buildings =
      this.state.buildings.map(function (element: IBuilding, i: number) {
        return (
          <NavLink key={element.id} to={"/building/" + element.id}>
            <Card
              hoverable
              style={{ width: 240 }}
              cover={<img alt="example" src="/images/building.jpg" />}
            >
              <p>{element.buildingName}<br />
                {element.address1}<br />
                {element.address2}<br />
                {element.city}</p>
            </Card>
          </NavLink>
        )
      });


    return (
      <>
        <Row>
          <Col span={24}>
            <h1>My Buildings</h1>
            <Space>
              {buildings}
            </Space>
          </Col>
        </Row>
      </>
    );
  }

}

export default Buildings;
