import { IBuilding, ISurveySection, IQuestion } from '../IVesta'

export function FormatBuilding(building?: IBuilding | null | undefined, unit?: string | null | undefined) {

    if (!building) return <></>

    var unitName = unit ? unit.trim() : "";
    var acc = RemoveEnd(building.buildingName, ",");
    var name = RemoveEnd(building.buildingName, ",");
    var address1 = RemoveEnd(building.address1, ",");
    var address2 = RemoveEnd(building.address2, ",");
    var city = RemoveEnd(building.city, ",");
    var postcode = RemoveEnd(building.postcode, ",");

    if (address1 !== "") {
        if (acc !== "") {
            acc += "," + address1;
            address1 = ", " + address1;
        }
        else {
            acc = address1;
        }
    }
    if (address2 !== "") {
        if (acc !== "") {
            acc += "," + address2
            address2 = ", " + address2;
        }
    }
    if (city !== "") {
        if (acc !== "") {
            acc += "," + city;
            city = ", " + city;
        }
    }
    if (postcode !== "") {
        if (acc !== "") {
            acc += "," + postcode;
            postcode = ", " + postcode;
        }
    }


    return <>

        {unitName !== "" &&
            <div className="unit-name">
                {unitName}
            </div>
        }
        {building.buildingName !== "" &&
            <>{name}</>
        }

        {building.address1 !== "" &&
            <>{address1}</>
        }

        {building.address2 !== "" &&
            <>{address2}</>
        }

        {building.city !== "" &&
            <>{city}</>
        }

        {building.postcode !== "" &&
            <>{postcode}</>
        }

    </>
}


function RemoveEnd(value: string, ch: string) {
    while (value[value.length - 1] === ch && value.length > 0) {
        value = value.slice(0, -1);
    }
    return value;
}

export function AllSectionsCompleted(sections: ISurveySection[]): boolean {
    var valid = true;

    sections.forEach(section => {
        if (!section.qaPass) valid = false;
        // section.questions.forEach(question => {
        //     if (question.mandatory) {
        //         if (question.answer == null) {
        //             valid = false;
        //         }
        //     }
        // });
    });

    return valid;
}

export function Mandatory(question: IQuestion, startDate?: Date): boolean {

    if (startDate) {
        if (question.mandatory && question.mandatoryGracePeriod > 0) {
            var today = new Date();
            var st = new Date(startDate);
            var days = DateDiffDays(today, st);

            return days >= question.mandatoryGracePeriod;
        }
    }

    return question.mandatory;
}

export function DateDiffDays(date1, date2) {
    const diffInMs = Math.abs(date2 - date1);
    return diffInMs / (1000 * 60 * 60 * 24);
}


export function StringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

