import React, { Component } from 'react';
import axios from 'axios';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';
import { API, APIKEY, ICertificateSurvey } from './IVesta';
import { Row, Col, Table, } from 'antd';
import Messages from './Messages';

import './App.css';
import 'antd/dist/antd.css';

interface IState {
  surveys: ICertificateSurvey[],
}

interface IProps extends WrappedComponentProps {
  history?: any,
  user: string,
}

class Todo extends Component<IProps, IState>
{

  constructor(props: any) {
    super(props);

    this.state = {
      surveys: [],
    };

    this.onSelect = this.onSelect.bind(this);
  }

  onSelect(r: ICertificateSurvey) {
    this.props.history.push("/survey/" + r.id);
  }


  componentDidMount() {
    this.refresh();
  }

  refresh() {
    const post = {
      security: {
        APIKey: APIKEY,
        user: this.props.user,
      },
    }
    axios.post(API + "/todo/manager", post)
      .then(response => {
        this.setState({
          surveys: response.data.surveys,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  render() {
    const columns = [
      {
        title: this.props.intl.formatMessage({ id: 'todo.certificate' }),
        dataIndex: 'certificateName',
        key: 'from',
      },
      {
        title: this.props.intl.formatMessage({ id: 'todo.survey' }),
        dataIndex: 'subject',
        key: 'subject',
        render: (text, record: ICertificateSurvey) => (
          <>{record.survey.name || "Unknown"}</>
        )
      },
    ];

    return (
      <>
        <Messages unread user={this.props.user} />
        <Row>
          <Col span={24}>
            <h1><FormattedMessage id="todo.unfinished.surveys" /></h1>
            <Table<ICertificateSurvey> rowKey="id" dataSource={this.state.surveys} columns={columns}
              onRow={(r) => ({
                onClick: () => this.onSelect(r),
              })}

            />
          </Col>
        </Row>
      </>
    );
  }

}

export default injectIntl(Todo);
