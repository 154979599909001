import React, { Component } from 'react';
import { Input } from 'antd'
import { EditOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { NavLink, } from 'react-router-dom';

interface IState {
    edit?: boolean
    canEdit?: boolean
    text: string
    width: string
    wrapperRef: any
    oldValue: string
}

interface IProps {
    icon?: any,
    deviceIcon?: any
    name: any,
    subText?: any | null | undefined,
    extraText?: any | null | undefined,
    onClick?: any,
    onUpdate?: any,
    style?: React.CSSProperties,
    last?: boolean
    edit?: boolean
    canEdit?: boolean
    editPlaceHolder?: string
    defaultInputValue?: boolean
    data?: any
    linkTo?: string | null | undefined
    className?: string | null | undefined
}

class SectionCard extends Component<IProps, IState>{

    constructor(props: any) {
        super(props);

        this.state = {
            edit: this.props.edit,
            canEdit: this.props.canEdit,
            text: "",
            oldValue: "",
            width: "250px",
            wrapperRef: React.createRef()
        }

        this.edit = this.edit.bind(this);
        this.update = this.update.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.onClick = this.onClick.bind(this);
        this.input = this.input.bind(this);
        this.cancel = this.cancel.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.onBlur = this.onBlur.bind(this);


        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);

    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('keydown', this.handleKeyPress);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('keydown', this.handleKeyPress);
    }

    handleClickOutside(event) {
        if (this.state.edit) {
            if (this.state.wrapperRef && !this.state.wrapperRef.current.contains(event.target)) {
                this.cancel()
            }
        }
    }

    handleKeyPress(event) {
        if (this.state.edit) {

            if (event.code === "Escape") {
                this.cancel();
                return;
            }

            if (event.code === "Tab") {
                this.cancel();
                return;
            }


        }
    }

    hasValue(value?: any | null | undefined): boolean {
        return !(value === null || value === undefined);
    }

    edit() {

        if (this.props.canEdit) {
            this.setState({ edit: true, width: "231px", oldValue: this.state.text })
        }
    }

    update() {

        if (this.props.onUpdate) {
            this.props.onUpdate(this.state.text, this.props.data);
        }

        this.setState({ edit: false, width: "250px" });
    }

    cancel() {
        this.setState({ edit: false, width: "250px", text: this.state.oldValue });
    }

    keyPress(e: any) {
        if (e.charCode === 13) {
            this.update();
            return;
        }
    }

    input(e: any) {
        const { value } = e.target;
        this.setState({ text: value });
    }

    onClick() {

        if (this.props.onClick) {
            this.props.onClick();
        }

        if (this.props.canEdit && !this.state.edit) {
            this.edit();
        }
    }

    onBlur() {

    }


    handleClick(e: any) {
        if (this.state.edit) {
            e.preventDefault()
        }
    }

    render() {

        var classname = this.props.className ?? "sectionCard";

        if (this.props.last === false) {
            classname += " MarginRight20";
        }

        const sectionCardBody =
            <>
                <div className="icon" >
                    {this.props.icon}
                </div>

                <div style={{ display: "inline-block" }}>

                    <div className="name" style={{ width: this.state.width, marginRight: 5 }}  >
                        <>{this.props.name}</>
                    </div>

                    {this.hasValue(this.props.subText) &&
                        <>
                            <div style={{ display: this.state.edit === true ? "block" : "none" }}>
                                <Input onBlur={this.onBlur} onInput={(e) => this.input(e)} onKeyPress={(e) => this.keyPress(e)} onKeyUp={(e) => this.keyPress(e)} style={{ width: "230px", display: "inline-block", marginRight: "5px" }} placeholder={this.props.editPlaceHolder} />
                            </div>
                            <div className="subtext" style={{ display: this.state.edit === true ? "none" : "block" }}>
                                <>{this.props.subText}</>
                            </div>
                        </>
                    }

                    {this.hasValue(this.props.extraText) &&
                        <div className="extraText">{this.props.extraText}</div>
                    }

                </div>


            </>

        const sectionCard = this.props.linkTo
            ?
            <NavLink to={this.props.linkTo} onClick={(e) => this.handleClick(e)}>
                <div className="section-body">
                    {sectionCardBody}
                </div>
            </NavLink >
            :
            <div className="section-body">
                {sectionCardBody}
            </div>


        return (

            <>
                <div className={classname} style={this.props.style} onClick={this.onClick} ref={this.state.wrapperRef}>

                    {sectionCard}

                    <div style={{ display: "inline-block" }}>
                        {this.props.deviceIcon && !this.state.edit &&
                            <div className="device-icon">{this.props.deviceIcon}</div>
                        }

                        <div style={{ display: "block", verticalAlign: this.state.edit ? "top" : "middle" }}>
                            {this.props.canEdit && !this.state.edit &&
                                <EditOutlined className="edit-icon" onClick={this.edit} style={{ cursor: "pointer", }} color="blue" />
                            }

                            {this.props.canEdit && this.state.edit &&
                                <div style={{ marginTop: 30 }}>
                                    <CheckCircleOutlined className="update-icon" style={{ cursor: "pointer" }} onClick={(e) => this.update()} />
                                     &nbsp;
                                <CloseCircleOutlined className="cancel-icon" style={{ cursor: "pointer" }} onClick={this.cancel} />
                                </div>
                            }
                        </div>

                    </div>

                </div>

            </>


        );
    }

}


export default SectionCard