import React, { Component } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { IBuilding, ICertificate, ICertificateSurvey, IQuestion } from './IVesta';
import Question2 from './Question2';
import Evidence2 from './Evidence2';

import './App.css';
import 'antd/dist/antd.css';

interface IState {
    building: IBuilding,
    certificate: ICertificate,
    certificatesurvey: ICertificateSurvey,
    showEvidence: boolean,
}

interface IProps extends WrappedComponentProps {
    user: string,
    question: IQuestion,
    certificatesurveyid: string,
    onAnswered: any,
    onSiteSurvey?: boolean
    completed?: boolean
    startDate?: Date

}

class QuestionCard2 extends Component<IProps, IState>
{
    constructor(props: IProps) {
        super(props);

        var textval = "";
        var numval = 0;

        if (props.question.answer != null) {
            switch (props.question.type) {
                case 1: textval = props.question.answer.answer; break;
                case 2: case 3: numval = Number(props.question.answer.answer); break;
            }
        }

        this.state = {
            certificate: {} as ICertificate,
            building: { address1: "" } as IBuilding,
            certificatesurvey: {} as ICertificateSurvey,
            showEvidence: false,
        };

    }

    componentDidMount() {
    }

    displayEvidence(id: string) {
        //$("#QCI"+id).addClass("flip-card-flip");
    }

    displayQuestion(id: string) {
        //$("#QCI"+id).addClass("flip-card-flip");
    }

    render() {
        if (this.props.question.evidencerequired > 0) {
            var disableEvidence = false;

            if (this.props.question.answer == null) disableEvidence = true;
            else {
                if (this.props.question.type === 12 || this.props.question.type === 13) disableEvidence = true;
                if (this.props.question.evidencerequired === 2) {
                    if (this.props.question.answer.answer === "False") disableEvidence = true;
                }
                else if (this.props.question.evidencerequired === 3) {
                    if (this.props.question.answer.answer === "True") disableEvidence = true;
                }
            }

            if (disableEvidence) {
                return <div className="questionCard">
                    <Question2 user={this.props.user}
                        question={this.props.question}
                        certificatesurveyid={this.props.certificatesurveyid}
                        onAnswered={this.props.onAnswered}
                        hasEvidence
                        onSiteSurvey={this.props.onSiteSurvey}
                        displayEvidence={() => { this.displayEvidence(this.props.question.id || "") }} 
                        completed = {this.props.completed}
                        startDate = {this.props.startDate}
                        />
                </div>
            }

            return <div className="questionCard">
                <Question2 user={this.props.user}
                    question={this.props.question}
                    certificatesurveyid={this.props.certificatesurveyid}
                    onAnswered={this.props.onAnswered}
                    hasEvidence
                    onSiteSurvey={this.props.onSiteSurvey}
                    displayEvidence={() => { this.displayEvidence(this.props.question.id || "") }}
                    completed = {this.props.completed} 
                    startDate = {this.props.startDate}
                    />

                <Evidence2 onAnswered={this.props.onAnswered}
                    user={this.props.user}
                    certificatesurveyid={this.props.certificatesurveyid}
                    question={this.props.question}
                    disabled={disableEvidence}
                    onSiteSurvey={this.props.onSiteSurvey}
                    displayQuestion={() => { this.displayQuestion(this.props.question.id || "") }} 
                    completed = {this.props.completed}
                    
                    />
            </div>;
        }
        return <div className="questionCard">


            <Question2 user={this.props.user}
                onSiteSurvey={this.props.onSiteSurvey}
                question={this.props.question}
                certificatesurveyid={this.props.certificatesurveyid}
                onAnswered={this.props.onAnswered} 
                completed = {this.props.completed}
                startDate = {this.props.startDate}
                />
        </div>


    }
}
export default injectIntl(QuestionCard2);
